<div class="container-fluid pdlr">
    <div class="row">
        <div class="col-xl-12 xl-100 dashboard-sec box-col-12">
            <div class=" row m-0 p-tb">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="card">
                        <div class="card-header calender-btn">
                            <h5>Role
                                <button class="btn btn-primary updated-btn pull-right"  (click)="addstorerole.openModal();$event.preventDefault()">
                                Add New Role
                            </button>
                            </h5>
                        </div>
                        <div class="card-body custom-datatable noscroll">
                            <ngx-datatable class="bootstrap"  [rows]="modelRoleList" [loadingIndicator]="loadingIndicator"
                                [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                [reorderable]="reorderable" [limit]="15">
                                <ngx-datatable-column name="Role Name" prop="role_name"></ngx-datatable-column> 
                                <ngx-datatable-column name="Action" widht="600">    
                                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                        <div class="trlistBtn">
                                            <button class="edit-btn" (click)="addstorerole.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                            
                                        </div>   
                                    </ng-template>    
                                </ngx-datatable-column> 
                            </ngx-datatable>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<app-addstorerole  (sendaddedstorerole) = 'reloadRoleTable($event)' #addstorerole ></app-addstorerole>