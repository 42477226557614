import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { StoreRoleManagementRoutingModule } from './store-role-management-routing.module';
import { StoreRoleManagementComponent } from './store-role-management.component';
import { FormsModule } from '@angular/forms';
import { AddStoreRoleComponent } from './modal/addstorerole/addstorerole.component';

@NgModule({
  declarations: [
    StoreRoleManagementComponent,
    AddStoreRoleComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    StoreRoleManagementRoutingModule,
    NgxDatatableModule
  ]
})
export class StoreRoleManagementModule { }
