import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VendorRoutingModule } from './vendor-routing.module';
import { VendorComponent } from './vendor.component';
import { AddvendorComponent } from './modal/addvendor/addvendor.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    VendorComponent,
    AddvendorComponent
  ],
  imports: [
    CommonModule,
    VendorRoutingModule,
    FormsModule,
    NgxDatatableModule
  ]
})
export class VendorModule { }
