import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class StoreFuelTax {
  public store_fuel_tax_description: string;
  public store_fuel_tax: string;
  public store_fuel_tax_on_tax: string;
  public store_fuel_tax_rate: string;
}


@Component({
  selector: 'app-addfueltax',
  templateUrl: './addfueltax.component.html',
  styleUrls: ['./addfueltax.component.scss']
})
export class AddfueltaxComponent implements OnInit {

  @ViewChild("addfueltax", { static: false }) addfueltax: TemplateRef<any>;
  @Output() sendaddedfueltax: EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelStoreFuelTax = new StoreFuelTax();
  selected_tax_id : string;
  public modelTitle: string;
  public salesTaxId: string; 

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    this.selected_tax_id= row_id;
    if(this.selected_tax_id != null){
        this.storeService.getStoreFuelTaxById(this.selected_tax_id).then((data)=>{this.modelStoreFuelTax=data;});
        this.modelTitle = "Edit";
        this.salesTaxId = this.selected_tax_id;
    }else{
      this.modelStoreFuelTax = new StoreFuelTax();
      this.modelTitle = "Add";
      this.salesTaxId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addfueltax, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveFuelTax(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
   if(form.valid) { 
    this.storeService.saveFuelTax(form.value, this.salesTaxId)
          .then((response) => {
             console.log(response);
             Swal.fire('Fuel Tax!', 'Fuel Tax Save Successfully.', 'success');
             this.sendaddedfueltax.emit(store_id);
             this.modalService.dismissAll();
          });
    }
  }


}
