import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {

  constructor() { }

    /**
     * get user details stored in session
     */
    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    /**
     * get logged user details stored in session
     */
    getLoggedInUser() {
        return JSON.parse(localStorage.getItem('loggedInUser'));
    }

    /**
     * get selected language stored in session
     */
    getLang() {
        return localStorage.getItem('lang');
    }

    /**
     * Store user details to session
     */
    setUser(user: any) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * Store logged user details to session
     */
    setLoggedInUser(user: any) {
        localStorage.setItem('loggedInUser', JSON.stringify(user));
    }

    /**
     * Store selected language to session
     */
    setLang(lang: string) {
        localStorage.setItem('lang', lang);
    }

    /**
     * Remove user details to session
     */
    removeUser() {
        localStorage.removeItem('user');
    }

    /**
     * Remove logged user details to session
     */
    removeLoggedInUser() {
        localStorage.removeItem('loggedInUser');
    }

    /**
     * Clear session
     */
    clearSession() {
        localStorage.clear();
    }

    /*
     *   Get current logged in status
     */
    isLoggedIn() {
        if(null != localStorage.getItem('user'))
          return true;

        return false;
    }
}