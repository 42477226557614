import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RegisterMappingRoutingModule } from './register-mapping-routing.module';
import { RegisterMappingComponent } from './register-mapping.component';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { AddDepartmentregistermappingComponent } from './modal/add-departmentregistermapping/add-departmentregistermapping.component';
import { AddFuelgraderegistermappingComponent } from './modal/add-fuelgraderegistermapping/add-fuelgraderegistermapping.component';

@NgModule({
  declarations: [
    RegisterMappingComponent,
    
    AddDepartmentregistermappingComponent,
    AddFuelgraderegistermappingComponent
  ],
  imports: [
    CommonModule,
    RegisterMappingRoutingModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule
    
  ]
})
export class RegisterMappingModule { }
