<div class="container-fluid">
    <div class="row">

        <div class="col-xl-12 xl-100 box-col-12">
            <div class="mtb">

                <div class="cardhead">

                    <ul id="info-tab" role="tablist" class="nav nav-tabs border-tab nav-primary storetabsst">
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-1" class="nav-link " [ngClass]="{'active show': openTab == 'store_info'}" (click)="tabbed('store_info')">Store Info</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-2" class="nav-link" [ngClass]="{'active show': openTab == 'sales_tax'}" (click)="tabbed('sales_tax')">Sales
                            Tax Setup</a></li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" id="info-tab-3" class="nav-link " [ngClass]="{'active show': openTab == 'sales_restriction'}" (click)="tabbed('sales_restriction')">Sales
                            Restriction</a></li> -->
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-4" class="nav-link" [ngClass]="{'active show': openTab == 'fuel_details'}" (click)="tabbed('fuel_details')">Fuel
                            Details</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-5" class="nav-link" [ngClass]="{'active show': openTab == 'store_location'}" (click)="tabbed('store_location')">Store
                            Fees</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-6" class="nav-link" [ngClass]="{'active show': openTab == 'payment_method'}" (click)="tabbed('payment_method')">Payment 
                            Method</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-7" class="nav-link" [ngClass]="{'active show': openTab == 'store_services'}" (click)="tabbed('store_services')">Store 
                            Services</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-8" class="nav-link" [ngClass]="{'active show': openTab == 'lottery_settings'}" (click)="tabbed('lottery_settings')">Lottery 
                            Settings</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-9" class="nav-link" [ngClass]="{'active show': openTab == 'bank_account'}" (click)="tabbed('bank_account')">Bank 
                            Account</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-10" class="nav-link" [ngClass]="{'active show': openTab == 'setting_off'}" (click)="tabbed('setting_off')">Setting 
                            (Time Off)</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-11" class="nav-link" [ngClass]="{'active show': openTab == 'setting_scheduling'}" (click)="tabbed('setting_scheduling')">Setting Scheduling</a></li>
                    </ul>
                    <div id="info-tabContent" class="tab-content">
                        <div id="info-1" role="tabpanel" aria-labelledby="info-tab-1" class="tab-pane fade" [ngClass]="{'show active': openTab == 'store_info'}">
                            <div class="card-header calender-btn">
                                <h5>Add New Store
                                    <button class="btn btn-primary pull-right" (click)="addStore()">Add new store</button>
                                    <div class="clearfix"></div>
                                </h5>
                            </div>
                            <form #storeInfoForm="ngForm" (ngSubmit)="onSubmitStoreInfo(storeInfoForm)" enctype="multipart/form-data">
                                <div class="row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Store Info</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Store Full Name</label>
                                            <input type="text" name="store_full_name" class="form-control" [(ngModel)]="model.store_full_name" #store_full_name="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_full_name.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Store Name</label>
                                            <input type="text" class="form-control" name="store_name" [(ngModel)]="model.store_name" #store_name="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_name.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Contact No</label>
                                            <input type="text" class="form-control" name="store_contact_no" [(ngModel)]="model.store_contact_no" #store_contact_no="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_contact_no.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Store Address</label>
                                            <input type="text" class="form-control" name="store_address" [(ngModel)]="model.store_address" #store_address="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_address.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">City</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_city" [(ngModel)]="model.store_city" #store_city="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_city.invalid }" required>
                                                <option>City 1</option>
                                                <option>City 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Country</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_country" [(ngModel)]="model.store_country" #store_country="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_country.invalid }" required>
                                                <option>country 1</option>
                                                <option>country 2</option>
                                            </select>
                                        </div>
                                    </div>
                                     <div class="col-xl-4 col-lg-6 col-md-6 col-12"> 
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">State</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_state" [(ngModel)]="model.store_state" #store_state="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_state.invalid }" required>
                                                <option>State 1</option>
                                                <option>State 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>ZIP Code</label>
                                            <input type="text" class="form-control" name="store_zip" [(ngModel)]="model.store_zip" #store_zip="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_zip.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Select Timezone</label>
                                            <select class="form-control form-select digits"  id="exampleFormControlSelect9" name="timezone"  [(ngModel)]="model.timezone"  #timezone="ngModel"
                                [ngClass]="{ 'is-invalid': storeInfoForm.submitted && timezone.invalid }"  required>
                                    <option value="">Select</option>    
                                    <option *ngFor="let stimzn of timezoneList" [value]="stimzn.value">
                                        {{stimzn.value}} ({{stimzn.name}})
                                    </option>
                                </select>
                                            
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">

                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Email Id</label>
                                            <input type="text" class="form-control" name="store_email" [(ngModel)]="model.store_email" #store_email="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_email.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Phone Number</label>
                                            <input type="text" class="form-control" name="store_phone" [(ngModel)]="model.store_phone" #store_phone="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_phone.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Upload Logo</label>
                                            <input type="file" class="form-control" name="store_logo"   (change)="onstorelogoChange($event.target.files)"  >
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Notification</h4>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="checkbox checkbox-primary p-0 leftmargin">
                                            <input class="form-check-input" id="checkbox-primary-1" type="checkbox" name="use_same_contact_notification" [(ngModel)]="model.use_same_contact_notification" #use_same_contact_notification="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && use_same_contact_notification.invalid }" required>
                                            <label class="form-check-label" for="checkbox-primary-1">Use same contact
                                                details for report notifications</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Add Phone No</label>
                                            <input type="text" class="form-control" name="additional_store_phone" [(ngModel)]="model.additional_store_phone" #additional_store_phone="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && additional_store_phone.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Add Email Id</label>
                                            <input type="text" class="form-control" name="additional_store_email" [(ngModel)]="model.additional_store_email" #additional_store_email="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && additional_store_email.invalid }" required>
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">POS Details</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Select POS system</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_pos_type" [(ngModel)]="model.store_pos_type" #store_pos_type="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_pos_type.invalid }" required>
                                                <option value="Gilbarco">Gilbarco</option>
                                                <option value="Verifone">Verifone</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Store Tier</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_tire" [(ngModel)]="model.store_tire" #store_tire="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_tire.invalid }" required>
                                                <option>Select 1</option>
                                                <option>Select 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Service Level</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9"  name="store_service_level" [(ngModel)]="model.store_service_level" #store_service_level="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_service_level.invalid }" required>
                                                <option>Select 1</option>
                                                <option>Select 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Select Price Config</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_price_config" [(ngModel)]="model.store_price_config" #store_price_config="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_price_config.invalid }" required>
                                                <option>Select 1</option>
                                                <option>Select 2</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Select Brand</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_select_brand" [(ngModel)]="model.store_select_brand" #store_select_brand="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_select_brand.invalid }" required>
                                                <option value="">Select </option>
                                                <option value="enCore">enCore</option>
                                                <option value="Chevron">Chevron</option>
                                                <option value="Citgo">Citgo</option>
                                                <option value="Shell">Shell</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label for="exampleFormControlSelect9">Select tank monitoring system</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_tank_monitoring_system" [(ngModel)]="model.store_tank_monitoring_system" #store_tank_monitoring_system="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_tank_monitoring_system.invalid }" required>
                                                <option value="TLS350">TLS350</option>
                                                <option value="TLS450">TLS450</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Pre-auth key</label>
                                            <input type="text" class="form-control" name="store_pre_auth_key" [(ngModel)]="model.store_pre_auth_key" #store_pre_auth_key="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && store_pre_auth_key.invalid }" required>
                                        </div>
                                    </div> 
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Scan Data Details</h4>
                                    </div>
                                    
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>RJR Account Number</label>
                                            <input type="text" class="form-control" name="rjr_account_no" [(ngModel)]="model.rjr_account_no" #rjr_account_no="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && rjr_account_no.invalid }" >
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Phillip Morris Account Number</label>
                                            <input type="text" class="form-control" name="altria_account_no" [(ngModel)]="model.altria_account_no" #altria_account_no="ngModel" [ngClass]="{ 'is-invalid': storeInfoForm.submitted && altria_account_no.invalid }" >
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-6 col-md-6 col-12">
                                        <div class="updatebtn">
                                            <button (click)="salesUpdateConti('sales_tax')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-2" role="tabpanel" aria-labelledby="info-tab-2" class="tab-pane fade" [ngClass]="{'show active': openTab == 'sales_tax'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Add sales tax
                                                    <button class="btn btn-primary pull-right" (click)="AddSalesTax.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="modelSalesTaxList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" [limit]="10">
                                                    <ngx-datatable-column name="Sales Tax Name" prop="store_sales_tax_name"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="POS Tax" prop="store_sales_pos_tax"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="City Tax" prop="store_sales_city_tax"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Country Tax" prop="store_sales_country_tax"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="State Tax" prop="store_sales_state_tax"></ngx-datatable-column> 
                                                    
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="AddSalesTax.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeAddSalesTax(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div> 
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Fuel tax
                                                    <button class="btn btn-primary pull-right" (click)="AddFuelTax.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="modelFuelTaxList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Fuel tax description" prop="store_fuel_tax_description"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Fuel tax" prop="store_fuel_tax"></ngx-datatable-column> 
                                                    <!-- <ngx-datatable-column name="Tax on tax" prop="store_fuel_tax_on_tax"></ngx-datatable-column>  -->
                                                    <ngx-datatable-column name="Fuel tax rate" prop="store_fuel_tax_rate"></ngx-datatable-column> 
                                                   
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="AddFuelTax.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeAddFuelTax(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button (click)="salesUpdateConti('sales_restriction')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div id="info-3" role="tabpanel" aria-labelledby="info-tab-3" class="tab-pane fade" [ngClass]="{'show active': openTab == 'sales_restriction'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Add new sales restriction
                                                    <button class="btn btn-primary pull-right" (click)="AddSalesRestriction.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelSalesRestrictionList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Pos sales restriction id" prop="_id"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Pos sales restriction name" prop="store_pos_sales_restriction_name"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Min cust age" prop="store_sales_restriction_min_cust_age"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Min clerk age" prop="store_sales_restriction_min_clerk_age"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Transaction limit" prop="store_sales_restriction_transaction_limit"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Transaction Sales restrict" prop="store_sales_restrict"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Prohibit discount" prop="store_pos_sales_prohibit_discount"></ngx-datatable-column> 
                                                    
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="AddSalesRestriction.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeSalesRestriction(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button (click)="salesUpdateConti('fuel_details')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-4" role="tabpanel" aria-labelledby="info-tab-4" class="tab-pane fade" [ngClass]="{'show active': openTab == 'fuel_details'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <!-- <div class="col-xl-12">
                                        <h4 class="topSellingItem">Add blend details</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Select fuel grade</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9">
                                                <option>0%</option>
                                                <option>50%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Select fuel grade</label>
                                            <select class="form-control form-select digits" id="exampleFormControlSelect9">
                                                <option>0%</option>
                                                <option>50%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-6 col-md-6 col-12">
                                        <div class="updatebtn">
                                            <button style="margin-right: 10px;">Save</button>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Tank Setting
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <form #tanksettingForm="ngForm"
                                                (ngSubmit)="onSubmitTankSetting($event,tanksettingForm)">
                                                <div class="row m-0 p-tb">
                                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                                        <div class="mb-3 theme-form">
                                                            <label for="exampleFormControlSelect9">Do you have Gas?</label>
                                                            <select class="form-control form-select digits"
                                                                name="is_having_gas"
                                                                [(ngModel)]="tankSettingModel.is_having_gas"
                                                                #is_having_gas="ngModel"
                                                                [ngClass]="{ 'is-invalid': tanksettingForm.submitted && is_having_gas.invalid }"
                                                                required  (change)="enableTankSettingFields($event.target.value)">
                                                                <option value="">Select</option>
                                                                <option value="0">I donot sell Gas</option>
                                                                <option value="1">Ownership Gas</option>
                                                                <option value="2">Agent Gas</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-0 p-tb" *ngIf="issellgas">
                                                    
                                                        
                                                        <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">Plus Grade Settings</label>
                                                                <select class="form-control form-select digits"
                                                                    name="grade_tank_type"
                                                                    [(ngModel)]="tankSettingModel.grade_tank_type"
                                                                    #grade_tank_type="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && grade_tank_type.invalid }"
                                                                    required>
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let plusGradeTankTyp of plusGradeTankType"
                                                                        [value]="plusGradeTankTyp.id">
                                                                        {{plusGradeTankTyp.name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label>Blend Ratio</label>
                                                                <input type="text" class="form-control" name="blend_ratio"
                                                                    [(ngModel)]="tankSettingModel.blend_ratio"
                                                                    #blend_ratio="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && blend_ratio.invalid }"
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">Does the store sell Diesel?</label>
                                                                <select class="form-control form-select digits"
                                                                    name="is_sell_diesel"
                                                                    [(ngModel)]="tankSettingModel.is_sell_diesel"
                                                                    #is_sell_diesel="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && is_sell_diesel.invalid }"
                                                                    required>
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let yesnooption of yesnooptions"
                                                                        [value]="yesnooption.id">
                                                                        {{yesnooption.name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-4 col-md-6 col-12" *ngIf="showAgentFields">
                                                            <div class="mb-3 theme-form">
                                                                <label>Comm Per Volume of Gas</label>
                                                                <input type="text" class="form-control" name="comm_per_volume"
                                                                    [(ngModel)]="tankSettingModel.comm_per_volume"
                                                                    #comm_per_volume="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && comm_per_volume.invalid }"
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-2 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label>Federal Multiplier</label>
                                                                <input type="text" class="form-control"
                                                                    name="federal_multiplier"
                                                                    [(ngModel)]="tankSettingModel.federal_multiplier"
                                                                    #federal_multiplier="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && federal_multiplier.invalid }"
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label>Federal tolerance</label>
                                                                <input type="text" class="form-control" name="federal_tolerance"
                                                                    [(ngModel)]="tankSettingModel.federal_tolerance"
                                                                    #federal_tolerance="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && federal_tolerance.invalid }"
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label>State multiplier</label>
                                                                <input type="text" class="form-control" name="state_multiplier"
                                                                    [(ngModel)]="tankSettingModel.state_multiplier"
                                                                    #state_multiplier="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && state_multiplier.invalid }"
                                                                    required>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label>State tolerance</label>
                                                                <input type="text" class="form-control" name="state_tolerance"
                                                                    [(ngModel)]="tankSettingModel.state_tolerance"
                                                                    #state_tolerance="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && state_tolerance.invalid }"
                                                                    required>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-xl-3 col-lg-3 col-md-6 col-12" *ngIf="showAgentFields">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">Tank report
                                                                    template</label>
                                                                <select class="form-control form-select digits"
                                                                    name="tank_report_template"
                                                                    [(ngModel)]="tankSettingModel.tank_report_template"
                                                                    #tank_report_template="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && tank_report_template.invalid }"
                                                                    required>
                                                                    <option value="AA">AA</option>
                                                                    <option value="AE">AE</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">Do you have 2 blended
                                                                    grades</label>
                                                                <select class="form-control form-select digits"
                                                                    name="is_two_blended_grades"
                                                                    [(ngModel)]="tankSettingModel.is_two_blended_grades"
                                                                    #is_two_blended_grades="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && is_two_blended_grades.invalid }"
                                                                    required>
                                                                    <option *ngFor="let yesnooption of yesnooptions"
                                                                        [value]="yesnooption.id">
                                                                        {{yesnooption.name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                                        <!--END BASIC FORM-->
        
                                                        <!--START SETTLMENT FORM-->
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">How is the jobber paid for gas load?</label>
                                                                <select class="form-control form-select digits"
                                                                    id="exampleFormControlSelect9" name="gas_supplier_paid_mode"
                                                                    [(ngModel)]="tankSettingModel.gas_supplier_paid_mode"
                                                                    #gas_supplier_paid_mode="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && gas_supplier_paid_mode.invalid }"
                                                                    required>
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let gassupliersmode of gassupliersmodes"
                                                                        [value]="gassupliersmode.id">
                                                                        {{gassupliersmode.name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                                            <div class="mb-3 theme-form">
                                                                <label for="exampleFormControlSelect9">Settlement bank account</label>
                                                                <select class="form-control form-select digits"
                                                                    id="exampleFormControlSelect9" name="store_bank_account_id"
                                                                    [(ngModel)]="tankSettingModel.store_bank_account_id"
                                                                    #store_bank_account_id="ngModel"
                                                                    [ngClass]="{ 'is-invalid': tanksettingForm.submitted && store_bank_account_id.invalid }"
                                                                    required>
                                                                    <option value="">Select</option>
                                                                    <option
                                                                        *ngFor="let storesBankaccount of storeBankaccountList"
                                                                        [value]="storesBankaccount._id">
                                                                        {{storesBankaccount.store_bank_account_name}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="row m-0 p-tb">
                                                    <!--START BASIC FORM-->
                                                    
                                                    
                                                    <div class="col-xl-12">
                                                        <h4 class="topSellingItem">Gas cash card</h4>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                                        <div class="mb-3 theme-form">
                                                            <label for="exampleFormControlSelect9">Do you have gas cash card?</label>
                                                            <select class="form-control form-select digits"
                                                                name="is_gas_cashcard"
                                                                [(ngModel)]="tankSettingModel.is_gas_cashcard"
                                                                #is_gas_cashcard="ngModel"
                                                                [ngClass]="{ 'is-invalid': tanksettingForm.submitted && is_gas_cashcard.invalid }"
                                                                required >
                                                                <option value="">Select</option>
                                                                <option *ngFor="let yesnooption of yesnooptions"
                                                                    [value]="yesnooption.id">
                                                                    {{yesnooption.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12">
                                                        <h4 class="topSellingItem">Credit Card Settlement</h4>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                                        <div class="mb-3 theme-form">
                                                            <label for="exampleFormControlSelect9">Gas Type?</label>
                                                            <select class="form-control form-select digits"
                                                                name="cc_grade_tank_type"
                                                                [(ngModel)]="tankSettingModel.cc_grade_tank_type"
                                                                #cc_grade_tank_type="ngModel"
                                                                [ngClass]="{ 'is-invalid': tanksettingForm.submitted && cc_grade_tank_type.invalid }"
                                                                required>
                                                                <option value="">Select</option>
                                                                <option *ngFor="let plusGradeTankTyp of plusGradeTankType"
                                                                    [value]="plusGradeTankTyp.id">
                                                                    {{plusGradeTankTyp.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                                        <div class="mb-3 theme-form">
                                                            <label for="exampleFormControlSelect9">How does the gas supplier
                                                                settle credit cards?</label>
                                                            <select class="form-control form-select digits"
                                                                id="exampleFormControlSelect9"
                                                                name="gas_supplier_settle_credit_card"
                                                                [(ngModel)]="tankSettingModel.gas_supplier_settle_credit_card"
                                                                #gas_supplier_settle_credit_card="ngModel"
                                                                [ngClass]="{ 'is-invalid': tanksettingForm.submitted && gas_supplier_settle_credit_card.invalid }"
                                                                required>
                                                                <option value="">Select</option>
                                                                <option
                                                                    *ngFor="let gassuplierscreditsettl of gassuplierscreditsettls"
                                                                    [value]="gassuplierscreditsettl.id">
                                                                    {{gassuplierscreditsettl.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                                        <div class="updatebtn">
                                                            <!-- <button class="back">Back</button> -->
                                                            <button>Save Settings</button>
                                                        </div>
                                                    </div>
    
                                                </div>
                                            </form> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Add fuel grade
                                                    <button class="btn btn-primary pull-right" (click)="addfuelgrade.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelFuelGradeList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Fuel grade no" prop="store_fuel_grade_id"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Fuel grade name" prop="store_fuel_grade"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Fuel department" prop="store_fuel_department"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Tax" prop="fueltax.store_fuel_tax_description"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="addfuelgrade.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeFuelGrade(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>Tank details
                                                    <button class="btn btn-primary pull-right" (click)="addtank.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelTankList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Tank no" prop="store_tank_number"></ngx-datatable-column> 
                                                    
                                                    <ngx-datatable-column name="Tank Height" prop="store_tank_height"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Tank ullage" prop="store_tank_ullage"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Current tank vol" prop="store_cuurent_tank_volume"></ngx-datatable-column> 
                                                    <!-- <ngx-datatable-column name="Connect tank" prop="store_fuel_tax"></ngx-datatable-column>  -->
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="addtank.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeTank(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button (click)="salesUpdateConti('store_location')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-5" role="tabpanel" aria-labelledby="info-tab-5" class="tab-pane fade" [ngClass]="{'show active': openTab == 'store_location'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Add new store fess</h4>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"  (click)="addstorelocation.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelStoreLocationList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Fees name" prop="store_fees_name"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="POS fee Id" prop="store_pos_fees_id"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="POS fee" prop="store_pos_fee"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="POS amount range" prop="store_pos_amount_range"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Is refundable" prop="store_fees_is_refundable"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="addstorelocation.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeStoreLocation(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button (click)="salesUpdateConti('payment_method')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-6" role="tabpanel" aria-labelledby="info-tab-6" class="tab-pane fade" [ngClass]="{'show active': openTab == 'payment_method'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Add new store location method of payment</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="mb-3 theme-form">
                                            <div class="job-filter">
                                                <div class="faq-form">
                                                    <input class="form-control" type="text" placeholder="Search..">
                                                    <i class="icon-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"  (click)="addpaymentmethod.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelPaymentMethodList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Store MOP No" prop="store_mop_no"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="MOP name" prop="store_mop_name"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="System MOP" prop="store_system_mop"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Action" widht="300">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="addpaymentmethod.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removePaymentMethod(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button (click)="salesUpdateConti('store_services')">Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-7" role="tabpanel" aria-labelledby="info-tab-7" class="tab-pane fade" [ngClass]="{'show active': openTab == 'store_services'}">
                            <form #storeServicesForm="ngForm" (ngSubmit)="onSubmitstoreServicesForm(storeServicesForm)">
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Store Services</h4>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="card-body switch-showcase box-one">
                                            <h2>ATM Service</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store ATM</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="is_store_atm" [(ngModel)]="storeServicesModel.is_store_atm" #is_store_atm="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Atm Transaction fee</label>
                                                        <input type="text" class="form-control" name="store_atm_transaction_fee" [(ngModel)]="storeServicesModel.store_atm_transaction_fee" #store_atm_transaction_fee="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select Credit Bank Account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_atm_credit_bank_account" [(ngModel)]="storeServicesModel.store_atm_credit_bank_account" #store_atm_credit_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Bill pay service ?</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="is_bill_pay_service" [(ngModel)]="storeServicesModel.is_bill_pay_service" #is_bill_pay_service="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select Credit Bank Account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_bill_pay_service_credit_bank_account" [(ngModel)]="storeServicesModel.store_bill_pay_service_credit_bank_account" #store_bill_pay_service_credit_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Check cashing Services</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="check_cashing_services" [(ngModel)]="storeServicesModel.check_cashing_services" #check_cashing_services="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store accept coupons</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="is_store_accept_coupons" [(ngModel)]="storeServicesModel.is_store_accept_coupons" #is_store_accept_coupons="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Money transfer</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="is_money_transfer" [(ngModel)]="storeServicesModel.is_money_transfer" #is_money_transfer="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <!-- <div class="card-body switch-showcase box-one">
                                            <h2>Mobile Coupons</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store accept mobile coupons</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_accept_mobile_coupons" [(ngModel)]="storeServicesModel.store_accept_mobile_coupons" #store_accept_mobile_coupons="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select bank account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="mobile_coupons_bank_account" [(ngModel)]="storeServicesModel.mobile_coupons_bank_account" #mobile_coupons_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="card-body switch-showcase box-one">
                                            <h2>Food stamps</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store accept food stamps</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_accept_food_stamps" [(ngModel)]="storeServicesModel.store_accept_food_stamps" #store_accept_food_stamps="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select bank account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="food_stamps_bank_account" [(ngModel)]="storeServicesModel.food_stamps_bank_account" #food_stamps_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <h2>Fishing game licences</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store sales fish & game licence</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_sales_fish_n_game_licence" [(ngModel)]="storeServicesModel.store_sales_fish_n_game_licence" #store_sales_fish_n_game_licence="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Enter licence fee</label>
                                                        <input type="text" class="form-control" name="fish_n_game_licence_fee" [(ngModel)]="storeServicesModel.fish_n_game_licence_fee" #fish_n_game_licence_fee="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select bank account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="fish_n_game_licence_bank_account" [(ngModel)]="storeServicesModel.fish_n_game_licence_bank_account" #fish_n_game_licence_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="card-body switch-showcase box-one">
                                            <h2>Lottery lotto & keno</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Store has keno machine</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_has_keno_machine" [(ngModel)]="storeServicesModel.store_has_keno_machine" #store_has_keno_machine="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Lottery commision</label>
                                                        <input type="text" class="form-control" name="lottery_commision" [(ngModel)]="storeServicesModel.lottery_commision" #lottery_commision="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select debit bank account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="lottery_commision_licence_bank_account" [(ngModel)]="storeServicesModel.lottery_commision_licence_bank_account" #lottery_commision_licence_bank_account="ngModel">
                                                            <option>Amex</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body switch-showcase box-one">
                                            <h2>Money order</h2>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Sell money order</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="sell_money_order" [(ngModel)]="storeServicesModel.sell_money_order" #sell_money_order="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select company name</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="money_order_company_name" [(ngModel)]="storeServicesModel.money_order_company_name" #money_order_company_name="ngModel">
                                                            <option>company 1</option>
                                                            <option>company 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Money order fee</label>
                                                        <input type="text" class="form-control" name="money_order_fee" [(ngModel)]="storeServicesModel.money_order_fee" #money_order_fee="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Money order buying</label>
                                                        <input type="text" class="form-control" name="money_order_buying" [(ngModel)]="storeServicesModel.money_order_buying" #money_order_buying="ngModel">
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">select debit bank account</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="money_order_debit_bank_account" [(ngModel)]="storeServicesModel.money_order_debit_bank_account" #money_order_debit_bank_account="ngModel">
                                                            <option>Bank 1</option>
                                                            <option>Bank 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button>Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-8" role="tabpanel" aria-labelledby="info-tab-8" class="tab-pane fade" [ngClass]="{'show active': openTab == 'lottery_settings'}">
                            <form #lotterySettingsForm="ngForm" (ngSubmit)="onSubmitlotterySettingsForm(lotterySettingsForm)">
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Lottery settings</h4>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div class="card-body switch-showcase box-one">
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Lottery settings</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_loteery_setting" [(ngModel)]="lotteryModel.store_loteery_setting" #store_loteery_setting="ngModel" required><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Does ticket number start with zero</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_is_ticket_number_start_with_zero" [(ngModel)]="lotteryModel.store_is_ticket_number_start_with_zero" #store_is_ticket_number_start_with_zero="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="media">
                                                <label class="col-form-label m-r-10">Do you sell the ticket in reverse order</label>
                                                <div class="media-body text-right">
                                                    <label class="switch">
                                                    <input type="checkbox" name="store_sell_ticket_in_reverse_order" [(ngModel)]="lotteryModel.store_sell_ticket_in_reverse_order" #store_sell_ticket_in_reverse_order="ngModel"><span class="switch-state"></span>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select department</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_lottery_depratment" [(ngModel)]="lotteryModel.store_lottery_depratment"  #store_lottery_depratment="ngModel">
                                                            <option *ngFor="let department of departmentList" [value]="department._id">
                                                                {{department.department_name}}
                                                              </option>
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button>Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-9" role="tabpanel" aria-labelledby="info-tab-9" class="tab-pane fade" [ngClass]="{'show active': openTab == 'bank_account'}">
                            <!-- <form #addbankAccountForm="ngForm" (ngSubmit)="onSubmitaddbankAccountForm(addbankAccountForm)"> -->
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Add bank account</h4>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"  (click)="addbankaccount.openModal();$event.preventDefault()">Add new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap"  [rows]="modelAddBankAccountList" [loadingIndicator]="loadingIndicator"
                                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                    [reorderable]="reorderable" >
                                                    <ngx-datatable-column name="Account name" prop="store_bank_account_name"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Account type" prop="store_bank_account_type"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Routing Number" prop="store_bank_routing_number"></ngx-datatable-column> 
                                                    <ngx-datatable-column name="Action" widht="600">    
                                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn" (click)="addbankaccount.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn" (click)="removeBankaccount(row._id)"><i class="fa fa-trash"></i></button>
                                                            </div>   
                                                        </ng-template>    
                                                    </ngx-datatable-column> 
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                                        <div class="card-body switch-showcase box-one">
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Account name</label>
                                                        <input type="text" class="form-control" name="store_bank_account_name" [(ngModel)]="addBankAccountModel.store_bank_account_name" #store_bank_account_name="ngModel" required>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label for="exampleFormControlSelect9">Select account type</label>
                                                        <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_bank_account_type" [(ngModel)]="addBankAccountModel.store_bank_account_type" #store_bank_account_type="ngModel" required>
                                                            <option>Select 1</option>
                                                            <option>Select 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Bank address</label>
                                                        <input type="text" class="form-control" name="store_bank_address" [(ngModel)]="addBankAccountModel.store_bank_address" #store_bank_address="ngModel" required>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Zip code</label>
                                                        <input type="text" class="form-control" name="store_bank_zip_code" [(ngModel)]="addBankAccountModel.store_bank_zip_code" #store_bank_zip_code="ngModel" required>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12">
                                                    <div class="mb-3 theme-form">
                                                        <label>Bank branch</label>
                                                        <input type="text" class="form-control" name="store_bank_branch" [(ngModel)]="addBankAccountModel.store_bank_branch" #store_bank_branch="ngModel" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Account name</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Select account type</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Bank address</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Zip code</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="mb-3 theme-form">
                                            <label>Bank branch</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div> -->

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button>Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            <!-- </form> -->
                        </div>
                        <div id="info-10" role="tabpanel" aria-labelledby="info-tab-10" class="tab-pane fade" [ngClass]="{'show active': openTab == 'setting_off'}">
                            <form #settingoffForm="ngForm" (ngSubmit)="onSubmitsettingoffForm(settingoffForm)">
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Setting (Time Off)</h4>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card-body switch-showcase box-one-last">
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <div class="d-flex">
                                                        <div class="checkbox checkbox-primary p-0 leftmargin">
                                                            <input class="form-check-input" id="checkbox-primary-5" type="checkbox" name="store_is_limit_timeoff_request" [(ngModel)]="settingoffModel.store_is_limit_timeoff_request" #store_is_limit_timeoff_request="ngModel" required>
                                                            <label class="form-check-label" for="checkbox-primary-5">Limit time of requests to no more than</label>
                                                        </div>
                                                        <div class="pay-para">
                                                            <input type="text" class="form-control" name="store_limit_timeoff_request" [(ngModel)]="settingoffModel.store_limit_timeoff_request" #store_limit_timeoff_request="ngModel" required>
                                                            <p>People per day.</p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="checkbox checkbox-primary p-0 leftmargin">
                                                            <input class="form-check-input" id="checkbox-primary-6" type="checkbox" name="store_do_not_allow_time_off_requests" [(ngModel)]="settingoffModel.store_do_not_allow_time_off_requests" #store_do_not_allow_time_off_requests="ngModel" required  required>
                                                            <label class="form-check-label" for="checkbox-primary-6">Do not allow time off requests less than</label>
                                                        </div>
                                                        <div class="pay-para">
                                                            <input type="text" class="form-control" name="store_do_not_allow_time_off_requests_limit" [(ngModel)]="settingoffModel.store_do_not_allow_time_off_requests_limit" #store_do_not_allow_time_off_requests_limit="ngModel" required>
                                                            <p>Days in advanced</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button>Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-11" role="tabpanel" aria-labelledby="info-tab-11" class="tab-pane fade" [ngClass]="{'show active': openTab == 'setting_scheduling'}">
                            <form #schedulesettingForm="ngForm" (ngSubmit)="onSubmitschedulesettingForm(schedulesettingForm)">
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12">
                                        <h4 class="topSellingItem">Setting Scheduling</h4>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card-body switch-showcase box-one-last">
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p class="heading-shift">Days</p>
                                                            <p>Monday</p>
                                                        </div>
                                                        <div>
                                                            <p class="heading-shift">First Shift</p>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Monday1" type="checkbox" name="monday_first_shift" [(ngModel)]="schedulingsettingModel.monday_first_shift" #monday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Monday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="monday_first_shift_in" [(ngModel)]="schedulingsettingModel.monday_first_shift_in" #monday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="monday_first_shift_out" [(ngModel)]="schedulingsettingModel.monday_first_shift_out" #monday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p class="heading-shift">Second Shift</p>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Monday2" type="checkbox" name="monday_second_shift" [(ngModel)]="schedulingsettingModel.monday_second_shift" #monday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Monday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="monday_second_shift_in" [(ngModel)]="schedulingsettingModel.monday_second_shift_in" #monday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="monday_second_shift_out" [(ngModel)]="schedulingsettingModel.monday_second_shift_out" #monday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p class="heading-shift">Third Shift</p>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Monday3" type="checkbox" name="monday_third_shift" [(ngModel)]="schedulingsettingModel.monday_third_shift" #monday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Monday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="monday_third_shift_in" [(ngModel)]="schedulingsettingModel.monday_third_shift_in" #monday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="monday_third_shift_out" [(ngModel)]="schedulingsettingModel.monday_third_shift_out" #monday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Tuesday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Tuesday1" type="checkbox" name="tuesday_first_shift" [(ngModel)]="schedulingsettingModel.tuesday_first_shift" #tuesday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Tuesday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="tuesday_first_shift_in" [(ngModel)]="schedulingsettingModel.tuesday_first_shift_in" #tuesday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="tuesday_first_shift_out" [(ngModel)]="schedulingsettingModel.tuesday_first_shift_out" #tuesday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Tuesday2" type="checkbox" name="tuesday_second_shift" [(ngModel)]="schedulingsettingModel.tuesday_second_shift" #tuesday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Tuesday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="tuesday_second_shift_in" [(ngModel)]="schedulingsettingModel.tuesday_second_shift_in" #tuesday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="tuesday_second_shift_out" [(ngModel)]="schedulingsettingModel.tuesday_second_shift_out" #tuesday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Tuesday3" type="checkbox" name="tuesday_third_shift" [(ngModel)]="schedulingsettingModel.tuesday_third_shift" #tuesday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Tuesday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="tuesday_third_shift_in" [(ngModel)]="schedulingsettingModel.tuesday_third_shift_in" #tuesday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="tuesday_third_shift_out" [(ngModel)]="schedulingsettingModel.tuesday_third_shift_out" #tuesday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Wednesday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Wednesday1" type="checkbox" name="wednesday_first_shift" [(ngModel)]="schedulingsettingModel.wednesday_first_shift" #wednesday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Wednesday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="wednesday_first_shift_in" [(ngModel)]="schedulingsettingModel.wednesday_first_shift_in" #wednesday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="wednesday_first_shift_out" [(ngModel)]="schedulingsettingModel.wednesday_first_shift_out" #wednesday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Wednesday2" type="checkbox" name="wednesday_second_shift" [(ngModel)]="schedulingsettingModel.wednesday_second_shift" #wednesday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Wednesday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="wednesday_second_shift_in" [(ngModel)]="schedulingsettingModel.wednesday_second_shift_in" #wednesday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="wednesday_second_shift_out" [(ngModel)]="schedulingsettingModel.wednesday_second_shift_out" #wednesday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Wednesday3" type="checkbox" name="wednesday_third_shift" [(ngModel)]="schedulingsettingModel.wednesday_third_shift" #wednesday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Wednesday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="wednesday_third_shift_in" [(ngModel)]="schedulingsettingModel.wednesday_third_shift_in" #wednesday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="wednesday_third_shift_out" [(ngModel)]="schedulingsettingModel.wednesday_third_shift_out" #wednesday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Thursday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Thursday1" type="checkbox" name="thusrday_first_shift" [(ngModel)]="schedulingsettingModel.thusrday_first_shift" #thusrday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Thursday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="thusrday_first_shift_in" [(ngModel)]="schedulingsettingModel.thusrday_first_shift_in" #thusrday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="thusrday_first_shift_out" [(ngModel)]="schedulingsettingModel.thusrday_first_shift_out" #thusrday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Thursday2" type="checkbox" name="thusrday_second_shift" [(ngModel)]="schedulingsettingModel.thusrday_second_shift" #thusrday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Thursday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="thusrday_second_shift_in" [(ngModel)]="schedulingsettingModel.thusrday_second_shift_in" #thusrday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="thusrday_second_shift_out" [(ngModel)]="schedulingsettingModel.thusrday_second_shift_out" #thusrday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Thursday3" type="checkbox" name="thusrday_third_shift" [(ngModel)]="schedulingsettingModel.thusrday_third_shift" #thusrday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Thursday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="thusrday_third_shift_in" [(ngModel)]="schedulingsettingModel.thusrday_third_shift_in" #thusrday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="thusrday_third_shift_out" [(ngModel)]="schedulingsettingModel.thusrday_third_shift_out" #thusrday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Friday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Friday1" type="checkbox" name="friday_first_shift" [(ngModel)]="schedulingsettingModel.friday_first_shift" #friday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Friday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="friday_first_shift_in" [(ngModel)]="schedulingsettingModel.friday_first_shift_in" #friday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="friday_first_shift_out" [(ngModel)]="schedulingsettingModel.friday_first_shift_out" #friday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Friday2" type="checkbox" name="friday_second_shift" [(ngModel)]="schedulingsettingModel.friday_second_shift" #friday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Friday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="friday_second_shift_in" [(ngModel)]="schedulingsettingModel.friday_second_shift_in" #friday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="friday_second_shift_out" [(ngModel)]="schedulingsettingModel.friday_second_shift_out" #friday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Friday3" type="checkbox" name="friday_third_shift" [(ngModel)]="schedulingsettingModel.friday_third_shift" #friday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Friday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="friday_third_shift_in" [(ngModel)]="schedulingsettingModel.friday_third_shift_in" #friday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="friday_third_shift_out" [(ngModel)]="schedulingsettingModel.friday_third_shift_out" #friday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Saturday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Saturday1" type="checkbox" name="saturday_first_shift" [(ngModel)]="schedulingsettingModel.saturday_first_shift" #saturday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Saturday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="saturday_first_shift_in" [(ngModel)]="schedulingsettingModel.saturday_first_shift_in" #saturday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="saturday_first_shift_out" [(ngModel)]="schedulingsettingModel.saturday_first_shift_out" #saturday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Saturday2" type="checkbox" name="saturday_second_shift" [(ngModel)]="schedulingsettingModel.saturday_second_shift" #saturday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Saturday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="saturday_second_shift_in" [(ngModel)]="schedulingsettingModel.saturday_second_shift_in" #saturday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="saturday_second_shift_out" [(ngModel)]="schedulingsettingModel.saturday_second_shift_out" #saturday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Saturday3" type="checkbox" name="saturday_third_shift" [(ngModel)]="schedulingsettingModel.saturday_third_shift" #saturday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Saturday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="saturday_third_shift_in" [(ngModel)]="schedulingsettingModel.saturday_third_shift_in" #saturday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="saturday_third_shift_out" [(ngModel)]="schedulingsettingModel.saturday_third_shift_out" #saturday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="time-flex">
                                                        <div class="first-month">
                                                            <p>Sunday</p>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Sunday1" type="checkbox" name="sunday_first_shift" [(ngModel)]="schedulingsettingModel.sunday_first_shift" #sunday_first_shift="ngModel">
                                                                    <label class="form-check-label" for="Sunday1"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="sunday_first_shift_in" [(ngModel)]="schedulingsettingModel.sunday_first_shift_in" #sunday_first_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="sunday_first_shift_out" [(ngModel)]="schedulingsettingModel.sunday_first_shift_out" #sunday_first_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Sunday2" type="checkbox" name="sunday_second_shift" [(ngModel)]="schedulingsettingModel.sunday_second_shift" #sunday_second_shift="ngModel">
                                                                    <label class="form-check-label" for="Sunday2"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="sunday_second_shift_in" [(ngModel)]="schedulingsettingModel.sunday_second_shift_in" #sunday_second_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="sunday_second_shift_out" [(ngModel)]="schedulingsettingModel.sunday_second_shift_out" #sunday_second_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex">
                                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                                    <input class="form-check-input" id="Sunday3" type="checkbox" name="sunday_third_shift" [(ngModel)]="schedulingsettingModel.sunday_third_shift" #sunday_third_shift="ngModel">
                                                                    <label class="form-check-label" for="Sunday3"></label>
                                                                </div>
                                                                <div class="time-para">
                                                                    <input type="time" class="form-control" name="sunday_third_shift_in" [(ngModel)]="schedulingsettingModel.sunday_third_shift_in" #sunday_third_shift_in="ngModel">
                                                                    <input type="time" class="form-control" name="sunday_third_shift_out" [(ngModel)]="schedulingsettingModel.sunday_third_shift_out" #sunday_third_shift_out="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="updatebtn">
                                            <button class="back">Back</button>
                                            <button>Update & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<app-addsalestax (sendaddedsalestax) = 'reloadSalesTaxTable($event)' #addsalestax></app-addsalestax>
<app-addfueltax (sendaddedfueltax) = 'reloadFuelTaxTable($event)' #addfueltax></app-addfueltax>
<app-addsalesrestriction (sendaddedsalesrestriction) = 'reloadSalesRestrictionTable($event)' #addsalesrestriction></app-addsalesrestriction>
<app-addfuelgrade (sendaddfuelgrade) = 'reloadFuelGradeTable($event)' #addfuelgrade></app-addfuelgrade>
<app-addtank (sendaddtank) = 'reloadTankTable($event)' #addtank></app-addtank>
<app-addstorelocation (sendaddstorelocation) = 'reloadStorelocationTable($event)' #addstorelocation></app-addstorelocation>
<app-addpaymentmethod (sendaddpaymentmethod) = 'reloadPaymentmethodTable($event)' #addpaymentmethod></app-addpaymentmethod>
<app-addbankaccount (sendaddedbankaccount) = 'reloadBankAccountTable($event)' #addbankaccount></app-addbankaccount>