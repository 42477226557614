import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddmixmatchmaintenanceComponent } from './modal/addmixmatchmaintenance/addmixmatchmaintenance.component';
import { AddcombodealComponent } from './modal/addcombodeal/addcombodeal.component';
import { CONSTANTS } from 'src/app/util/constants';



@Component({
  selector: 'app-manage-promotions',
  templateUrl: './manage-promotions.component.html',
  styleUrls: ['./manage-promotions.component.scss']
})
export class ManagePromotionsComponent implements OnInit {
  @ViewChild("addmixmatchmaintenance") Addmixmatchmaintenance: AddmixmatchmaintenanceComponent;
  @ViewChild("addcombodeal") Addcombodeal: AddcombodealComponent;

  public openTab: string = "mixmatch";
  modelMixmatchMaintenanceList = [];
  modelCombodealList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelMixmatchMaintenanceList = await this.storeService.getMixMatchMatchMaintenance(data['_id']);
        this.modelCombodealList = await this.storeService.getComboDeal(data['_id']);
      }
    })
  }

  async reloadMixMatchMaintenanceTable(event){
    this.modelMixmatchMaintenanceList = await this.storeService.getMixMatchMatchMaintenance(event);
  }
  async reloadCombodealTable(event){
    this.modelCombodealList = await this.storeService.getComboDeal(event);
  }
  removeMixmatchmaintenance(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeMixmatchmaintenance(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadMixMatchMaintenanceTable(store_id);
          }
        });
    }
  }
  removeCombodeal(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeCombodeal(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadCombodealTable(store_id);
          }
        });
    }
  }
  async tabbed(val: any) {
    this.openTab = val;
    var store_id = JSON.parse(localStorage.getItem('store_id'));

    switch (val) {
      case 'mixmatch':
        this.modelMixmatchMaintenanceList = await this.storeService.getMixMatchMatchMaintenance(store_id);
        break;
      case 'combodeal':
        this.modelCombodealList = await this.storeService.getComboDeal(store_id);
        break;
        default:
    }
  }

}
