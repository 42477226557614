<ng-template #addstocktransaction let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Stock Transaction</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addstocktransaction="ngForm" (ngSubmit)="saveStocktransaction(addstocktransaction)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>UPC code</label>
                    <input class="form-control" type="text" name="stock_UPC_code" [(ngModel)]="modelStocktransaction.stock_UPC_code"  required>
                </div>
                <div class="form-group col-md-6">
                    <label>Transaction type</label>
                    <select class="form-control form-select digits"  id="exampleFormControlSelect9" name="stock_transaction_type" [(ngModel)]="modelStocktransaction.stock_transaction_type" required>
                        <option >
                            Select 1
                          </option>
                    </select>
                    
                </div>
                <div class="form-group col-md-6">
                    <label>To store </label>
                    <select class="form-control form-select digits"  id="exampleFormControlSelect9" [(ngModel)]="modelStocktransaction.stock_to_store">
                        <option *ngFor="let st of storeListarr" [value]="st._id">
                            {{st.store_full_name}}
                          </option>
                    </select>
                    
                </div>
                <div class="form-group col-md-6">
                    <label>Quantity</label>
                    <input class="form-control" type="text" name="stock_quantity" [(ngModel)]="modelStocktransaction.stock_quantity" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Modified date </label>
                    <input class="form-control" type="date" name="stock_modified_date" [(ngModel)]="modelStocktransaction.stock_modified_date" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Description </label>
                    <input class="form-control" type="text" name="stock_description" [(ngModel)]="modelStocktransaction.stock_description" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Note </label>
                    <input class="form-control" type="text" name="stock_note" [(ngModel)]="modelStocktransaction.stock_note" required>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>