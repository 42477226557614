<ng-template #addfuelgraderegistermapping let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #fuelgrademapping="ngForm" (ngSubmit)="saveFuelGradeData(fuelgrademapping)">
            <div class="form-row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div class="mb-3 theme-form">
                                <label>Register Fuel Grade ID </label>
                                <input type="text" class="form-control" name="register_id" [(ngModel)]="fuelgraderegistermapping.register_id" #register_id="ngModel"  required>
                            </div>
                        </div> 
                        <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div class="mb-3 theme-form">
                                <label>Fuel Grade Name</label>
                                <input type="text" class="form-control" name="fuelgrade_name" [ngModel]="fuelgraderegistermapping.fuelgrade_name" readonly>
                            </div>
                        </div> 
                        <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div class="mb-3 theme-form">
                                <label>Name Mapping</label>
                                <input type="text" class="form-control" name="display_name" [(ngModel)]="fuelgraderegistermapping.display_name" #display_name="ngModel" required>
                            </div>
                        </div> 
                        
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" >Save</button>
                    <button class="btn btn-secondary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>