import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginAdminComponent } from './auth/login-admin/login-admin.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthService } from './shared/services/firebase/auth.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CONSTANTS } from './util/constants';
import { HttpService } from './shared/services/http.service';
import { SessionService } from './shared/services/session.service';
import { AuthGuard } from './shared/services/auth.guard';
import { StoreModule } from './components/store/store.module';
import { StoreService } from './services/store.service';
import { VendorModule } from './components/vendor/vendor.module';
import { TaxSetupModule } from './components/tax-setup/tax-setup.module';
import { StockTransactionModule } from './components/stock-transaction/stock-transaction.module';
import { BuyDownManagersModule } from './components/buy-down-managers/buy-down-managers.module';
import { PhysicalInventoryModule } from './components/physical-inventory/physical-inventory.module';
import { AddVendorInvoiceItemModule } from './components/add-vendor-invoice-item/add-vendor-invoice-item.module';
import { ItemCategoryModule } from './components/item-category/item-category.module';
import { PriceGroupModule } from './components/price-group/price-group.module';
import { RoleManagementModule } from './components/role-management/role-management.module';

import { LiveDataModule } from './components/live-data/live-data.module';
import { UserManagementModule } from './components/user-management/user-management.module';
import {ManagePromotionsModule} from './components/manage-promotions/manage-promotions.module';
import { StoreRoleManagementModule } from './components/store-role-management/store-role-management.module';
import { StoreUserManagementModule } from './components/store-user-management/store-user-management.module';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ScanDataModule } from './components/scan-data/scan-data.module';
import { FuelRetailPriceModule } from './components/fuel-retail-price/fuel-retail-price.module';
import { StoreManufacturerModule } from './components/store-manufacturer/store-manufacturer.module';
import { BulkUpdateComponent } from './components/bulk-update/bulk-update.component';
import { BulkUpdateComponentModule } from './components/bulk-update/bulk-update.module';
import { RegisterMappingModule } from './components/register-mapping/register-mapping.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LoginAdminComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    StoreModule,
    VendorModule,
    StockTransactionModule,
    TaxSetupModule,
    BuyDownManagersModule,
    PhysicalInventoryModule,
    AddVendorInvoiceItemModule,
    ItemCategoryModule,
    PriceGroupModule,
    BulkUpdateComponentModule,
    RoleManagementModule,
    LiveDataModule,
    UserManagementModule,
    ManagePromotionsModule,
    StoreRoleManagementModule,
    StoreUserManagementModule,
    ScanDataModule,
    FuelRetailPriceModule,
    StoreManufacturerModule,
    RegisterMappingModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      progressBar: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule
  ],
  providers: [AuthService, AuthGuard, CONSTANTS, HttpService, SessionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
