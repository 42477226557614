<ng-template #addtank let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}} Tank</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class=" needs-validation" #addtank="ngForm" (ngSubmit)="saveTank(addtank)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank number</label>
                    <input class="form-control" type="text" name="store_tank_number" [(ngModel)]="modelStoreTank.store_tank_number" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Fuel grade</label>
                    <input class="form-control" type="text" name="store_fuel_grade" [(ngModel)]="modelStoreTank.store_fuel_grade" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank recorder level (%)</label>
                    <input class="form-control" type="text" name="store_tank_recorder_level" [(ngModel)]="modelStoreTank.store_tank_recorder_level" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank ullage</label>
                    <input class="form-control" type="text" name="store_tank_ullage" [(ngModel)]="modelStoreTank.store_tank_ullage" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank Height</label>
                    <input class="form-control" type="text" name="store_tank_height" [(ngModel)]="modelStoreTank.store_tank_height" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Select tank type</label>
                    <input class="form-control" type="text" name="store_tank_type" [(ngModel)]="modelStoreTank.store_tank_type" required>
                </div> -->
                <!-- <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank description</label>
                    <input class="form-control" type="text" name="store_tank_description" [(ngModel)]="modelStoreTank.store_tank_description" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank capacity</label>
                    <input class="form-control" type="text" name="store_tank_capacity" [(ngModel)]="modelStoreTank.store_tank_capacity" required>
                </div> -->
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Current tank volume</label>
                    <input class="form-control" type="text" name="store_cuurent_tank_volume" [(ngModel)]="modelStoreTank.store_cuurent_tank_volume" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Notes</label>
                    <input class="form-control" type="text" name="store_tank_details_notes" [(ngModel)]="modelStoreTank.store_tank_details_notes" required>
                </div> -->
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>