<div class="media profile-media">
    <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
    <div class="media-body"><span>{{ username }}</span>
        <p class="mb-0 font-roboto">Admin <i class="middle fa fa-angle-down"></i></p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    
    <li>
        <a href="/store"><span>Manage my store </span></a>
    </li>
    <li>
        <a href="javascript:void(0);">
            <app-feather-icons [icon]="'user'"></app-feather-icons><span>My profile/picture </span></a>
    </li>
    <!-- <li>
        <a href="javascript:void(0);">
            <app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a>
    </li> -->
    <!-- <li>
        <a href="javascript:void(0);">
            <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a>
    </li> -->
    <li>
        <a href="javascript:void(0);" (click)="signOut()">
            <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Sign Out</span></a>
    </li>
</ul>