<ng-template #addbuydownmanagers let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Buy Down Manager</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addbuydownmanagers="ngForm" (ngSubmit)="saveBuydownmanager(addbuydownmanagers)">
            <div class="form-row">

                <div class="row form-group col-md-6">
                    <div class="col-md-12 ">
                        <label>Manufacturer Name</label>
                    </div>
                    <div *ngIf="buydownmId == null" class="col-md-8 form-group">
                        <select class="form-control form-select digits" name="manufacturer_id" [(ngModel)]="modelBuydownmanager.manufacturer_id"  #manufacturer_id="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && manufacturer_id.invalid }" required>
                        <option value="">Select</option>
                        <option *ngFor="let smgr of manufacturers" [value]="smgr._id">
                          {{smgr.name}}
                        </option>
                        </select>
                    </div>
                    <div *ngIf="buydownmId != null" class="col-md-8 form-group">
                        <select  class="form-control form-select digits" name="manufacturer_id" [(ngModel)]="modelBuydownmanager.manufacturer_id"  #manufacturer_id="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && manufacturer_id.invalid }" disabled>
                        <option value="">Select</option>
                        <option *ngFor="let smgr of manufacturers" [value]="smgr._id">
                          {{smgr.name}}
                        </option>
                    </select>
                    </div>
                    <div  *ngIf="buydownmId == null"  class="ml-2 col-md-2 form-group">
                        <a  class="btn btn-primary updated-btn" (click)="addNewManufacturer()">+</a>
                    </div>
                </div>
                <div *ngIf="buydownmId == null" class="row form-group col-md-6">
                    <div class="col-md-12 ">
                        <label>Select Item code / Price Group</label>
                    </div>
                    
                    <div class="mt-2 ml-2 col-md-4 form-group">
                        <div class="radio radio-primary radio_item">
                            <input  id="itmcode" type="radio" name="buydownfor" (click)="showIemCodeSection()" [(ngModel)]="modelBuydownmanager.selectedBy" value="ItemCode" required
                            #selectedBy="ngModel" required>
                            <label  for="itmcode">Item Code</label>
                        </div>
                    </div>
                    <div class="mt-2 ml-2 col-md-4 form-group">
                        <div class="radio radio-primary radio_item">
                            <input  id="pricegrp" type="radio" name="buydownfor" (click)="showPriceGrpSection()" [(ngModel)]="modelBuydownmanager.selectedBy" value="PriceGroup" required
                            #selectedBy="ngModel" required>
                            <label  for="pricegrp">Price Group</label>
                        </div>
                    </div>
                    <div *ngIf="selectedBy.errors?.required" class="ml-3 text-danger">Please Select Item code / Price Group</div>
                </div>
                <div *ngIf="itmcode" class="form-group col-md-6">
                    <label>UPC Code / Description </label>
                    
                            <ng-select name="item_list_name" [multiple]="false" [(ngModel)]="modelBuydownmanager.item_id"  (search)="onSearch($event)" (remove)="onRemove($event)" (change)="getItemDetail($event)" #item_id="ngModel" [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && item_id.invalid }" required>
                                <ng-option *ngFor="let storeItem1 of searchstoreitems" [value]="storeItem1._id" >
                                    {{ storeItem1.upc_code }} ({{storeItem1.item_description}})
                                </ng-option>
                             </ng-select>
                    
                             <div *ngIf="!modelBuydownmanager.item_id" class="invalid-feedback">
                                UPC Code / Description is required
                            </div>
                    <!-- <input type="text" class="form-control" name="item_code" [(ngModel)]="modelBuydownmanager.item_code" #item_code="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && item_code.invalid }" (blur)="onSearchByUpc($event)" required>
                    <input class="form-control" type="hidden" name="item_id" [(ngModel)]="modelBuydownmanager.item_id"> -->
                </div>
                <div *ngIf="pricegrp" class="form-group col-md-6">
                    <label>Select Price Group </label>
                    <select class="form-control form-select digits" name="price_group_id" [(ngModel)]="modelBuydownmanager.price_group_id" #price_group_id="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && price_group_id.invalid }" (change)="getGroupItems($event)" required>
                        <option value="">Select</option>
                        <option *ngFor="let prg of pricegroups" [value]="prg._id">
                          {{prg.name}}
                        </option>
                    </select>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Item Name</label>
                    <input class="form-control" type="text" [(ngModel)]="modelBuydownmanager.item_name" name="item_name" readonly>
                </div> -->
                <div class="form-group col-md-6">
                    <label>Discount Given By Manufacturer </label>
                    <input class="form-control" type="number" name="discount_given_by_manufacturer" [(ngModel)]="modelBuydownmanager.discount_given_by_manufacturer" #discount_given_by_manufacturer="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && discount_given_by_manufacturer.invalid }" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Actual Price of Item </label>
                    <input class="form-control" type="number" name="actual_price_of_item" [(ngModel)]="modelBuydownmanager.actual_price_of_item" #actual_price_of_item="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && actual_price_of_item.invalid }" required>
                </div> -->
                <div class="form-group col-md-6">
                    <label>From Date </label>
                    <input class="form-control" type="date" name="from_date" [(ngModel)]="modelBuydownmanager.from_date" #from_date="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && from_date.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>To Date </label>
                    <input class="form-control" type="date" name="to_date" [(ngModel)]="modelBuydownmanager.to_date" #to_date="ngModel"
                    [ngClass]="{ 'is-invalid': addbuydownmanagers.submitted && to_date.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Description </label>
                    <input class="form-control" type="text" name="description" [(ngModel)]="modelBuydownmanager.description" >
                </div>
                <div *ngIf="itemrowfound" class="col-md-12 mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">UPC Code</th>
                                <th scope="col">Description</th>
                                <th scope="col">Unit/Cass</th>
                                <th scope="col">Case Cost </th>
                                <th scope="col">Unit Retail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>{{itemrow.upc_code}}</td>
                                <td>{{itemrow.item_description}}</td>
                                <td>{{itemrow.unit_or_case}}</td>
                                <td>{{itemrow.case_cost}}</td>
                                <td>{{itemrow.unit_retail}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="grupitemsfound" class="col-md-12 mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">UPC Code</th>
                                <th scope="col">Description</th>
                                <th scope="col">Unit/Cass</th>
                                <th scope="col">Case Cost </th>
                                <th scope="col">Unit Retail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let irow of grupitems;; let prgi=index">
                                <td>{{prgi + 1}}</td>
                                <td>{{irow.upc_code}}</td>
                                <td>{{irow.item_description}}</td>
                                <td>{{irow.unit_or_case}}</td>
                                <td>{{irow.case_cost}}</td>
                                <td>{{irow.unit_retail}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-secondary mr-1" >Save</button>
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #addmanufacturer let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">Add Manufacturer</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
            (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addstoremanufacturer="ngForm"
            (ngSubmit)="savemanufacturer(addstoremanufacturer)">
            <div class="form-row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form">
                        <label>Name </label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="modelManufacturer.name"
                            #name="ngModel" [ngClass]="{ 'is-invalid': addstoremanufacturer.submitted && name.invalid }"
                            required>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form">
                        <label>Account Number</label>
                        <input type="text" class="form-control" name="account_numner"
                            [(ngModel)]="modelManufacturer.account_numner" #account_numner="ngModel"
                            [ngClass]="{ 'is-invalid': addstoremanufacturer.submitted && account_numner.invalid }"
                            required>
                    </div>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-secondary mr-1">Save</button>
                    <button class="btn btn-primary mr-1" id="closemodal2" type="button" data-dismiss="modal"
                        (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>