import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import {  StoreUserManagementRoutingModule } from './store-user-management-routing.module';
import { StoreUserManagementComponent } from './store-user-management.component';
import { FormsModule } from '@angular/forms';
import { AddStoreUserComponent } from './modal/addstoreuser/addstoreuser.component';

@NgModule({
  declarations: [
    StoreUserManagementComponent,
    AddStoreUserComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    StoreUserManagementRoutingModule,
    NgxDatatableModule
  ]
})
export class StoreUserManagementModule { }
