<ng-template #addvendor let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Vendor</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addvendor="ngForm" (ngSubmit)="savevendor(addvendor)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Vendor Name</label>
                    <input class="form-control" type="text" name="vendor_name" [(ngModel)]="modelVendor.vendor_name" #vendor_name="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && vendor_name.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Vendor code</label>
                    <input class="form-control" type="text" name="vendor_code" [(ngModel)]="modelVendor.vendor_code"  #vendor_code="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && vendor_code.invalid }" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Central price book vendor mapping</label>
                    <select class="form-control form-select digits" name="central_price_book_vendor_mapping" [(ngModel)]="modelVendor.central_price_book_vendor_mapping"  required>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Allow cash purchases</label>
                    <select class="form-control form-select digits" name="allow_cash_purchases" [(ngModel)]="modelVendor.allow_cash_purchases"  required>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div> -->
                <div class="form-group col-md-6">
                    <label>Contact Name</label>
                    <input class="form-control" type="text" name="contact_name" [(ngModel)]="modelVendor.contact_name" #contact_name="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && contact_name.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Contact No</label>
                    <input class="form-control" type="text" name="vendor_contact_no" [(ngModel)]="modelVendor.vendor_contact_no" #vendor_contact_no="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && vendor_contact_no.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Email</label>
                    <input class="form-control" type="text" name="email" [(ngModel)]="modelVendor.email" #email="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && email.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Fax</label>
                    <input class="form-control" type="text" name="fax" [(ngModel)]="modelVendor.fax" #fax="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && fax.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Address 1</label>
                    <input class="form-control" type="text" name="vendor_address" [(ngModel)]="modelVendor.vendor_address" #vendor_address="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && vendor_address.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Address 2</label>
                    <input class="form-control" type="text" name="vendor_address2" [(ngModel)]="modelVendor.vendor_address2" #vendor_address2="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && vendor_address2.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>State</label>
                    <input class="form-control" type="text" name="state" [(ngModel)]="modelVendor.state" #state="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && state.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>City</label>
                    <input class="form-control" type="text" name="city" [(ngModel)]="modelVendor.city" #city="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && city.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Zipcode</label>
                    <input class="form-control" type="text" name="zipcode" [(ngModel)]="modelVendor.zipcode" #zipcode="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && zipcode.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Account Number</label>
                    <input class="form-control" type="text" name="account_number" [(ngModel)]="modelVendor.account_number" #account_number="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && account_number.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Payment Method</label>
                    <input class="form-control" type="text" name="payment_method" [(ngModel)]="modelVendor.payment_method" #payment_method="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && payment_method.invalid }" required>
                </div>
                
                <div class="form-group col-md-6">
                    <label>Terms</label>
                    <select class="form-control form-select digits" name="terms" [(ngModel)]="modelVendor.terms" #terms="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && terms.invalid }"  required>
                        <option value="">Select</option>
                        <option value="7">7 days</option>
                        <option value="15">15 days</option>
                        <option value="30">30 days</option>
                        <option value="60">60 days</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>POS ID</label>
                    <input class="form-control" type="text" name="pos_id" [(ngModel)]="modelVendor.pos_id" #pos_id="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && pos_id.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Department</label>
                    <select class="form-control form-select digits" name="department" [(ngModel)]="modelVendor.department" #department="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && department.invalid }" required>
                    <option value="">Select</option>
                        <option *ngFor="let department of modelDepartmentList" [value]="department._id">
                            {{department.department_name}}
                          </option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Default margin</label>
                    <input class="form-control" type="text" name="default_margin" [(ngModel)]="modelVendor.default_margin" #default_margin="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && default_margin.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Default bank account</label>
                    <select class="form-control form-select digits" name="default_bank_account" [(ngModel)]="modelVendor.default_bank_account" #default_bank_account="ngModel"
                    [ngClass]="{ 'is-invalid': addvendor.submitted && default_bank_account.invalid }" required>
                    <option value="">Select</option>
                    <option *ngFor="let bankacct of storeBankaccountList" [value]="bankacct._id">
                        
                        {{bankacct.store_bank_account_name}} ({{bankacct.store_bank_branch}})
                      </option>
                    </select>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Retailing type</label>
                    <select class="form-control form-select digits" name="retailing_type" [(ngModel)]="modelVendor.retailing_type"  required>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Electronic file format</label>
                    <select class="form-control form-select digits" name="electronic_file_format" [(ngModel)]="modelVendor.electronic_file_format"  required>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                
                <div class="form-group col-md-6">
                    <label>Item Name</label>
                    <input class="form-control" type="text" name="vendor_item_name" [(ngModel)]="modelVendor.vendor_item_name" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Item Scan Code</label>
                    <input class="form-control" type="text" name="vendor_item_scan_code" [(ngModel)]="modelVendor.vendor_item_scan_code" required>
                </div> -->
                
                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-1" type="checkbox"
                            name="is_employee" class="form-control"
                            [(ngModel)]="modelVendor.is_employee" >
                        <label class="form-check-label" for="checkbox-primary-1">Is Employee</label>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-2" type="checkbox"
                            name="is_expence" class="form-control"
                            [(ngModel)]="modelVendor.is_expence" >
                        <label class="form-check-label" for="checkbox-primary-2">Is Expence</label>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-3" type="checkbox"
                            name="is_customer" class="form-control"
                            [(ngModel)]="modelVendor.is_customer" >
                        <label class="form-check-label" for="checkbox-primary-3">Is Customer</label>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-4" type="checkbox"
                            name="is_misc" class="form-control"
                            [(ngModel)]="modelVendor.is_misc" >
                        <label class="form-check-label" for="checkbox-primary-4">Is Misc</label>
                    </div>
                </div>
                <!-- <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-5" type="checkbox"
                            name="is_mpos" class="form-control"
                            [(ngModel)]="modelVendor.is_mpos" >
                        <label class="form-check-label" for="checkbox-primary-5">Is mPOS</label>
                    </div>
                </div> -->
                <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                    <div class="checkbox checkbox-primary p-0 leftmargin">
                        <input class="form-check-input" id="checkbox-primary-6" type="checkbox"
                            name="is_vendor" class="form-control"
                            [(ngModel)]="modelVendor.is_vendor" >
                        <label class="form-check-label" for="checkbox-primary-6">Is Vendor</label>
                    </div>
                </div>
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>