import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { StoreUser } from 'src/app/shared/classes/storeuser';

@Component({
  selector: 'app-addstoreuser',
  templateUrl: './addstoreuser.component.html',
  styleUrls: ['./addstoreuser.component.scss']
})
export class AddStoreUserComponent implements OnInit {
  @ViewChild("addstoreuser", { static: false }) addstoreuser: TemplateRef<any>;
  @Output() sendaddedstoreuser: EventEmitter<string> = new EventEmitter<string>();
  public closeResult: string;
  public modalOpen: boolean = false;
  selected_id: string;
  public modelTitle: string;
  public itemId: string;
  modelUser = new StoreUser();
  finaldata = [];
  selectedroleidsArr = [];

  events: Event[] = [];
  selectedItems: any;
  menupages = [];
  checkedRoleIDs = [];
  detailuser = [];
  modelRoleList = [];
  seluser_id: any;
  storeList = [];

  newpassword = ""

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
  ) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    //this.storeService.getStoreRoles(store_id).then((data)=>{this.modelRoleList=data;});
  }

  isChecked(eventId) {
    return this.selectedroleidsArr.indexOf(eventId) > -1 ? true : null
  }

  openModal(row_id?: null) {
    this.newpassword = ""
    this.storeService.getAllStores()
      .then((response) => {
        this.storeList = response;
      });

    let store_id = JSON.parse(localStorage.getItem('store_id'));

    this.finaldata = [];
    this.selectedroleidsArr = [];

    this.selected_id = row_id;

    if (this.selected_id != null) {
      this.storeService.getStoreUserRoles(this.selected_id).then((resdata) => {
        resdata.forEach((value, key) => {
          console.log("value.role_id", value.role_id);

          this.selectedroleidsArr.push(value.role_id);
        });
      });
      this.storeService.getStoreUserById(this.selected_id).then((data) => {
        this.modelUser = data;
        // this.selectedroleidsArr = data['role_id'];
        // let roleids = data['role_id'];
        // this.selectedroleidsArr = roleids.split(",");
      });
      this.modelTitle = "Edit User";
      this.itemId = this.selected_id;
      this.checkedRoleIDs = this.selectedroleidsArr;
    } else {
      this.modelUser = new StoreUser();
      //this.modelUser['userdetail']['fullname'] = '';
      //this.modelUser['userdetail']['email'] = '';
      this.modelTitle = "Add User";
      this.itemId = null;
    }

    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addstoreuser, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  getstoreroles(storeid) {
    if (storeid != '' || storeid != null) {
      this.checkedRoleIDs = [];
      this.storeService.getStoreRoles(storeid).then((data) => { this.modelRoleList = data; });
    } else {
      this.modelRoleList = [];
      this.checkedRoleIDs = [];
    }

  }
  handleStatusCode(stcode) {
    if (stcode >= 200 && stcode <= 299) {
      return 'ok';
    } else {
      return 'errorcode';
    }
  }

  saveuser(form) {
    //user_id = '';
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    if (form.valid) {
      let frmvalues = {
        "firstName": form.value.firstName,
        "lastName": form.value.lastName,
        "email": form.value.email,
        "username": form.value.email,
        "password": this.newpassword,
        "store_id": form.value.store_id,
        "companyname": form.value.companyname,
        "phone_number": form.value.phone_number
      }
      this.storeService.saveStoreUser(frmvalues, this.itemId)
        .then((response) => {
          if (response.status) {
            let responseStstus = this.handleStatusCode(response.status);
            if (responseStstus == 'errorcode') {
              Swal.fire('User!', response.message, 'error');
            }
            return false;
          }

          if (this.itemId != null) {
            this.seluser_id = this.itemId;
            this.storeService.deleteStoreuserroles(form.value.store_id, this.seluser_id).then((response) => {

            });
          } else {
            this.seluser_id = response._id;
          }

          this.checkedRoleIDs.map((value1, key1) => {
            this.detailuser[key1] = { "role_id": value1, "user_id": this.seluser_id, "store_id": form.value.store_id };
          });
          // console.log(this.detailuser);
          // return false;


          this.storeService.saveStoreUSerRole(this.detailuser, null).then((perresponse) => {
          });

          Swal.fire('User!', 'User Save Successfully.', 'success');
          this.sendaddedstoreuser.emit(store_id);
          this.modalService.dismissAll();
          this.modelRoleList = [];
        }).catch((error) => {
          console.log("error", error);
        });


    }
  }
  fetchPerCheckedIDs($event) {
  }
  changeSelection(evt) {

    if (evt.target.checked) {
      this.checkedRoleIDs.push(evt.target.value);
    }
    if (!evt.target.checked) {
      var xchkid = this.checkedRoleIDs.indexOf(evt.target.value);
      this.checkedRoleIDs.splice(xchkid, 1);
    }
    console.log(this.checkedRoleIDs);

  }
}
