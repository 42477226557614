import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddVendorInvoiceItemRoutingModule } from './add-vendor-invoice-item-routing.module';
import { AddVendorInvoiceItemComponent } from './add-vendor-invoice-item.component';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {SafePipe} from './pipe/safe.pipe'

@NgModule({
  declarations: [
    AddVendorInvoiceItemComponent,
    SafePipe
  ],
  imports: [
    FormsModule,
    CommonModule,
    AddVendorInvoiceItemRoutingModule,
    NgxDatatableModule,
    FormsModule
  ]
})
export class AddVendorInvoiceItemModule { }
