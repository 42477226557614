<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 xl-100 dashboard-sec box-col-12">
            <div class="add-heading">
                <h1>Register mappings</h1>
            </div>
            </div>
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <div class="cardhead mt-3">

                    <ul id="info-tab" role="tablist" class="nav nav-tabs border-tab nav-primary">
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-1" class="nav-link "
                                [ngClass]="{'active show': openTab == 'depsetting'}" (click)="tabbed('depsetting')">Departments</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-2" class="nav-link"
                                [ngClass]="{'active show': openTab == 'gasgradestting'}" (click)="tabbed('gasgradestting')">Gas Grades</a></li>
                                <!-- <li class="nav-item"><a href="javascript:void(0)" id="info-tab-2" class="nav-link"
                                    [ngClass]="{'active show': openTab == 'gencustacnt'}" (click)="tabbed('gencustacnt')">General/Customer account</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)" id="info-tab-2" class="nav-link"
                                        [ngClass]="{'active show': openTab == 'incomeexpnc'}" (click)="tabbed('incomeexpnc')">Income/Expense</a></li> -->
                    </ul>
                    <div id="info-tabContent" class="tab-content">

                        <div id="info-1" role="tabpanel" aria-labelledby="info-tab-1" class="tab-pane fade"
                            [ngClass]="{'show active': openTab == 'depsetting'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <!-- <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"
                                                        (click)="deptregistermapping.openModal();$event.preventDefault()">Add
                                                        new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div> -->
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="departmentList" [loadingIndicator]="loadingIndicator"
                                                [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                [reorderable]="reorderable">
                                                    <ngx-datatable-column name="Department Name" prop="department_name"></ngx-datatable-column>
                                                    <ngx-datatable-column name="Reg ID" prop="regmapping.register_id"></ngx-datatable-column>
                                                    <ngx-datatable-column name="Display Name" prop="regmapping.display_name"></ngx-datatable-column>

                                                    <ngx-datatable-column name="Action">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            <button class="edit-btn ml-2 mr-2" name="Edit"
                                                            (click)="deptregistermapping.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-2" role="tabpanel" aria-labelledby="info-tab-2" class="tab-pane fade"
                            [ngClass]="{'show active': openTab == 'gasgradestting'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <!-- <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"
                                                        (click)="fuelgraderegistermapping.openModal();$event.preventDefault()">Add
                                                        new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div> -->
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="fuelGradeList" [loadingIndicator]="loadingIndicator"
                                                [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                                [reorderable]="reorderable">
                                                    <ngx-datatable-column name="Fuel Grade Name" prop="store_fuel_grade_display_name"></ngx-datatable-column>
                                                    <ngx-datatable-column name="Reg ID" prop="regmapping.register_id"></ngx-datatable-column>
                                                    <ngx-datatable-column name="Display Name" prop="regmapping.display_name"></ngx-datatable-column>
                                                    <ngx-datatable-column name="Action">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            <button class="edit-btn ml-2 mr-2" name="Edit"
                                                            (click)="fuelgraderegistermapping.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-departmentmapping (sendaddeddepmapping) = 'reloadDepTable($event)' #departmentmapping></app-departmentmapping>
<app-gasmapping (sendaddedgasmapping) = 'reloadFuelTable($event)' #gasmappping></app-gasmapping> -->
<app-add-departmentregistermapping (sendaddeddepmapping) = 'reloadDepTable($event)' #deptregistermapping></app-add-departmentregistermapping>
<app-add-fuelgraderegistermapping  (sendaddedgasmapping) = 'reloadFuelTable($event)' #fuelgraderegistermapping></app-add-fuelgraderegistermapping>