import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public show: boolean = false;
  public showRestPasswordForm = true;
  public showCreatePasswordForm = false;
  isPasswordNotMatch = false

  email: string = "test@gmail.com"
  otp: string = ""
  password: string = ""

  constructor(public authService: AuthService, public router: Router) {
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  getEmailForRestPassword(form: any) {
    this.email = form.value.email

    if (form.valid) {
      this.authService.ForgotStoreUserPassword({ "email": this.email }).then((response) => {
        if (_.isEmpty(response)) {
          Swal.fire('Forgot Password', 'Please Enter valid Email Address', 'error');
        } else {
          this.showCreatePasswordForm = true
          this.showRestPasswordForm = false
        }

      }).catch((err) => {
        console.log(err)
      })
    }
  }

  resendOtp(e) {
    e.preventDefault()
    this.showRestPasswordForm = true;
    this.showCreatePasswordForm = false
  }
  async changePassword(form: any) {
    let password = form.value.password
    let confirmPassword = form.value.confirmPassword

    if (password !== confirmPassword) {
      this.isPasswordNotMatch = true
    } else {
      this.otp = form.value.otp
      this.password = password
      this.isPasswordNotMatch = false
      console.log("Password: " + password)
      await this.authService.resetStoreUserPassword({ "newPassword": this.password, "otp": this.otp })
        .then((response) => {
          console.log("rest password response: ==" + JSON.stringify(response))
          if (_.isEmpty(response)) {
            Swal.fire('Forgot Password', 'Password reset failed!', 'error');
          } else {
            this.showCreatePasswordForm = false;
            this.showRestPasswordForm = true

            this.email = ""
            this.otp = ""
            this.password = ""

            Swal.fire('Forgot Password', 'Password successfully reset!', 'success');
            this.router.navigate([''])
          }

        })
        .catch((err) => {
        })
    }

  }
}
