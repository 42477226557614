import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ReactiveFormsModule  } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  
  public show: boolean = false;
  public registerForm: FormGroup;
  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder) {
      this.registerForm = this.fb.group({
        firstname: ['test', Validators.required],
        lastname: ['test', Validators.required],
        companyname: ['adddd', Validators.required],
        email: ['test1@gmail.com', [Validators.required, Validators.email]],
        password: ['test123', Validators.required]
      });
  }

  ngOnInit() {
    // console.log("login compoent");
  }

  

  // Simple Login
  async register() {
    var user = await this.authService.SignUp(this.registerForm.value['firstname'], 
    this.registerForm.value['lastname'], this.registerForm.value['companyname'], 
    this.registerForm.value['email'], this.registerForm.value['password']).then((response) => {
      Swal.fire('Thank You!', 'User Add Successfully. Please check your email for account activation', 'success');
    });;
  }


}

