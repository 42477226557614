import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class StoreTank {
  public store_tank_number: string;
  public store_fuel_grade: string;
  public store_tank_recorder_level: string;
  public store_tank_ullage: string;
  public store_tank_type: string;
  public store_tank_description: string;
  public store_tank_capacity: string;
  public store_cuurent_tank_volume: string;
  public store_tank_details_notes: string;
}


@Component({
  selector: 'app-addtank',
  templateUrl: './addtank.component.html',
  styleUrls: ['./addtank.component.scss']
})
export class AddtankComponent implements OnInit {

  @ViewChild("addtank", { static: false }) addtank: TemplateRef<any>;
  @Output() sendaddtank: EventEmitter<string> = new EventEmitter<string>();
 
  public closeResult: string;
  public modalOpen: boolean = false;
  modelStoreTank = new StoreTank();
  selected_id : string;
  public modelTitle: string;
  public tankId: string; 

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getTankById(this.selected_id).then((data)=>{this.modelStoreTank=data;});
        this.modelTitle = "Edit";
        this.tankId = this.selected_id;
    }else{
      this.modelStoreTank = new StoreTank();
      this.modelTitle = "Add";
      this.tankId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addtank, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveTank(form) {
  let store_id = JSON.parse(localStorage.getItem('store_id'));
   if(form.valid) { 
    this.storeService.saveTank(form.value, this.tankId)
          .then((response) => {
             console.log(response);
             Swal.fire('Tank!', 'Tank Save Successfully.', 'success');
             this.sendaddtank.emit(store_id);
             this.modalService.dismissAll();
             });
    }
  }


}
