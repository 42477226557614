<div class="container-fluid pdlr">
    <div class="row">
        <div class="col-xl-12 xl-100 dashboard-sec box-col-12">
            <div class=" row m-0 p-tb">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="card">
                        <div class="card-header calender-btn" style="padding-bottom: 10px   ;">
                            <h5>User
                                <button class="btn btn-primary updated-btn pull-right"  (click)="adduser.openModal();$event.preventDefault()">
                                Add New User
                            </button>
                            </h5>
                            <div class="card earning-card p-15" style="margin-bottom: 0;">
                                <form #serachuserform="ngForm">
                                    <div class="row">
                                        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                            <div class="mb-2 theme-form">
                                                <label>Search By Name / Email </label>
                                                <input type="text" class="form-control" placeholder="test@mail.com" name="searchvalue" ngModel>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                            <div class="mb-2 theme-form">
                                                <button class="btn btn-primary" (click)="searchUser(serachuserform)" style="margin-top: 32px;">Search</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card-body custom-datatable noscroll">
                            <ngx-datatable class="bootstrap"  [rows]="modelUserList" [loadingIndicator]="loadingIndicator"
                                [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                [reorderable]="reorderable" [limit]="10">
                                <ngx-datatable-column name="Name" prop="fullname"></ngx-datatable-column> 
                                <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
                                <ngx-datatable-column name="Action" widht="600" >    
                                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                        <div class="trlistBtn">
                                            <button class="edit-btn" (click)="adduser.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                            <button class="delete-btn" (click)="removeuser(row._id)"><i class="fa fa-trash"></i></button>
                                        </div>   
                                    </ng-template>    
                                </ngx-datatable-column> 
                            </ngx-datatable>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<app-adduser (sendaddeduser) = 'reloadUserTable($event)'  #adduser ></app-adduser>
