<ng-template #addstoreuser let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addstoreuser="ngForm" (ngSubmit)="saveuser(addstoreuser)">
           
            <div class="row">
                <div class="col-xl-12">
                    <!-- <div class="heading-title">
                        <h2>Department details</h2>
                    </div> -->
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>First Name </label>
                                <input type="text" class="form-control" name="firstName"  [(ngModel)]="modelUser.firstName"  #firstName="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && firstName.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Last Name </label>
                                <input type="text" class="form-control" name="lastName"  [(ngModel)]="modelUser.lastName"  #lastName="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && lastName.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Company Name </label>
                                <input type="text" class="form-control" name="companyname"  [(ngModel)]="modelUser.companyname"  #companyname="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && companyname.invalid }"  >
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Email </label>
                                <input type="text" class="form-control" name="email"  [(ngModel)]="modelUser.email"  #email="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && email.invalid }"  required>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Password </label>
                                <input type="text" class="form-control" name="newpassword" [(ngModel)]="newpassword" required>
                            </div>
                            
                        </div>
        
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Phone Number </label>
                                <input type="text" class="form-control" name="phone_number"  [(ngModel)]="modelUser.phone_number"  #phone_number="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && phone_number.invalid }"  required>
                            </div>
                            
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Select store </label>
                                <select class="form-control form-select digits"  id="exampleFormControlSelect9" name="store_id"  [(ngModel)]="modelUser.store_id"  #store_id="ngModel"
                                [ngClass]="{ 'is-invalid': addstoreuser.submitted && store_id.invalid }" (change)="getstoreroles($event.target.value)" required>
                                    <option value="">Select</option>    
                                    <option *ngFor="let st of storeList" [value]="st._id">
                                        {{st.store_full_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div  class="col-xl-4 col-lg-4 col-md-4 col-4">
                            <div class="mb-3 theme-form col-md-12">
                                <ul class="role_list">
                                    <li>
                                        <div *ngFor="let permrow of modelRoleList; let i = index" class="checkbox checkbox-primary">
                                            <input class="form-check-input"  id="chkper_{{permrow._id}}"  type="checkbox"  value="{{permrow._id}}" (change)="changeSelection($event)" [attr.checked]="isChecked(permrow._id)">
                                            <label class="form-check-label" for="chkper_{{permrow._id}}">{{permrow.role_name}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
               </div>
            </div>

            <div class="form-row">
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1">Save</button>
                    <button class="btn btn-secondary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>