import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/shared/services/nav.service';
import Swal from 'sweetalert2';

export class ComboDeal {
    //public store_id: string;
    public store_location: string;
    public name: string;
    public description: string;
    public start_date: string;
    public end_date: string;
    public quantity: string;
}

@Component({
  selector: 'app-addcombodeal',
  templateUrl: './addcombodeal.component.html',
  styleUrls: ['./addcombodeal.component.scss']
})
export class AddcombodealComponent implements OnInit {
  @ViewChild("addcombodeal", { static: false }) addcombodeal: TemplateRef<any>;
  @Output() sendaddedcombodeal:EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelComboDeal = new ComboDeal();
  selected_id : string;
  public modelTitle: string;
  public itemId: string; 
  
  storeLocationList = [];
  storeItemList = [];

  events: Event[] = [];

  
  selectedItems: any;
    searchstoreitems = [];
    combo_itm_ids = [];
    combodeal_id = '';
    itms_ary = [];
    removedItemIds = [];


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public navServices: NavService,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.storeLocationList = await this.storeService.getStoreLocation(data['_id']);
        this.searchstoreitems = await this.storeService.getStoreItem(data['_id']);
      }
    })
  }
  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    
    this.storeService.getStoreLocation(store_id)
          .then((response) => {
             this.storeLocationList = response;
          });
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getComboDealById(this.selected_id).then((data)=>{this.modelComboDeal=data;});
        this.modelTitle = "Edit Combo Deal";
        this.itemId = this.selected_id;

        this.storeService.getComboDealitemlist(this.itemId).then((itmdata)=>{
          this.combo_itm_ids = [];
          itmdata.forEach((value, key) => {
            this.combo_itm_ids.push({
              id: value.item_id,
              label: value.combodealItems.upc_code+' ('+ value.combodealItems.item_description+')'
            });
          });
        });
        

    }else{
      this.modelComboDeal = new ComboDeal();
      this.modelTitle = "Add Combo Deal";
      this.itemId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addcombodeal, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  savecombodeal(form) {
    //return false;
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    console.log(form.valid);
    
   if(form.valid) {
    this.storeService.saveCombodeal(form.value, this.itemId)
          .then((response) => {
            if(this.itemId !=null){
              this.combodeal_id = this.itemId;
            }else{
              this.combodeal_id = response._id;
            }
            let item_idval = '';
            form.value.item_list_name.map((value1, key1) => {
              if(typeof value1 === 'object' && value1 !== null && !Array.isArray(value1)) {
                
                if((this.removedItemIds.indexOf(value1.id) != -1) == true){
                }else{
                  item_idval = value1.id;
                }
             } else {
              if((this.removedItemIds.indexOf(value1) != -1) == true){
              }else{
                item_idval = value1;
              }
             }
              this.itms_ary[key1] = {"store_id":store_id,"combodeal_id":this.combodeal_id,"item_id":item_idval};
            });

            
            console.log("itms_ary--",this.itms_ary);
            
            this.storeService.saveComboDealItems(this.combodeal_id,this.itms_ary).then((perresponse) => {
            });
              this.removedItemIds = [];
             console.log(response);
             Swal.fire('Combo Deal!', 'Combo Deal Save Successfully.', 'success');
             this.sendaddedcombodeal.emit(store_id);
             this.modalService.dismissAll();
             this.searchstoreitems = [];
             
          });
    }
  }

  onSearch($event) {
    if($event.term !=''){
      this.storeService.getStoreItemListOnSearch($event.term).then((data)=>{
        console.log(data);
        this.searchstoreitems = data;
      });
    }else{
      this.searchstoreitems = [];
    }
    
  }
  onRemove(event: any) {
    this.removedItemIds.push(event.value.id)
  }

}
