import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { CONSTANTS } from 'src/app/util/constants';
import { AddDepartmentregistermappingComponent } from './modal/add-departmentregistermapping/add-departmentregistermapping.component';
import { AddFuelgraderegistermappingComponent } from './modal/add-fuelgraderegistermapping/add-fuelgraderegistermapping.component';



@Component({
  selector: 'app-register-mapping',
  templateUrl: './register-mapping.component.html',
  styleUrls: ['./register-mapping.component.scss']
})
export class RegisterMappingComponent implements OnInit {
  
  @ViewChild("adddepartmentregistermapping") Departmentregistermappping: AddDepartmentregistermappingComponent;
  @ViewChild("addfuelgraderegistermapping") FuelGradeRegisterMapping: AddFuelgraderegistermappingComponent;



  public openTab: string = "depsetting";
  modelMixmatchMaintenanceList = [];
  modelCombodealList = [];
  departmentList = []
  fuelGradeList =[]
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        
        
        this.storeService.getStoreDepartment(data['_id']).then((data) => {
           this.departmentList = data; 
          });

        this.storeService.getStoreFuelGrade(data['_id']).then((data)=>{
          this.fuelGradeList = data
          console.log("Fuel Grade: "+JSON.stringify(data[0]))
        })
        
      }
    })
  }

  async reloadDepTable(event){
    this.departmentList = await this.storeService.getStoreDepartment(event);
  }
  async reloadFuelTable(event){
    this.fuelGradeList = await this.storeService.getStoreFuelGrade(event);
  }

  
  
  async tabbed(val: any) {
    this.openTab = val;
    var store_id = JSON.parse(localStorage.getItem('store_id'));

    switch (val) {
      case 'depsetting':
        this.storeService.getStoreDepartment(store_id).then((data) => {
          this.departmentList = data; 
         });
        break;
      case 'gasgradestting':
        this.storeService.getStoreFuelGrade(store_id).then((data)=>{
          this.fuelGradeList = data
        })
        break;
        default:
    }
  }

}
