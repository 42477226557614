
export class StoreInfo {
    public store_full_name!: string;
    public store_name!: string;
    public store_contact_no!: string;
    public store_address!: string;
    public store_city!: string;
    public store_country!: string;
    public store_state!: string;
    public store_zip!: string;
    public store_email!: string;
    public store_phone!: string;
    public use_same_contact_notification!: string;
    public additional_store_phone!: string;
    public additional_store_email!: string;
    public store_pos_type!: string;
    public store_tire!: string;
    public store_service_level!: string;
    public store_price_config!: string;
    public store_select_brand!: string;
    public store_tank_monitoring_system!: string;
    public store_pre_auth_key!: string;
    public timezone!: string;
    public store_logo!: string;
    public rjr_account_no!: string;
    public altria_account_no!: string;
}

export class StoreSalesTax {
    public store_sales_tax_name: string;
    public store_sales_pos_tax: string;
    public store_sales_city_tax: string;
    public store_sales_country_tax: string;
    public store_sales_state_tax: string;
}

export class StoreFuelTax {
    public store_fuel_tax_description: string;
    public store_fuel_tax: string;
    public store_fuel_tax_on_tax: string;
    public store_fuel_tax_rate: string;
}


export class StoreSalesRestriction {
    public store_pos_sales_restriction_name: string;
    public store_sales_restriction_min_cust_age: string;
    public store_sales_restriction_min_clerk_age: string;
    public store_sales_restriction_transaction_limit: string;
    public store_sales_restrict: string;
    public store_pos_sales_prohibit_discount: string;
}

export class StoreFuelGrade {
    public store_fuel_grade: string;
    public store_fuel_department: string;
    public store_fuel_tax: string;
    public store_fuel_grade_id: string;
    public store_fuel_grade_display_name: string;
    public tank_size: string;
    public prepaid_sales_tax: string;
    public tax_rate: string;
    public ust_fees: string;
    public register_mapping: string;
    public is_blend: boolean = false;
    public blend_ratio1:string;
    public blend_ratio2:string;
}

export class StoreTank {
    public store_tank_number: string;
    public store_fuel_grade: string;
    public store_tank_recorder_level: string;
    public store_tank_ullage: string;
    public store_tank_type: string;
    public store_tank_description: string;
    public store_tank_capacity: string;
    public store_cuurent_tank_volume: string;
    public store_tank_details_notes: string;
    public store_tank_height:string
}

export class LotterySetting {
    public store_id: string;
    public store_loteery_setting: boolean = false;
    public store_is_ticket_number_start_with_zero: boolean = false;
    public store_sell_ticket_in_reverse_order: boolean = false;
    public store_lottery_depratment: string;
}

export class BankAccount {
    public store_id: string;
    public store_bank_account_name: string;
    public store_bank_account_type: string;
    public store_bank_address: string;
    public store_bank_zip_code: string;
    public store_bank_branch: string;
    public store_bank_routing_number: string;
    public store_bank_signature: string;
    public store_bank_logo: string;
}

export class SettingOff {
    public store_id: string;
    public store_is_limit_timeoff_request: boolean = false;
    public store_limit_timeoff_request: string;
    public store_do_not_allow_time_off_requests: boolean = false;
    public store_do_not_allow_time_off_requests_limit: string;
}

export class StoreServices {
    public store_id: string;
    public is_store_atm: boolean = false;
    public store_atm_transaction_fee: string;
    public store_atm_credit_bank_account: string;
    public is_bill_pay_service: boolean = false;
    public store_bill_pay_service_credit_bank_account: string;
    public check_cashing_services: boolean = false;
    public is_store_accept_coupons: boolean = false;
    public is_money_transfer: boolean = false;
    public store_accept_mobile_coupons: boolean = false;
    public mobile_coupons_bank_account: string;
    public store_accept_food_stamps: boolean = false;
    public food_stamps_bank_account: string;
    public store_sales_fish_n_game_licence: boolean = false;
    public fish_n_game_licence_fee: string;
    public fish_n_game_licence_bank_account: string;
    public store_has_keno_machine: boolean = false;
    public lottery_commision: string;
    public lottery_commision_licence_bank_account: string;
    public sell_money_order: boolean = false;
    public money_order_company_name: string;
    public money_order_fee: string;
    public money_order_buying: string;
    public money_order_debit_bank_account: string
}

export class ScheduleSetting {

    public store_id: string;
    public monday_first_shift: boolean = false;
    public monday_first_shift_in: string;
    public monday_first_shift_out: string;
    public monday_second_shift: boolean = false;
    public monday_second_shift_in: string;
    public monday_second_shift_out: string;
    public monday_third_shift: boolean = false;
    public monday_third_shift_in: string;
    public monday_third_shift_out: string;
    public tuesday_first_shift: boolean = false;
    public tuesday_first_shift_in: string;
    public tuesday_first_shift_out: string;
    public tuesday_second_shift: boolean = false;
    public tuesday_second_shift_in: string;
    public tuesday_second_shift_out: string;
    public tuesday_third_shift: boolean = false;
    public tuesday_third_shift_in: string;
    public tuesday_third_shift_out: string;
    public wednesday_first_shift: boolean = false;
    public wednesday_first_shift_in: string;
    public wednesday_first_shift_out: string;
    public wednesday_second_shift: boolean = false;
    public wednesday_second_shift_in: string;
    public wednesday_second_shift_out: string;
    public wednesday_third_shift: boolean = false;
    public wednesday_third_shift_in: string;
    public wednesday_third_shift_out: string;
    public thusrday_first_shift: boolean = false;
    public thusrday_first_shift_in: string;
    public thusrday_first_shift_out: string;
    public thusrday_second_shift: boolean = false;
    public thusrday_second_shift_in: string;
    public thusrday_second_shift_out: string;
    public thusrday_third_shift: boolean = false;
    public thusrday_third_shift_in: string;
    public thusrday_third_shift_out: string;
    public friday_first_shift: boolean = false;
    public friday_first_shift_in: string;
    public friday_first_shift_out: string;
    public friday_second_shift: boolean = false;
    public friday_second_shift_in: string;
    public friday_second_shift_out: string;
    public friday_third_shift: boolean = false;
    public friday_third_shift_in: string;
    public friday_third_shift_out: string;
    public saturday_first_shift: boolean = false;
    public saturday_first_shift_in: string;
    public saturday_first_shift_out: string;
    public saturday_second_shift: boolean = false;
    public saturday_second_shift_in: string;
    public saturday_second_shift_out: string;
    public saturday_third_shift: boolean = false;
    public saturday_third_shift_in: string;
    public saturday_third_shift_out: string;
    public sunday_first_shift: boolean = false;
    public sunday_first_shift_in: string;
    public sunday_first_shift_out: string;
    public sunday_second_shift: boolean = false;
    public sunday_second_shift_in: string;
    public sunday_second_shift_out: string;
    public sunday_third_shift: boolean = false;
    public sunday_third_shift_in: string;
    public sunday_third_shift_out: string;
}

export class FuelSetting {
    public store_id: string;
    public jobber_commissions_gallon: string;
    public gas_fees: string;
    public add_daily_fuel_over_or_short_in_profit: boolean = false;
    public do_not_calculate_fuel_profit: boolean = false;
    public is_fuel_on_consignment: boolean = false;
}

export class Lottery {
    public store_id: string;
    public lottery_terminal_id: string;
    public lottery_user_id: string;
    public lottery_password: string;
    public lottery_bins: string;
    public no_of_slots: string;
    public starting_numbers: string;
    public lottery_auto_activate: boolean = false;
    public lottery_auto_settle: boolean = false;
    public delete_confirmed_lottery: boolean = false;
}

export class POSSetup {
    public store_id: string;
    public do_not_subtract_pos_discount: boolean = false;
    public do_not_subtract_pos_refund: boolean = false;
    public do_not_subtract_pos_promotion: boolean = false;
    public import_fuel_discount: boolean = false;
}

export class Department {

    store_id: string;
    department_type: string;
    profit_margin: string;
    profit_percentage: string;
    minimum_open_sales_amount: string;
    maximum_open_sales_amount: string;
    active_department: boolean = false;
    allow_open_sales_in_department: boolean = false;
    allow_partial_quantity_in_sales: boolean = false;
    allow_royalty_redeem_sales: boolean = false;
    allow_item_return: boolean = false;
    allow_food_stamp_sales: boolean = false;
    allow_special_discount: boolean = false;
    flexible_pricing_for_items: boolean = false;
    department_negative: boolean = false;
}

export class Item {
    store_id: string;
    upc_code: string;
    item_description: string;
    department: string;
    is_multipack: boolean = false;
    selling_unit: string;
    item_category: string;
    unit_of_measurment: string;
    price_group: string;
    size: string;
    sales_type: string;
    payee: string;
    active_item: boolean = false;
    partial_quantity_sales: boolean = false;
    item_returns: boolean = false;
    food_stamp_sales: boolean = false;
    special_discount: boolean = false;
    loyalty_redeem_sales: boolean = false;
    unit_or_case: string;
    case_cost: string;
    case_discount: string;
    unit_retail: string;
    case_rebate: string;
    cartize_price: string;
    cost_or_unit_after_discount: string;
    margin: string;
    margin_after_rebate: string;
    min_age: string;
    max_inv: string;
    min_inv: string;
    tax_rate: string;
    nacs_code: string;
    all_food_stamp: boolean = false;
    kitchen_print_option: string;
    linked_item: string;
    nacs_category: string;
    nacs_sub_category: string;
    blue_law: string;
    department_id: string;
    item_category_id: string;
    price_group_id: string;
    scan_code: string;
    carton_count:string;
    item_count_in_carton:string;
    carton_price:string;
    carton_item_price:string;

}

export class TankSetting {
    public store_id: string;
    public grade_tank_type: string;
    public blend_ratio: string;
    public is_sell_diesel: boolean = false;
    public comm_per_volume: string;
    public federal_multiplier: string;
    public federal_tolerance: string;
    public state_multiplier: string;
    public state_tolerance: string;
    public tank_report_template:string;
    public is_two_blended_grades: boolean = false;
    public gas_supplier_paid_mode;
    public store_bank_account_id;
    public gas_supplier_settle_credit_card;
    public is_having_gas;
    public cc_grade_tank_type;
    public is_gas_cashcard = false;
}

export class TankGradeSetting{
    public store_id: string;
    public store_fuel_grade_id: string;
    public tank_size: string;
    public is_grade_present: boolean = false;
    public tax_rate:string;
    public prepaid_sales_tax:string;
    public ust_fees:string;
    public register_mapping:string;
}
export class FuelDelivery{
    public store_id: string;
    public delivery_date: string;
    public fuel_delivery_detail: string;
}

export class StoreLotteryData{
    public store_id: string;
    public game_name: string;
    public roll_number: string;
    public ticket_value: string;
    public ticket_qty: string;
    public bar_code_number: string;
    public start_number: string;
    public end_number: string;
    public book_value: string;
}

export class ActiveStoreLotteryData{
    public store_id: string;
    public activate_date: string;
    public roll_number: string;
    public deal_number: string;
    public no_of_tickets: string;
    public status: string;
  
}