import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BulkUpdateRoutingModule } from './bulk-update-routing.module';
import { BulkUpdateComponent } from './bulk-update.component';
import { FormsModule } from '@angular/forms';
import { AddbulkupdateComponent } from './addbulkupdate/addbulkupdate.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    BulkUpdateComponent,
    AddbulkupdateComponent
  ],
  imports: [
    CommonModule,
    BulkUpdateRoutingModule,
    FormsModule,
    NgxDatatableModule,
    NgSelectModule
  ]
})
export class BulkUpdateComponentModule { }


