<ng-template #addcombodeal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addcombodeal="ngForm" (ngSubmit)="savecombodeal(addcombodeal)">
            <div class="form-row">
                        

                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Deal name </label>
                                <input type="text" class="form-control" name="name" [(ngModel)]="modelComboDeal.name" #name="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && name.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Description </label>
                                <input type="text" class="form-control" name="description" [(ngModel)]="modelComboDeal.description" #description="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && name.invalid }"  required>
                            </div>
                        </div>
                        
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Select Items</label>
                                <ng-select name="item_list_name" [multiple]="true" [(ngModel)]="combo_itm_ids" class="searchable_select" (search)="onSearch($event)" (remove)="onRemove($event)">
                                    <ng-option *ngFor="let storeItem1 of searchstoreitems" [value]="storeItem1._id">
                                        {{ storeItem1.upc_code }} ({{storeItem1.item_description}})
                                    </ng-option>
                                 </ng-select>
                                
                                
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Begin date </label>
                                <input type="date" class="form-control" name="start_date" [(ngModel)]="modelComboDeal.start_date" #start_date="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && start_date.invalid }" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>End date </label>
                                <input type="date" class="form-control" name="end_date" [(ngModel)]="modelComboDeal.end_date" #end_date="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && end_date.invalid }"  required>
                            </div>
                        </div>
                        
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Quantity</label>
                                <input type="text" class="form-control" name="quantity" [(ngModel)]="modelComboDeal.quantity" #quantity="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && quantity.invalid }" required>
                            </div>
                        </div> 
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Combo Price</label>
                                <input type="text" class="form-control" name="amount" [(ngModel)]="modelComboDeal.amount" #amount="ngModel"
                                [ngClass]="{ 'is-invalid': addcombodeal.submitted && amount.invalid }"  required>
                            </div>
                        </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>