import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/app/util/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();
  public projectname: {};

  constructor(public constants: CONSTANTS,) { }

  ngOnInit(): void {
    this.projectname = this.constants.GLOBAL_SETTINGS.PROJECT_NAME;
  }

}
