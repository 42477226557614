import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output,ElementRef  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class Bulkupdate {
    public store_id: string;
    public item_id:string;
    public price_group_id: string;
    public discount_given_by_manufacturer: string;
    public from_date: string;
    public to_date: string;
    public description: string;
    public selectedBy:any
  }
 

@Component({
  selector: 'app-addbulkupdate',
  templateUrl: './addbulkupdate.component.html',
  styleUrls: ['./addbulkupdate.component.scss']
})
export class AddbulkupdateComponent implements OnInit {

  // @ViewChild("addbulkupdate", { static: false }) addbulkupdate: TemplateRef<any>;
  // @Output() sendaddbulkupdate: EventEmitter<string> = new EventEmitter<string>();
 
  public closeResult: string;
  public modalOpen: boolean = false;
  modelBulkupdate = new Bulkupdate();

  selected_id : string;
  public modelTitle: string;
  public bulkupdateId: string; 
 
  itmcode = false;
  pricegrp = false;
  pricegroups = [];
  itemrow = [];
  itemrowfound = false;
  grupitemsfound = false;
  grupitems = [];
  IsmodelShow:any;
  searchstoreitems = [];
  removedItemIds = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {

  }

 
  // savemanufacturer(form) {
  //   let store_id = JSON.parse(localStorage.getItem('store_id'));
    
  //  if(form.valid) {
  //   this.storeService.saveManufacturer(form.value, null)
  //         .then((response) => {
  //            console.log(response);
  //            Swal.fire('Manufacturer!', 'Manufacturer Save Successfully.', 'success');
  //            this.manufacturers.push(response);
  //            document.getElementById("closemodal2").click();
  //         });
  //   }
  // }

  // async openModal(row_id?:null) {
  //   let store_id = JSON.parse(localStorage.getItem('store_id'));
  //   this.storeService.getManufacturers(store_id).then((data)=>{this.manufacturers=data;});
  //   this.storeService.getPriceGroup(store_id).then((data)=>{this.pricegroups=data;});
  //   this.selected_id= row_id;
  //   if(this.selected_id != null){
  //       await this.storeService.getBuydownmanagerById(this.selected_id).then((data)=>{
  //         this.modelBuydownmanager=data;

  //         if(this.modelBuydownmanager.item_id != null){
  //           this.grupitemsfound = false;
  //            this.storeService.getItemById(this.modelBuydownmanager.item_id).then((data)=>{
  //             this.itemrow = data;
  //             this.itemrowfound = true;
  //           })
  //         }
  //         if(this.modelBuydownmanager.price_group_id != null){
  //           this.itemrowfound = false;
  //           this.grupitems = [];
  //            this.storeService.getPriceGroupById(this.modelBuydownmanager.price_group_id).then((data)=>{
  //             this.grupitems=data.items;
  //             if(this.grupitems.length > 0){
  //               this.grupitemsfound = true;
  //             }
  //           });
  //         }

  //       });
  //       this.modelTitle = "Edit";
  //       this.bulkupdateId = this.selected_id;
        
        
  //   }else{
  //     this.modelBuydownmanager = new Buydownmanager();
  //     this.modelTitle = "Add";
  //     this.bulkupdateId = null;
  //   }
  //   if (isPlatformBrowser(this.platformId)) { // For SSR 
  //     this.modalService.open(this.addbuydownmanagers, {
  //       size: 'lg',
  //       ariaLabelledBy: 'modal-bookmark',
  //       centered: true,
  //       windowClass: 'modal-bookmark'
  //     }).result.then((result) => {
  //       this.modalOpen = true;
  //       `Result ${result}`
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     });
  //   }
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  // ngOnDestroy() {
  //   if (this.modalOpen) {
  //     this.modalService.dismissAll();
  //   }
  // }
  // showIemCodeSection(){
  //   this.itmcode = true;
  //   this.pricegrp = false;
  //   this.grupitemsfound = false;
  //   this.grupitems = [];
  // }
  // showPriceGrpSection(){
  //   this.itemrowfound = false;
  //   this.itemrow = [];
  //   this.pricegrp = true;
  //   this.itmcode = false;
  // }
  // getGroupItems(ev){
  //   this.grupitems = [];
  //   this.storeService.getPriceGroupById(ev.target.value).then((data)=>{
  //     this.grupitems=data.items;
  //     if(this.grupitems.length > 0){
  //       this.grupitemsfound = true;
  //     }
      
  //     console.log("grupitems",this.grupitems);
  //   });
  // }
  // getItemDetail($event){
  //   if($event !== undefined){
  //     this.storeService.getItemById($event).then((data)=>{
  //       console.log(data);
  //       if(data.upc_code !=''){
  //         this.itemrow = data;
  //         this.itemrowfound = true;
  //       }else{
  //         this.itemrowfound = false;
  //       }
        
  //     }).catch((error) => {
  //       this.itemrowfound = false;
  //     });
  //   }else{
  //     this.itemrow = [];
  //   this.itemrowfound = false;
  //   }
    
    
  // }
  // onSearchByUpc($event) {
  //     if($event.target.value !='')
  //     this.storeService.getStoreItemListByUPC($event.target.value).then((data)=>{
  //       console.log(data);
  //       if(data.upc_code !=''){
  //         this.modelBuydownmanager.item_id = data._id;
  //         this.itemrow = data;
  //         this.itemrowfound = true;
  //       }else{
  //         Swal.fire('Item Search', 'Item not found!.', 'error');
  //         this.modelBuydownmanager.item_id = '';
  //         this.itemrowfound = false;
  //       }
        
  //     }).catch((error) => {
  //       Swal.fire('Item Search', 'Item not found!.', 'error');
  //       this.modelBuydownmanager.item_id = '';
  //       this.itemrowfound = false;
  //     });
    
    
  // }
  // onSearch($event) {
  //   if($event.term !=''){
  //     this.storeService.getStoreItemListOnSearch($event.term).then((data)=>{
  //       console.log(data);
  //       this.searchstoreitems = data;
  //     });
  //   }else{
  //     this.searchstoreitems = [];
  //   }
    
  // }
  // onRemove(event: any) {
  //   this.removedItemIds.push(event.value.id);
  // }

  // saveBuydownmanager(form) {
  //   let store_id = JSON.parse(localStorage.getItem('store_id'));
  //   let price_group_id;
  //   let item_id;
  //  if(form.valid) { 
  //   if(form.value.buydownfor == 'PriceGroup'){
  //    price_group_id = form.value.price_group_id;
  //    item_id = null;
  //   }else{
  //     price_group_id = null;
  //     item_id = this.modelBuydownmanager.item_id
  //   }
  //   let frmvalues =   {
  //     "store_id":store_id,
  //     "price_group_id":price_group_id,
  //     "item_id":item_id,
  //     "manufacturer_id":form.value.manufacturer_id,
  //     "discount_given_by_manufacturer":form.value.discount_given_by_manufacturer,
  //     "from_date":form.value.from_date,
  //     "to_date":form.value.to_date,
  //     "description":form.value.description
  //   }

  //   console.log("fff",frmvalues);
  //   //return false;
    
  //   this.storeService.saveBuydownmanager(frmvalues, this.buydownmId)
  //         .then((response) => {
  //            console.log(response);
  //            Swal.fire('Buy Down Manager!', 'Buy Down Manager Save Successfully.', 'success');
  //            this.sendaddbuydownmanager.emit(store_id);
  //            this.modalService.dismissAll();
  //         });
  //   }
  // }


}
