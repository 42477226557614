import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ItemCategoryRoutingModule } from './item-category-routing.module';
import { ItemCategoryComponent } from './item-category.component';
import { FormsModule } from '@angular/forms';
import { AddItemCategoryComponent } from './modal/additemcategory/additemcategory.component';

@NgModule({
  declarations: [
    ItemCategoryComponent,
    AddItemCategoryComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ItemCategoryRoutingModule,
    NgxDatatableModule
  ]
})
export class ItemCategoryModule { }
