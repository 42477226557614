import { isPlatformBrowser } from '@angular/common';
import {
    Component,
    Inject,
    OnInit,
    PLATFORM_ID,
    TemplateRef,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import * as _ from 'lodash';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { BehaviorSubject } from 'rxjs';

export interface Filters {
    store: string;
    station: string;
    transaction_type: string;
    transaction_time: string;
};


@Component({
    selector: 'app-fuel-pricing',
    templateUrl: './live-data.component.html',
    styleUrls: ['./live-data.component.scss'],
})
export class LiveDataComponent implements OnInit {
    storeLocationList = [];
    storeList = [];
    storeTransactionList = [];
    displayStoreTransactionList = [];
    store_full_name = '';
    transactiondetail = [];
    selectedIndex: any;
    filtercondt = [];
    formatedgetcurdata: any;
    selectedstoreid: any;
    selstorename = '';
    trclickstatus: boolean = false;
    show_transaction: boolean = false;
    selected_store_timezone = '';
    livesummarydatareport: any = ""
    summarystoreid: string = ""

    sales_events = ['SalesEvent', 'VoidEvent'];
    filter_object = {
        store: '',
        station: 'all',
        transaction_type: 'all',
        transaction_time: 'all'
    };
    filters = new BehaviorSubject<Filters>(this.filter_object);
    station_list = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        public navServices: NavService,
        public storeService: StoreService
    ) {
    }

    filterTransactionArray(arr) {
        if(_.isArray(arr)){
            if (_.isEmpty(arr)) {
                return [];
            }
            for (var i = 0; i <= arr.length - 1; i++) {
                if (arr[i]['TransactionTax']) {
                    arr.splice(i, 1);
                }
                if (arr[i] && arr[i]['TenderInfo']) {
                    arr.splice(i, 1);
                }
            }
        }else{
            arr = []
        }

        return arr;
    }

    async ngOnInit() {
        let store_id = JSON.parse(localStorage.getItem('store_id'));
        let store_obj = _.filter(this.storeList, (store) => store._id == store_id)[0];

        this.formatedgetcurdata = '2022_03_10';
        this.navServices.storeName.subscribe(async (data) => {
            if (data['_id'] != undefined) {
                localStorage.setItem('store_id', JSON.stringify(data['_id']));
                this.store_full_name = data['store_full_name'];
            }
        });
        this.selectedstoreid = store_id;
        this.filter_object.store = store_id;
        this.filters.next(this.filter_object);
        this.storeList = await this.storeService.getAllStores();

        this.filtercondt['count'] = 'all';
        await this.getStoreTransactions(store_id);
        var vm = this;
        this.filters.subscribe(async function (filters: Filters) {
            await vm.filterTransaction(filters);
        });

        await this.getlivesummarydata(store_id)
        // var today = new Date().toLocaleDateString("en-CA", { timeZone: store_obj['timezone'] });
        // this.formatedgetcurdata = today;
    }

    async getStoreTransactions(store_id: string) {
        let store_obj = _.filter(this.storeList, (store) => store._id == store_id)[0];
        this.selected_store_timezone = store_obj['timezone'];
        var today = new Date().toLocaleDateString("en-CA", { timeZone: store_obj['timezone'] });
        this.formatedgetcurdata = today;
        this.storeTransactionList = await this.storeService.getlivedatareport(
            store_id,
            'live',
            this.formatedgetcurdata
        );
        this.displayStoreTransactionList = this.storeTransactionList;
    }

    async getlivesummarydata(store_id: string){
        this.summarystoreid = store_id
        await this.storeService.getlivesummarydatareport(store_id, 'live', this.formatedgetcurdata )
        .then((response)=>{
            this.livesummarydatareport = response
        })
    }

    reloadTransactionTable(filtertype, filterval) {
        this.filter_object[filtertype] = filterval.target.value;
        this.filters.next(this.filter_object);
    }

    async setStationList() {
        let unique_stations = _.uniq(_.map(this.storeTransactionList, 'RegisterID'));
        this.station_list = _.compact(unique_stations);
    }

    async filterTransaction(filters) {
        if (this.selectedstoreid != filters.store) {
            this.selectedstoreid = filters.store;
            this.filter_object = {
                store: filters.store,
                station: 'all',
                transaction_type: 'all',
                transaction_time: 'all'
            };
            await this.getStoreTransactions(filters.store);
            await this.getlivesummarydata(this.selectedstoreid)

        }
        this.setStationList();
        // Apply filters here
        await this.filterByTransactionTime();
        await this.filterByTransactionType();
        await this.filterByStation();
        await this.setStoreAttribute();
    }

    async filterByStation() {
        var selected_station = this.filter_object.station;
        if (selected_station != 'all') {
            let filtered_stations_list = this.displayStoreTransactionList.filter(p => p.RegisterID == selected_station);
            this.displayStoreTransactionList = filtered_stations_list;
        }

    }

    async filterByTransactionTime() {
        let storeTransactionList = _.orderBy(this.storeTransactionList, ['EventEndDate', 'EventEndTime'],
            ['desc', 'desc']);
        if (this.filter_object.transaction_time == 'all') {
            this.displayStoreTransactionList = storeTransactionList;
        }

        if (this.filter_object.transaction_time == '50') {

            this.displayStoreTransactionList = _.take(storeTransactionList, 50);
        }

        if (this.filter_object.transaction_time == '5min') {
            // var then_date = new Date(Date.parse('2022-03-10 22:55:00') - (5 * 60 * 1000));
            // var then_date = new Date(Date.now() - (5 * 60 * 1000));
            var tzdt = new Date(new Date().toLocaleString("en-US", { timeZone: this.selected_store_timezone }));
            var then_date = new Date(tzdt.valueOf() - (5 * 60 * 1000));
            var last5min = storeTransactionList.filter(p => {
                let date = p.EventEndDate + ' ' + p.EventEndTime;
                return new Date(date) >= then_date;
            });
            this.displayStoreTransactionList = last5min;
        }

        if (this.filter_object.transaction_time == '1hour') {
            // var then_date = new Date(Date.parse('2022-03-10 22:55:00') - (60 * 60 * 1000));
            // var then_date = new Date(Date.now() - (60 * 60 * 1000));
            var tzdt = new Date(new Date().toLocaleString("en-US", { timeZone: this.selected_store_timezone }));
            var then_date = new Date(tzdt.valueOf() - (60 * 60 * 1000));
            var last1hour = storeTransactionList.filter(p => {
                let date = p.EventEndDate + ' ' + p.EventEndTime;
                return new Date(date) >= then_date;
            });
            this.displayStoreTransactionList = last1hour;
        }
    }

    async filterByTransactionType() {
        var displayTransactionList = this.displayStoreTransactionList;
        if (this.filter_object.transaction_type != 'all') {
            displayTransactionList = displayTransactionList.filter(p => p.EventType == this.filter_object.transaction_type);
        }

        this.displayStoreTransactionList = displayTransactionList;

    }

    setStoreAttribute() {
        this.show_transaction = false;
        var selected_store = this.selectedstoreid;
        let store_info = _.filter(this.storeList, function (store) {
            return store._id == selected_store;
        });
        this.selstorename = store_info[0].store_full_name;

    }

    show_tansaction_detail(transaction_id, indx) {
        this.selectedIndex = indx;
        this.transactiondetail = [];
        this.trclickstatus = !this.trclickstatus;
        let transaction = _.first(_.filter(this.displayStoreTransactionList, { TransactionID: transaction_id }));
        this.transactiondetail = transaction;
        console.log("transactiondetail", this.transactiondetail);

        this.show_transaction = true;

    }

}
