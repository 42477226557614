import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PriceGroupRoutingModule } from './price-group-routing.module';
import { PriceGroupComponent } from './price-group.component';
import { AddpricegroupComponent } from './modal/addpricegroup/addpricegroup.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PriceGroupComponent,
    AddpricegroupComponent
  ],
  imports: [
    FormsModule,
    NgxDatatableModule,
    CommonModule,
    PriceGroupRoutingModule
  ]
})
export class PriceGroupModule { }
