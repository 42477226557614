<ng-template #addfuelretailprice let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addfuelretailprice="ngForm" (ngSubmit)="savefuelprice(addfuelretailprice)">
            <div class="form-row">
                        
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form" >
                        <label>Current credit price </label>
                        <input type="text" class="form-control"  placeholder="{{curr_credit_price}}"   readonly>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form" >
                        <label>Set New credit price </label>
                        <input type="text" class="form-control" name="new_credit_price1" [(ngModel)]="modelFuelPrice.new_credit_price1"  #new_credit_price1="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelretailprice.submitted && new_credit_price1.invalid }" required>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form" >
                        <label>Current cash price </label>
                        <input type="text" class="form-control"  placeholder="{{curr_cash_price}}"   readonly>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form" >
                        <label>Set New cash price </label>
                        <input type="text" class="form-control" name="new_cash_price1" [(ngModel)]="modelFuelPrice.new_cash_price1"  #new_cash_price1="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelretailprice.submitted && new_cash_price1.invalid }" required>
                    </div>
                </div>

                
                
                        
                        
                        
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>