import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaxSetupComponent } from './tax-setup.component';
import { TaxSetupRoutingModule } from './tax-setup-routing.module';
import { FormsModule } from '@angular/forms';
import { AddtaxsetupComponent } from './addtaxsetup/addtaxsetup.component';
import { AddtaxstrategyComponent } from './addtaxstrategy/addtaxstrategy.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  declarations: [
    TaxSetupComponent,
    AddtaxsetupComponent,
    AddtaxstrategyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    TaxSetupRoutingModule,
  ]
})
export class TaxSetupModule { }
