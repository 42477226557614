import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../../../services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {

  public megaItems: Menu[];
  public levelmenuitems: Menu[];
  public store_full_name = new BehaviorSubject(null);
  // storeName: any;


  constructor(
    private router: Router,
    public navServices: NavService,
    public storeService: StoreService
  ) {
    this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    this.navServices.levelmenuitems.subscribe(levelmenuitems => this.levelmenuitems = levelmenuitems);
  }

  ngOnInit() {
    this.navServices.createdstore.subscribe(async (store) => {
      if (store) {
        let res = await this.storeService.getAllStores();
        if (res) {
          this.levelmenuitems = res;
          this.navServices.storeName.next(store);

        }
      }
    });

    this.storeService.getAllStores()
      .then(res => {
        this.levelmenuitems = res;
        if (res) {
          this.navServices.storeName.next(res[0]);
        }
      });

    this.navServices.storeName.subscribe((data) => {
      this.store_full_name.next(data['store_full_name']);
    })
    //  console.log(this.levelmenuitems);
  }

  megaMenuToggle() {
    this.navServices.levelMenu = false;
    this.navServices.megaMenu = !this.navServices.megaMenu;
    if (window.innerWidth < 991) {
      this.navServices.collapseSidebar = true;
    }
  }

  levelMenuToggle() {
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = !this.navServices.levelMenu;
    if (window.innerWidth < 991) {
      this.navServices.collapseSidebar = true;
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    console.log(item);
    // this.storeName = item;
    localStorage.setItem('store_id', JSON.stringify(item._id));

    this.navServices.storeName.next(item);
    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
    this.navServices.levelMenu = !this.navServices.levelMenu;
  }
  livedatabtnClick= function () {
    this.router.navigateByUrl('/live-data');
  }


}
