<ng-template #addtaxstrategy let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Tax Strategy</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addtaxstrategy="ngForm" (ngSubmit)="savetaxstrategy(addtaxstrategy)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Tax Strategy Rate</label>
                    <input class="form-control" type="text" name="store_tax_strategy_rate" [(ngModel)]="modeTaxStrategy.store_tax_strategy_rate"  required>
                </div>
                <div class="form-group col-md-6">
                    <label>Commander Tax Id</label>
                    <input class="form-control" type="text" name="store_tax_commander_tax_id" [(ngModel)]="modeTaxStrategy.store_tax_commander_tax_id" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Tax Commands</label>
                    <input class="form-control" type="text" name="store_tax_commands" [(ngModel)]="modeTaxStrategy.store_tax_commands" required>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>