<ng-template #addfueltax let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Fuel Tax</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addFuleTax="ngForm" (ngSubmit)="saveFuelTax(addFuleTax)">
            <div class="form-row">
                <!-- <div class="form-group col-md-6">
                    <label>Fuel Tax Description</label>
                    <input class="form-control" type="text" name="store_fuel_tax_description" [(ngModel)]="modelStoreFuelTax.store_fuel_tax_description"  required>
                </div> -->
                <div class="form-group col-md-6">
                    <label>Fuel Tax Description</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fuel_tax_description" [(ngModel)]="modelStoreFuelTax.store_fuel_tax_description"  required>
                        <option value="description 1">description 1</option>
                        <option value="description 2">description 2</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Fuel Tax</label>
                    <input class="form-control" type="text" name="store_fuel_tax" [(ngModel)]="modelStoreFuelTax.store_fuel_tax" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Tax On Tax</label>
                    <input class="form-control" type="text" name="store_fuel_tax_on_tax" [(ngModel)]="modelStoreFuelTax.store_fuel_tax_on_tax" required>
                </div> -->
                <div class="form-group col-md-6">
                    <label>Fuel Tax Rate</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fuel_tax_rate" [(ngModel)]="modelStoreFuelTax.store_fuel_tax_rate"  required>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Fuel Tax Rate</label>
                    <input class="form-control" type="text" name="store_fuel_tax_rate" [(ngModel)]="modelStoreFuelTax.store_fuel_tax_rate" required>
                </div> -->
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>