import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddAtriaComponent } from './modal/addaltria/addaltria.component';
import { CONSTANTS } from 'src/app/util/constants';

@Component({
  selector: 'app-scan-data',
  templateUrl: './scan-data.component.html',
  styleUrls: ['./scan-data.component.scss']
})
export class ScanDataComponent implements OnInit {
  @ViewChild("addaltria") AddAtria: AddAtriaComponent;
  modelFtpList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  public selected = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelFtpList = await this.storeService.getAltriaftpdeatil(data['_id']);
      }
    })
    
  }
  async reloadFtpTable(event){
    this.modelFtpList = await this.storeService.getAltriaftpdeatil(event);
  }

}
