import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddItemCategoryComponent } from './modal/additemcategory/additemcategory.component';
import { CONSTANTS } from 'src/app/util/constants';


export class ItemCategory{
  public store_id: string;
  public name: string;
}

@Component({
  selector: 'app-item-category',
  templateUrl: './item-category.component.html',
  styleUrls: ['./item-category.component.scss']
})
export class ItemCategoryComponent implements OnInit {

  @ViewChild("additemcategory") AddItemCategory: AddItemCategoryComponent;
  modelItemCategoryList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelItemCategoryList = await this.storeService.getItemCategory(data['_id']);
      }
    })
  }

  async reloadItemCategoryTable(event){
    this.modelItemCategoryList = await this.storeService.getItemCategory(event);
  }
  removeItemCategory(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeItemCategory(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadItemCategoryTable(store_id);
          }
        });
    }
  }

}
