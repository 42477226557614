import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddVendorInvoiceItemComponent } from './add-vendor-invoice-item.component';

const routes: Routes = [
  { path: '', component: AddVendorInvoiceItemComponent },
  { path: ':itemId', component: AddVendorInvoiceItemComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddVendorInvoiceItemRoutingModule { }
