<div class="container-fluid pdlr">
    <div class="row">

        <div class="col-xl-12">
            <div class="card mtb">
                <form #searchForm="ngForm" (ngSubmit)="onSubmitAdvanceSubmit($event, searchForm)">
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Stock transaction Details</h4>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Select date </label>
                                <input type="date" class="form-control" name="stock_modified_date" ngModel>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Stock Transaction </label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9" name="stock_id" ngModel>
                                    <option value="">Select</option>
                                    <option *ngFor="let stocktransct of stocktransactionList"
                                        [value]="stocktransct._id">
                                        {{stocktransct.stock_description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">From Store</label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_id" ngModel>
                                    <option value="">Select</option>
                                    <option *ngFor="let st of storeList" [value]="st._id">
                                        {{st.store_full_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>UPC code </label>
                                <input type="text" class="form-control" name="upc_code"  ngModel>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="updatebtn">
                                <button type="submit">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="col-xl-12">
            <div class="card mtb"> -->
                <!-- <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="addupdatebtn">
                                <button>+ Add stock transaction</button>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>UPC code </label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Transaction type</label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9">
                                    <option>1</option>
                                    <option>2</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">From store </label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9">
                                    <option>1</option>
                                    <option>2</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Quantity </label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Modified date </label>
                                <input type="date" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Description </label>
                                <textarea class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Note </label>
                                <textarea class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="updatebtn">
                                <button>Save</button>
                            </div>
                        </div>


                    </div>
                </form> -->
            <!-- </div>
        </div> -->
        <div class="col-xl-12">
            <div class="card" style="margin-top: 30px;">
                <div class="card-body">
                    <div class="card-header calender-btn">
                        <h5>
                            <button class="btn btn-primary pull-right"
                                (click)="addstocktransaction.openModal();$event.preventDefault()">Add new </button>
                            <div class="clearfix"></div>
                        </h5>
                    </div>
                    <!-- <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>UPC code</th>
                                    <th>Description</th>
                                    <th>Itransaction type</th>
                                    <th>Store</th>
                                    <th>To store</th>
                                    <th>Selling Price </th>
                                    <th>Qty</th>
                                    <th>Notes</th>
                                    <th>Transaction date </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    <div class="card-body custom-datatable noscroll">
                        <ngx-datatable class="bootstrap" [rows]="modelAddstocktransactionList"
                            [loadingIndicator]="loadingIndicator" [columns]="columns" [columnMode]="'force'"
                            [headerHeight]="40" [summaryRow]="true" [summaryPosition]="'bottom'" [footerHeight]="40"
                            [rowHeight]="'auto'" [reorderable]="reorderable">
                            <ngx-datatable-column name="UPC code" prop="stock_UPC_code"></ngx-datatable-column>
                            <ngx-datatable-column name="Description" prop="stock_description"></ngx-datatable-column>
                            <ngx-datatable-column name="Transaction type"
                                prop="stock_transaction_type"></ngx-datatable-column>
                            <!-- <ngx-datatable-column name="Store" prop="vendor_item_name"></ngx-datatable-column>  -->
                            <ngx-datatable-column name="To store" prop="stock_from_store"></ngx-datatable-column>
                            <ngx-datatable-column name="Selling Price " prop="stock_quantity"></ngx-datatable-column>
                            <!-- <ngx-datatable-column name="Qty" prop="vendor_item_scan_code"></ngx-datatable-column>  -->
                            <ngx-datatable-column name="Notes" prop="stock_note"></ngx-datatable-column>
                            <ngx-datatable-column name="Transaction date"
                                prop="stock_modified_date"></ngx-datatable-column>
                            <ngx-datatable-column name="Action" widht="600">
                                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                                    <div class="trlistBtn">
                                        <button class="edit-btn"
                                            (click)="addstocktransaction.openModal(row._id);$event.preventDefault()"><i
                                                class="fa fa-pencil"></i></button>
                                        <button class="delete-btn" (click)="removeStocktransaction(row._id)"><i
                                                class="fa fa-trash"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-addstocktransaction (sendaddstocktransaction)='reloadStocktransactionTable($event)'
    #addstocktransaction></app-addstocktransaction>