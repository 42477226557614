<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login-logo.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/login-logo.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main">

              <ng-template [ngIf]="showRestPasswordForm">
                <form class="theme-form" #restPasswordForm="ngForm">
                  <h4>Reset Your Password</h4>
                  <div class="form-group">
                    <label class="col-form-label">Enter Your Email Address</label>
                    <div class="row">
                      <div class="col-md-12">
                        <input class="form-control mb-1" name="email" type="email" required [(ngModel)]="email"
                          autocomplete="off">
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary btn-block m-t-10" type="button"
                          (click)="getEmailForRestPassword(restPasswordForm)">Send</button>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-template>

              <ng-template [ngIf]="showCreatePasswordForm">
                <form class="theme-form" #createPasswordForm="ngForm">
                  <h6 class="mt-4">Create Your Password</h6>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Enter OTP</label>
                    <div class="row">
                      <div class="col">
                        <input class="form-control text-center opt-text" name="otp" type="text" required
                          [(ngModel)]="otp" autocomplete="off">
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">New Password</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" name="password" required=""
                      placeholder="*********" [(ngModel)]="password" autocomplete="off">
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">Retype Password</label>
                    <input class="form-control" type="password" name="confirmPassword" required=""
                      placeholder="*********" ngModel autocomplete="off">
                  </div>


                  <div *ngIf="isPasswordNotMatch" class="alert" style="color: red;">
                    Password do NOT match!
                  </div>


                  <div class="form-group mb-0">
                    <button class="btn btn-primary btn-block" type="submit"
                      (click)="changePassword(createPasswordForm)">Done</button>
                  </div>

                  <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a
                        class="btn-link text-danger" (click)="resendOtp($event)">Resend</a></span></div>

                  <p class="mt-4 mb-0">Already have an password?<a class="ml-2"
                      [routerLink]="'/authentication/login/simple'">Sign in</a></p>
                </form>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>