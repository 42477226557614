import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class StoreSalesTax {
  public store_sales_tax_name: string;
  public store_sales_pos_tax: string;
  public store_sales_city_tax: string;
  public store_sales_country_tax: string;
  public store_sales_state_tax: string;
}


@Component({
  selector: 'app-addsalestax',
  templateUrl: './addsalestax.component.html',
  styleUrls: ['./addsalestax.component.scss']
})
export class AddsalestaxComponent implements OnInit {

  @ViewChild("addsalestax", { static: false }) addsalestax: TemplateRef<any>;
  @Output() sendaddedsalestax: EventEmitter<string> = new EventEmitter<string>();
 
  public closeResult: string;
  public modalOpen: boolean = false;
  modelStoreSalesTax = new StoreSalesTax();
  selected_tax_id : string;
  public modelTitle: string;
  public salesTaxId: string; 

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    this.selected_tax_id= row_id;
    if(this.selected_tax_id != null){
        this.storeService.getStoreSalesTaxById(this.selected_tax_id).then((data)=>{this.modelStoreSalesTax=data;});
        this.modelTitle = "Edit";
        this.salesTaxId = this.selected_tax_id;
    }else{
      this.modelStoreSalesTax = new StoreSalesTax();
      this.modelTitle = "Add";
      this.salesTaxId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
     const dialogRef = this.modalService.open(this.addsalestax, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveSalesTax(form) {
  let store_id = JSON.parse(localStorage.getItem('store_id'));
   if(form.valid) { 
      this.storeService.saveSalesTax(form.value, this.salesTaxId)
          .then((response) => {
              console.log(response);
              Swal.fire('Sales Tax!', 'Sales Tax Info Save Successfully.', 'success');
              this.sendaddedsalestax.emit(store_id);
              this.modalService.dismissAll();
              });
       
     }
  }


}
