import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { StoreRole } from 'src/app/shared/classes/storerole';

@Component({
  selector: 'app-addstorerole',
  templateUrl: './addstorerole.component.html',
  styleUrls: ['./addstorerole.component.scss']
})
export class AddStoreRoleComponent implements OnInit {
  @ViewChild("addstorerole", { static: false }) addstorerole: TemplateRef<any>;
  @Output() sendaddedstorerole:EventEmitter<string> = new EventEmitter<string>();
  public closeResult: string;
  public modalOpen: boolean = false;
  selected_id : string;
  public modelTitle: string;
  public itemId: string; 
  modelRole = new StoreRole();
  finaldata = [];
  selectedpermissionsArr = [];
  editrolename = true;

  events: Event[] = [];
  selectedItems: any;
  menupages = [];
  checkedPerIDs = [];
  detailforpermission = [];
  selrole_id:any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  isChecked(eventId){
    //console.log(eventId);
//console.log(this.selectedpermissionsArr.indexOf(eventId));
    return this.selectedpermissionsArr.indexOf(eventId)>-1 ? true : null
  }

  openModal(row_id?:null) {
    this.finaldata = [];
    this.selectedpermissionsArr = [];
    let store_id = JSON.parse(localStorage.getItem('store_id'));

    this.selectedpermissionsArr = [];
    this.checkedPerIDs = [];

    this.selected_id= row_id;
    this.storeService.getStoreMenuPages(store_id).then((data)=>{
      data.forEach((value, key) => {
        let pageid = value._id;
        let menupages = {id:value._id, name:value.name};
        this.storeService.getStorepermissionlistbypageid(pageid).then((perdata)=>{
          console.log("perdata",perdata);
          
              menupages["permissions"] = perdata;
              
        });

        this.finaldata.push(menupages);
      });
    });
    //console.log("finaldata",this.finaldata);
    if(this.selected_id != null){
      this.editrolename = false;
        this.storeService.getStoreRoleById(this.selected_id).then((data)=>{
          this.modelRole=data;
          this.storeService.getstorepermissionlistbyroleid(data._id).then((pdata)=>{
            pdata.forEach((value, key) => {
              this.selectedpermissionsArr.push(value.permission_id);
            });
          });
        });
        this.modelTitle = "Edit Role";
        this.itemId = this.selected_id;
        this.checkedPerIDs = this.selectedpermissionsArr;
    }else{
      this.editrolename = true;
      this.modelRole = new StoreRole();
      this.modelTitle = "Add Role";
      this.itemId = null;
    }

    
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addstorerole, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  saverole(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    if(form.valid) {
    //return false;
    this.storeService.saveStoreRole(form.value, this.itemId)
          .then((response) => {
            let roleid = this.itemId;
            console.log("this.checkedPerIDs",this.checkedPerIDs);


            if(this.itemId !=null){
              this.selrole_id = this.itemId;
            }else{
              this.selrole_id = response._id;
            }
            this.storeService.deletestorerolepermissions(this.selrole_id).then((response) => {
              
            });
            this.checkedPerIDs.map((value1, key1) => {
              this.detailforpermission[key1] = {"store_id":store_id,"role_id":this.selrole_id,"permission_id":value1};
            });
            
            
            // this.checkedPerIDs.map((value1, key1) => {
            //   this.detailforpermission[key1] = {"role_id":roleid,"permission_id":value1};
            // });
            console.log("this.detailforpermission",this.detailforpermission);
            
            this.storeService.saveStoreRolePermissions(this.detailforpermission, null).then((perresponse) => {
            });
             
            Swal.fire('Role!', 'Role Save Successfully.', 'success');
            this.sendaddedstorerole.emit(store_id);
            this.modalService.dismissAll();
          });
    }
  }
  fetchPerCheckedIDs($event) {
  }
  changeSelection(evt) {
    console.log("evt.target",evt.target);

    if(evt.target.checked){
      console.log("t3");
      this.checkedPerIDs.push(evt.target.value);
    }
    if(!evt.target.checked){
      console.log("t");
      var xchkid = this.checkedPerIDs.indexOf(evt.target.value);
       this.checkedPerIDs.splice(xchkid,1);
    }

    console.log("ddffd",this.checkedPerIDs);
  }
}
