<ul class="horizontal-menu">
    <li><button class="btn btn-primary slect-btn mr-2 live_data_btn" (click)="livedatabtnClick();">Live Data</button></li>
    <li class="level-menu">
        <a class="nav-link live_data_btn" href="javascript:void(0)" [class.active]="navServices.levelMenu" (click)="levelMenuToggle()">
            <app-feather-icons></app-feather-icons>
            <span>{{ store_full_name.getValue() | titlecase  }} <i class="fa fa-angle-down"></i></span>
        </a>
        <ul class="header-level-menu menu-to-be-close" [class]="navServices.levelMenu ? 'd-block' : 'd-none'">
            <li *ngFor="let levelmenuitem of levelmenuitems">
                <!-- sub -->
                <a href="javascript:void(0);" (click)="toggletNavActive(levelmenuitem)">
                    <app-feather-icons [icon]="levelmenuitem.icon"></app-feather-icons>
                    <span>{{levelmenuitem.store_full_name | titlecase }}</span>
                </a>
                <!-- Link -->
                <a [routerLink]="!levelmenuitem.type ? null : [levelmenuitem.path]" *ngIf="levelmenuitem.type === 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <app-feather-icons [icon]="levelmenuitem.icon"></app-feather-icons>
                    <span>{{levelmenuitem.store_full_name | titlecase}}</span>
                </a>
                <!-- External Link -->
                <a href="{{ !levelmenuitem.type ? null : levelmenuitem.path }}" *ngIf="levelmenuitem.type === 'extLink'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <app-feather-icons [icon]="levelmenuitem.icon"></app-feather-icons>
                    <span>{{levelmenuitem.store_full_name | titlecase}}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ !levelmenuitem.type ? null : levelmenuitem.path }}" target="_blank" *ngIf="levelmenuitem.type === 'extTabLink'">
                    <app-feather-icons [icon]="levelmenuitem.icon"></app-feather-icons>
                    <span>{{levelmenuitem.store_full_name | titlecase}}</span>
                </a>
            </li>
        </ul>
    </li>
    <!-- <li><button class="btn btn-primary slect-btn">Monitor</button></li>
    <li><a class="btn btn-primary slect-btn" routerLink="/day-recon-dashboard">Item Sales</a> 
    </li>-->
</ul>