import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddpricegroupComponent } from './modal/addpricegroup/addpricegroup.component';
import { CONSTANTS } from 'src/app/util/constants';
export class PriceGroup{
  public store_id: string;
  public name: string;
  public buying_cost: string;
  public selling_price: string;
  public item_count: string;
  public purchase_entry_margin: string;
  public item_ids: string;
}
@Component({
  selector: 'app-price-group',
  templateUrl: './price-group.component.html',
  styleUrls: ['./price-group.component.scss']
})
export class PriceGroupComponent implements OnInit {
  @ViewChild("addpricegroup") Addpricegroup: AddpricegroupComponent;

  modelPriceGroupList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  public selected = [];
  constructor(public storeService: StoreService,public navServices: NavService) { }

  storeList = [];

  ngOnInit(): void {

    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelPriceGroupList = await this.storeService.getPriceGroup(data['_id']);
      }
    })

    this.storeService.getAllStores()
          .then((response) => {
             this.storeList = response;
          });
          
  }

  async reloadPriceGroupTable(event){
    this.modelPriceGroupList = await this.storeService.getPriceGroup(event);
  }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  removePriceGroup(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removePriceGroup(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadPriceGroupTable(store_id);
          }
        });
    }
  }

}
