import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ManagePromotionsRoutingModule } from './manage-promotions-routing.module';
import { ManagePromotionsComponent } from './manage-promotions.component';
import { FormsModule } from '@angular/forms';
import { AddmixmatchmaintenanceComponent } from './modal/addmixmatchmaintenance/addmixmatchmaintenance.component';
import { AddcombodealComponent } from './modal/addcombodeal/addcombodeal.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ManagePromotionsComponent,
    AddmixmatchmaintenanceComponent,
    AddcombodealComponent
  ],
  imports: [
    CommonModule,
    ManagePromotionsRoutingModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule
    
  ]
})
export class ManagePromotionsModule { }
