import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/shared/services/nav.service';
import Swal from 'sweetalert2';

export class FuelGradeMapping {
  type_obj_id: string
  type: string
  fuelgrade_name: string
  register_id: string
  display_name: string
}


@Component({
  selector: 'app-add-fuelgraderegistermapping',
  templateUrl: './add-fuelgraderegistermapping.component.html',
  styleUrls: ['./add-fuelgraderegistermapping.component.scss']
})
export class AddFuelgraderegistermappingComponent implements OnInit {

  @ViewChild("addfuelgraderegistermapping", { static: false }) fuelgrademapping: TemplateRef<any>;
  @Output() sendaddedgasmapping:EventEmitter<string> = new EventEmitter<string>();

  public modalOpen: boolean = false;
  public closeResult: string;
  selected_id : string;
  modelTitle: string;
  fuelgrade: any;
  mapfuel_selected_id = null;

  fuelgraderegistermapping = new FuelGradeMapping()


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private modalService: NgbModal,
  public navServices: NavService,
  public storeService: StoreService) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.modelTitle = "Fuel Grade Register Mapping"
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
      }
    })
  }

  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    
    this.selected_id= row_id;
    if(row_id){
      this.storeService.getFuelGradeById(this.selected_id).then((data)=>{
        console.log("Data: "+JSON.stringify(data))
        this.fuelgrade = data
        this.fuelgraderegistermapping.type_obj_id = this.fuelgrade._id
        this.fuelgraderegistermapping.fuelgrade_name = this.fuelgrade.store_fuel_grade_display_name
        this.fuelgraderegistermapping.type = "Fuel"
        if(this.fuelgrade.regmapping !=null){
          this.mapfuel_selected_id = this.fuelgrade.regmapping._id;
          this.fuelgraderegistermapping.display_name = this.fuelgrade.regmapping.display_name;
          this.fuelgraderegistermapping.register_id = this.fuelgrade.regmapping.register_id;
        }else{
          this.mapfuel_selected_id =  null;
          this.fuelgraderegistermapping.display_name = '';
          this.fuelgraderegistermapping.register_id = '';
        }
      })
    }
    
    if (isPlatformBrowser(this.platformId)) { 
      this.modalService.open(this.fuelgrademapping, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveFuelGradeData(form){
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    console.log("form: "+JSON.stringify(this.fuelgraderegistermapping))
    if (!form.invalid) {
      this.storeService.saveregistermapping(this.fuelgraderegistermapping, this.mapfuel_selected_id )
        .then((response) => {
          console.log("Response: " + response)
          if(response){
            Swal.fire('Register Mapping!', 'Reigster Mapping Added Successfully.', 'success');
            this.sendaddedgasmapping.emit(store_id);
            this.modalService.dismissAll();
          }else{
            Swal.fire('Register Mapping!', 'Reigster Mapping Added Failed.', 'error');
          }
        })
    }
    return false
  }
}
