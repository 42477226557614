import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddsalestaxComponent } from './modal/addsalestax/addsalestax.component';
import { AddfueltaxComponent } from './modal/addfueltax/addfueltax.component';
import { CONSTANTS } from 'src/app/util/constants';
import { AddsalesrestrictionComponent } from './modal/addsalesrestriction/addsalesrestriction.component';
import { AddstorelocationComponent } from './modal/addstorelocation/addstorelocation.component';
import { AddpaymentmethodComponent } from './modal/addpaymentmethod/addpaymentmethod.component';
import { AddbankaccountComponent } from './modal/addbankaccount/addbankaccount.component';
import { BankAccount, LotterySetting, ScheduleSetting, SettingOff, StoreInfo, StoreServices } from 'src/app/shared/classes/store';
import { TankGradeSetting,TankSetting } from 'src/app/shared/classes/store';
import { JSONOPTIONS } from 'src/app/util/jsonoptions';
import { ToastrService } from 'ngx-toastr';
import { FileuploadService } from  'src/app/services/fileupload.service';

export class StoreSalesTax {
  public store_sales_tax_name: string;
  public store_sales_pos_tax: string;
  public store_sales_city_tax: string;
  public store_sales_country_tax: string;
  public store_sales_state_tax: string;
}

export class StoreFuelTax {
  public store_fuel_tax_description: string;
  public store_fuel_tax: string;
  public store_fuel_tax_on_tax: string;
  public store_fuel_tax_rate: string;
}


export class StoreSalesRestriction {
  public store_pos_sales_restriction_name: string;
  public store_sales_restriction_min_cust_age: string;
  public store_sales_restriction_min_clerk_age: string;
  public store_sales_restriction_transaction_limit: string;
  public store_sales_restrict: string;
  public store_pos_sales_prohibit_discount: string;
}

export class StoreFuelGrade {
  public store_fuel_grade: string;
  public store_fuel_department: string;
  public store_fuel_tax: string;
  public store_fuel_grade_id: string;
  public store_fuel_grade_display_name: string;
}

export class StoreTank {
  public store_tank_number: string;
  public store_fuel_grade: string;
  public store_tank_recorder_level: string;
  public store_tank_ullage: string;
  public store_tank_type: string;
  public store_tank_description: string;
  public store_tank_capacity: string;
  public store_cuurent_tank_volume: string;
  public store_tank_details_notes: string;
}

export class StoreLocation {
  public store_id: string;
  public store_fees_name: string;
  public store_pos_fees_id: string;
  public store_pos_fee: string;
  public store_pos_amount_range: string;
  public store_fees_is_refundable: string;
}

export class StorePaymentmethod {
  public store_mop_no: string;
  public store_mop_name: string;
  public store_system_mop: string;
}

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  @ViewChild("addsalestax") AddSalesTax: AddsalestaxComponent;
  @ViewChild("addfueltax") AddFuelTax: AddfueltaxComponent;
  @ViewChild("addsalesrestriction") AddSalesRestriction: AddsalesrestrictionComponent;
  @ViewChild("addstorelocation") Addstorelocation: AddstorelocationComponent;
  @ViewChild("addbankaccount") Addbankaccount: AddbankaccountComponent;


  public openTab: string = "store_info";
  model = new StoreInfo();
  modelSalesTaxList = [];
  modelFuelTaxList = [];
  modelSalesRestrictionList = [];
  modelFuelGradeList = [];
  modelTankList = [];
  modelStoreLocationList = [];
  modelPaymentMethodList = [];
  modelAddBankAccountList = [];
  public storelogoname = '';

  public modalOpen: boolean = false;

  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [

  ];

  departmentList = [];
  timezoneList  = [];

  lotteryModel = new LotterySetting();
  addBankAccountModel = new BankAccount();
  settingoffModel = new SettingOff();
  storeServicesModel = new StoreServices();
  schedulingsettingModel = new ScheduleSetting();

  tankSettingModel = new TankSetting();
  plusGradeTankType = [];
  yesnooptions = [];
  gassupliersmodes = [];
  gassuplierscreditsettls = [];
  storeBankaccountList = [];
  tankitemId = null;
  showAgentFields = false;
  issellgas = true;
  store_logofile : File = null;
  

  constructor(
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal,
    public jsonoptions: JSONOPTIONS,
    private toastrService: ToastrService,
    private fileUploadService:FileuploadService
  ) { }

  ngOnInit(): void {
    let getstore = null;
    
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        this.model = await this.storeService.getStore(data['_id']);
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
      }
      console.log(data);
    })

    var store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreDepartment(store_id).then((data)=>{this.departmentList=data;});
    this.storeService.getStoreTimezones().then((data)=>{this.timezoneList=data;});
    
    this.plusGradeTankType = this.jsonoptions.PlusGradeTankType;
    this.yesnooptions = this.jsonoptions.YESNO;
    this.gassupliersmodes = this.jsonoptions.GASSUPLIERPAYMODES;
    this.gassuplierscreditsettls = this.jsonoptions.GASSUPLIERCREDITCARDSETTLE;

    this.storeService.getBankaccountList(store_id).then((data)=>{this.storeBankaccountList=data;});
    this.storeService.getTankSetting(store_id).then((data)=>{
      this.tankitemId=data._id;
      this.tankSettingModel=data; 
      if(data.is_having_gas == 2){
        this.showAgentFields = true;
        this.issellgas = true;
      }
      if(data.is_having_gas == 1){
        this.showAgentFields = false;
        this.issellgas = true;
      }
      if(data.is_having_gas == 0){
        this.issellgas = false;
      }
      
    });

  }

  addStore() {
    this.model = new StoreInfo();
    localStorage.removeItem('store_id');
  }
  onstorelogoChange(fileev) {
    this.store_logofile =  fileev.item(0);
    console.log("store_logofile",this.store_logofile);
  }
 
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  async reloadSalesTaxTable(event) {
    this.modelSalesTaxList = await this.storeService.getStoreSalesTax(event);
  }

  async reloadFuelTaxTable(event) {
    this.modelFuelTaxList = await this.storeService.getStoreFueltaxList(event);
  }

  async reloadSalesRestrictionTable(event) {
    this.modelSalesRestrictionList = await this.storeService.getSalesRestrictionList(event);
  }

  async reloadFuelGradeTable(event) {
    this.modelFuelGradeList = await this.storeService.getStoreFuelGrade(event);
  }

  async reloadTankTable(event) {
    this.modelTankList = await this.storeService.getStoreTank(event);
  }

  async reloadStorelocationTable(event) {
    this.modelStoreLocationList = await this.storeService.getStoreLocation(event);
  }

  async reloadPaymentmethodTable(event) {
    this.modelPaymentMethodList = await this.storeService.getPaymentmethod(event);
  }

  async reloadBankAccountTable(event) {
    this.modelAddBankAccountList = await this.storeService.getBankaccountList(event);
  }

  async tabbed(val: any) {
    this.openTab = val;
    var store_id = JSON.parse(localStorage.getItem('store_id'));

    switch (val) {
      case 'sales_tax':
        this.modelSalesTaxList = await this.storeService.getStoreSalesTax(store_id);
        this.modelFuelTaxList = await this.storeService.getStoreFueltaxList(store_id);
        break;
      case 'fuel_details':
        this.modelFuelGradeList = await this.storeService.getStoreFuelGrade(store_id);
        this.modelTankList = await this.storeService.getStoreTank(store_id);
        break;
      case 'lottery_settings':
        this.lotteryModel = await this.storeService.getLotterySettings(store_id);
        break;
      case 'setting_off':
        this.settingoffModel = await this.storeService.getTimeoffsetting(store_id);
        break;
      case 'store_services':
        this.storeServicesModel = await this.storeService.getstoreServices(store_id);
        break;
      case 'bank_account':
        this.modelAddBankAccountList = await this.storeService.getBankaccountList(store_id);
        break;
      case 'store_location':
        this.modelStoreLocationList = await this.storeService.getStoreLocation(store_id);
        break;
      case 'payment_method':
        this.modelPaymentMethodList = await this.storeService.getPaymentmethod(store_id);
        break;
      case 'sales_restriction':
        this.modelSalesRestrictionList = await this.storeService.getSalesRestrictionList(store_id);
        break;
      case 'setting_scheduling':
          this.schedulingsettingModel = await this.storeService.getSchedulesetting(store_id);
          break;
      default:
    }
  }


  //store info save
  onSubmitStoreInfo(form: any) {
    
    if (this.store_logofile) {
      var store_id = JSON.parse(localStorage.getItem('store_id'));
      const formData = new FormData();
      formData.append('file', this.store_logofile);
      formData.append('store_id', store_id);
      formData.append('folder', 'assets/store_logo')
      
      //formData.append('storelogo', this.store_logofile);
      this.storeService.uploadfile(formData)  
      .then((filresponse) => {
         console.log("filresponse",filresponse)
          let otherinfo = [];
          otherinfo['store_logo'] = filresponse['Key'];
          
          //formData.append('store_logo', store_id);
          this.storeService.saveStore(form.value, store_id,otherinfo)  
          .then((response) => {
            this.navServices.createdstore.next(response);
            this.tabbed("sales_tax");

          });
      });
    
    } else {
    var store_id = JSON.parse(localStorage.getItem('store_id'));
   
    this.storeService.saveStore(form.value, store_id)  
      .then((response) => {
        this.navServices.createdstore.next(response);
        this.tabbed("sales_tax");

      });
    }
    
  }


  //store sales tax save
  async salesUpdateConti(val: any) {
    switch (val) {
      case 'sales_tax':
        this.tabbed("sales_tax");
        break;
      case 'sales_restriction':
        this.tabbed("sales_restriction");
        break;
      case 'payment_method':
        this.tabbed("payment_method");
        break;
      case 'fuel_details':
        this.tabbed("fuel_details");
        break;
      case 'store_location':
        this.tabbed("store_location");
        break;
      case 'store_services':
        this.tabbed('store_services');
        break;
      default:
    }
  }


  removeAddSalesTax(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeAddSalesTax(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadSalesTaxTable(store_id);
          }
        });
    }
  }


  removeAddFuelTax(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeAddFuelTax(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadFuelTaxTable(store_id);
          }
        });
    }
  }

  removeSalesRestriction(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeSalesRestriction(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadSalesRestrictionTable(store_id);
          }
        });
    }
  }

  removeFuelGrade(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeFuelGrade(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadFuelGradeTable(store_id);
          }
        });
    }
  }

  removeTank(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeTank(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadTankTable(store_id);
          }
        });
    }
  }

  removeStoreLocation(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeStoreLocation(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadStorelocationTable(store_id);
          }
        });
    }
  }

  removePaymentMethod(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removePaymentMethod(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadPaymentmethodTable(store_id);
          }
        });
    }
  }

  removeBankaccount(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeBankaccount(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadBankAccountTable(store_id);
          }
        });
    }
  }


  //lottery setting save
  onSubmitlotterySettingsForm(form: any) {
    this.storeService.saveLotterySettings(form.value)
      .then((response) => {
        this.tabbed("bank_account");
      });
  }

  //Add bank account save
  // onSubmitaddbankAccountForm(form: any) {
  //   this.storeService.addBankaccount(form.value)
  //     .then((response) => {
  //       this.tabbed("setting_off");
  //     });
  // }


  //setting off setting save
  onSubmitsettingoffForm(form: any) {
    this.storeService.saveTimeoffsetting(form.value)
      .then((response) => {
        this.tabbed("setting_scheduling");
      });
  }


  //store services setting save
  onSubmitstoreServicesForm(form: any) {
    this.storeService.savestoreServices(form.value)
      .then((response) => {
        this.tabbed("lottery_settings");
      });
  }


  //setting off setting save
  onSubmitschedulesettingForm(form: any) {
    this.storeService.saveSchedulesetting(form.value)
      .then((response) => {
        alert("success");
      });
  }

  onSubmitTankSetting(e, form: any) {
    e.preventDefault();
    if (form.invalid) {
      return false;
    }else{
      let comm_per_volume = '';
    let tank_report_template = '';
    
      let frmvalues = {};
      if(form.value.comm_per_volume){
        comm_per_volume = form.value.comm_per_volume
      }else{
        comm_per_volume = '';
      }
      if(form.value.tank_report_template){
        tank_report_template = form.value.tank_report_template
      }else{
        tank_report_template = '';
      }
      if(form.value.is_having_gas == 0){
         frmvalues =   {
          "grade_tank_type":'',
          "blend_ratio":'',
          "is_sell_diesel":false,
          "comm_per_volume":'',
          "tank_report_template":'',
          "gas_supplier_paid_mode":'',
          "store_bank_account_id":'',
          "gas_supplier_settle_credit_card":form.value.gas_supplier_settle_credit_card,
          "is_having_gas":0,
          "cc_grade_tank_type":form.value.cc_grade_tank_type,
          "is_gas_cashcard":form.value.is_gas_cashcard,
        }
      }else{
         frmvalues =   {
          "grade_tank_type":form.value.grade_tank_type,
          "blend_ratio":form.value.blend_ratio,
          "is_sell_diesel":form.value.is_sell_diesel,
          "comm_per_volume":comm_per_volume,
          "tank_report_template":tank_report_template,
          "gas_supplier_paid_mode":form.value.gas_supplier_paid_mode,
          "store_bank_account_id":form.value.store_bank_account_id,
          "gas_supplier_settle_credit_card":form.value.gas_supplier_settle_credit_card,
          "is_having_gas":form.value.is_having_gas,
          "cc_grade_tank_type":form.value.cc_grade_tank_type,
          "is_gas_cashcard":form.value.is_gas_cashcard,
        }
      }
      
      
                      
    this.storeService.saveTankSetting(frmvalues, this.tankitemId)
      .then((response) => {
        this.toastrService.success('Tank Setting saved!', '');
      }).catch((error) => {
        this.toastrService.error('Error!', '');
      });
    
    }
    
  }
  enableTankSettingFields(tval){
    //alert(tval)
    if(tval == 0){
      this.issellgas = false;
    }
    if(tval == 1){
      this.issellgas = true;
      this.showAgentFields = false;
    }
    if(tval == 2){
      this.issellgas = true;
      this.showAgentFields = true;
    }
  }
}
