<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
    <div class="header-wrapper row m-0">
        <app-search></app-search>
        <div class="header-logo-wrapper col-3" style="flex: 0 0 20%;max-width: 20%;">
            <div class="logo-wrapper">
                <a routerLink='/dashboard'>
                    <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" style="height: 100px;">
                    <img class="img-fluid for-dark" src="assets/images/logo/logo.png" alt="">
                </a>
            </div>
            <div class="toggle-sidebar" (click)="sidebarToggle()">
                <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
            </div>
        </div>
        <div class="left-header col-6 horizontal-wrapper pl-0">
            <app-mega-menu></app-mega-menu>
        </div>
        <div class="nav-right col-offset-1 col-3 pull-right right-header p-0">
            <ul class="nav-menus">
                <!-- <li class="language-nav" (click)="languageToggle()">
                    <app-languages></app-languages>
                </li>
                <li>
                    <span class="header-search" (click)="searchToggle()">
                    <app-feather-icons [icon]="'search'"></app-feather-icons>
                  </span>
                </li> -->
                <!-- <li class="onhover-dropdown notify">
                    <app-notification></app-notification>
                </li> -->
                <!-- <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
          </div>
        </li>
        <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li>
        <li class="onhover-dropdown">
          <app-message-box></app-message-box>
        </li>
        <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li> -->
                <li class="profile-nav onhover-dropdown p-0">
                    <app-my-account></app-my-account>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Page Header Ends -->