import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { database } from 'firebase';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

class fuelRetailPrice{
  public old_cash_price:string;
  public new_cash_price1:string
  public old_credit_price:string
  public new_credit_price1:string
  public price_changes_date:string
}

@Component({
  selector: 'app-addfuelretailprice',
  templateUrl: './addfuelretailprice.component.html',
  styleUrls: ['./addfuelretailprice.component.scss']
})
export class AddFuelPriceComponent implements OnInit {
  @ViewChild("addfuelretailprice", { static: false }) addfuelretailprice: TemplateRef<any>;
  @Output() sendaddaddprice:EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelFuelPrice = new fuelRetailPrice();
  selected_id : string;
  public modelTitle: string;
  public itemId: string; 
  setreadonlycreditinp =false;
  setreadonlycashinp = false
  curr_cash_price = '';
  curr_credit_price = '';
  
  

  events: Event[] = [];
  selectedItems: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getFuelGradeById(this.selected_id).then((data)=>{
          this.modelFuelPrice=data;
          //this.modelFuelPrice.current_credit_price = ''
          //this.modelFuelPrice.current_cash_price = ''
          this.curr_cash_price = data.new_cash_price;
          this.curr_credit_price = data.new_credit_price;
          
        });
        this.modelTitle = "Edit Price";
        this.itemId = this.selected_id;
        
    }else{
      this.modelFuelPrice = new fuelRetailPrice();
      this.modelTitle = "Add Price";
      this.itemId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addfuelretailprice, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  
  savefuelprice(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    if(form.valid) {
      let frmvalues =   {
        "new_credit_price":form.value.new_credit_price1,
        "new_cash_price":form.value.new_cash_price1
      }
      this.storeService.saveFuelRetailPrice(frmvalues, this.itemId)
            .then((response) => {
              console.log(response);
              Swal.fire('Retail Price!', 'Retail Price Updated Successfully.', 'success');
              this.sendaddaddprice.emit(store_id);
              this.modalService.dismissAll();
            });
    }
  }

}
