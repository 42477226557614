import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddstocktransactionComponent } from './modal/addstocktransaction/addstocktransaction.component';
import { CONSTANTS } from 'src/app/util/constants';
import { Item } from 'src/app/shared/classes/store';
import * as _ from 'lodash';


export class Stocktransaction {
  public stock_UPC_code: string;
  public stock_transaction_type: string;
  public stock_to_store: string;
  public stock_quantity: string;
  public stock_modified_date: string;
  public stock_description: string;
  public stock_note: string;
}

@Component({
  selector: 'app-stock-transaction',
  templateUrl: './stock-transaction.component.html',
  styleUrls: ['./stock-transaction.component.scss']
})


export class StockTransactionComponent implements OnInit {

  @ViewChild("addstocktransaction") Addstocktransaction: AddstocktransactionComponent;
  itemModel = new Item();

  modelAddstocktransactionList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  storeList = [];

  stocktransactionList = []

  constructor(
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    var store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStocktransaction(store_id).then((data) => {
      this.modelAddstocktransactionList = data;
      this.stocktransactionList = this.modelAddstocktransactionList
      console.log(this.modelAddstocktransactionList)
    });
    this.storeService.getAllStores()
      .then((response) => {
        this.storeList = response;
      });
  }

  async reloadStocktransactionTable(event) {
    // console.log("test");
    this.modelAddstocktransactionList = await this.storeService.getStocktransaction(event);
  }

  removeStocktransaction(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeStocktransaction(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadStocktransactionTable(store_id);
          }
        });
    }
  }

  onSubmitAdvanceSubmit(e, form) {
    e.preventDefault();

    if (form.invalid) {
      return false;
    }

    if (!_.isEmpty(form.value.upc_code)) {
      this.modelAddstocktransactionList = _.filter(this.stocktransactionList, (item) => {
        return item.stock_UPC_code === form.value.upc_code
      })
    } else {
      this.modelAddstocktransactionList = this.stocktransactionList;

      if (!_.isEmpty(form.value.stock_modified_date)) {
        this.modelAddstocktransactionList = _.filter(this.stocktransactionList, (item) => {
          return item.stock_modified_date === form.value.stock_modified_date
        })
      }

      if (!_.isEmpty(form.value.stock_id)) {
        this.modelAddstocktransactionList = _.filter(this.modelAddstocktransactionList, (item) => {
          return item._id === form.value.stock_id
        })
      }

      if (!_.isEmpty(form.value.store_id)) {
        this.modelAddstocktransactionList = _.filter(this.modelAddstocktransactionList, (item) => {
          return item.store_id === form.value.store_id
        })
      }
    }
  }

}