import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddStoreManufacturerComponent } from './modal/addstoremanufacturer/addstoremanufacturer.component';
import { CONSTANTS } from 'src/app/util/constants';


export class StoreManufacturer{
  public store_id: string;
  public name: string;
}

@Component({
  selector: 'app-store-manufacturer',
  templateUrl: './store-manufacturer.component.html',
  styleUrls: ['./store-manufacturer.component.scss']
})
export class StoreManufacturerComponent implements OnInit {

  @ViewChild("addstoremanufacturer") AddStoreManufacturer: AddStoreManufacturerComponent;
  modelManufacturerList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelManufacturerList = await this.storeService.getManufacturers(data['_id']);
      }
    })
  }

  async reloadManufacturerTable(event){
    this.modelManufacturerList = await this.storeService.getManufacturers(event);
  }
  removeManufacturer(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeManufacturer(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadManufacturerTable(store_id);
          }
        });
    }
  }

}
