<ng-template #addbankaccount let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Bank Account</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #bankaccount="ngForm" (ngSubmit)="savebank(bankaccount)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Account name</label>
                    <input class="form-control" type="text" name="store_bank_account_name" [(ngModel)]="modelBankAccount.store_bank_account_name"  #store_bank_account_name="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_account_name.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Select account type</label>
                    <input class="form-control" type="text" name="store_bank_account_type" [(ngModel)]="modelBankAccount.store_bank_account_type" #store_bank_account_type="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_account_type.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Bank address</label>
                    <input class="form-control" type="text" name="store_bank_address" [(ngModel)]="modelBankAccount.store_bank_address" #store_bank_address="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_address.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Zip code</label>
                    <input class="form-control" type="text" name="store_bank_zip_code" [(ngModel)]="modelBankAccount.store_bank_zip_code" #store_bank_zip_code="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_zip_code.invalid }" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Bank branch</label>
                    <input class="form-control" type="text" name="store_bank_branch" [(ngModel)]="modelBankAccount.store_bank_branch" required>
                </div> -->
                <div class="form-group col-md-6">
                    <label>Routing Number</label>
                    <input class="form-control" type="text" name="store_bank_routing_number" [(ngModel)]="modelBankAccount.store_bank_routing_number" #store_bank_routing_number="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_routing_number.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Signature </label>
                    <input class="form-control" type="text" name="store_bank_signature" [(ngModel)]="modelBankAccount.store_bank_signature" #store_bank_signature="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_signature.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Logo </label>
                    <input class="form-control" type="file" name="store_bank_logo" [(ngModel)]="modelBankAccount.store_bank_logo" #store_bank_logo="ngModel"
                    [ngClass]="{ 'is-invalid': bankaccount.submitted && store_bank_logo.invalid }" required>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>