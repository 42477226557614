<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                  <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Fuel Retail Pricing</h4>
                        </div>
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <form class="needs-validation" #addfuelretailprice="ngForm" (ngSubmit)="savefuelretailprice(addfuelretailprice)">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Fuel grade </th>
                                                        <th>Current Credit Price </th>
                                                        <th>New Credit Price </th>
                                                        <th>Current Cash Price</th>
                                                        <th>New Cash Price</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let fuelgrade of modelFuelGradeList; index as i;"
                                                            ngModelGroup="fuelpriceitems">
                                                        <ng-container [ngModelGroup]="i">
                                                            <td>{{fuelgrade.store_fuel_grade_display_name}} 
                                                                <input [ngModel]="fuelgrade._id" name="fuel_grade_id" type="hidden">
                                                            </td>
                                                        
                                                        <td>
                                                            <span class="form-control" readonly>{{fuelgrade.new_credit_price}}</span>
                                                            <!-- <input name="current_credit_price"
                                                            [(ngModel)]="modelFuelPrice.new_credit_price" class="form-control" type="text" readonly> -->
                                                        </td>
                                                        <td><input name="new_credit_price" [(ngModel)]="new_credit_price_ctrl[i]"   class="form-control" type="text" >
                                                        </td>
                                                        <td>
                                                            <span class="form-control" readonly>{{fuelgrade.new_cash_price}}</span>
                                                            <!-- <input  name="current_cash_price" [(ngModel)]="modelFuelPrice.new_cash_price" type="text" class="form-control form-td" readonly> -->
                                                        </td>
                                                        <td><input  name="new_cash_price" [(ngModel)]="new_cash_price_ctrl[i]" type="text" class="form-control form-td" >
                                                        </td>
                                                        </ng-container>
                                                    </tr>
                                                    <tr>
                                                      <td colspan="6" class="text-right">
                                                        <button class="btn btn-primary mr-1" id="" type="submit">Save</button>
                                                      </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </form>

                                    </div>
                                    <!-- <div class="card-body custom-datatable noscroll">
                                        <ngx-datatable class="bootstrap"  [rows]="modelFuelGradeList" [loadingIndicator]="loadingIndicator"
                                            [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                            [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                            [reorderable]="reorderable" [limit]="15"  [selectAllRowsOnPage]="false" >
                                            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                                [draggable]="false" [resizeable]="false" >
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Fuel grade" prop="store_fuel_grade_display_name"></ngx-datatable-column>


                                            <ngx-datatable-column name="Current Credit Price" prop="new_credit_price" widht="50"></ngx-datatable-column>

                                            <ngx-datatable-column name="Current Cash Price"  prop="new_cash_price"></ngx-datatable-column>

                                            
                                            <ngx-datatable-column name="Action"  widht="50">
                                                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                                                    <div class="trlistBtn">
                                                        <button class="edit-btn" (click)="addfuelretailprice.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>

                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12" style="text-align: right; margin-bottom: 20px;">
                            <button class="btn btn-secondary mr-1" id="" type="submit">Save</button>
                        </div> -->
                    </div>
            </div>
        </div>

    </div>
</div>

<app-addfuelretailprice #addfuelretailprice (sendaddaddprice) = 'reloadTable($event)' ></app-addfuelretailprice>
