import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { NavService } from 'src/app/shared/services/nav.service';

export class Physicalinventory {
    public department: string;
    public item_id: string;
    public description: string;
    public store_id: string;
    public inventory_date: string;
    public buying_cost: string;
    public checked_by: string;
    public selling_price: string;
    public physical_inventory	: string;
    public current_inventory: string;
    public price_group_id: string;
  }

@Component({
  selector: 'app-addphysicalinventory',
  templateUrl: './addphysicalinventory.component.html',
  styleUrls: ['./addphysicalinventory.component.scss']
})
export class AddphysicalinventoryComponent implements OnInit {

  @ViewChild("addphysicalinventory", { static: false }) addphysicalinventory: TemplateRef<any>;
  @Output() sendaddphysicalinventory: EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelPhysicalinventory = new Physicalinventory();
  selected_id : string;
  public modelTitle: string;
  public invryId: string; 
  departmentList = [];
  itemrow = [];
  itemrowfound = false;
  IsmodelShow:any;
  searchstoreitems = [];
  removedItemIds = [];
  itmcode = false;
  pricegrp = false;
  pricegroups = [];
  grupitemsfound = false;
  grupitems = [];
  

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService,
    public navServices:NavService
    ) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.departmentList = await this.storeService.getStoreDepartment(data['_id']);
      }
    })
  }

   openModal(row_id?:null) {
    this.itemrow = [];
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getPriceGroup(store_id).then((data)=>{this.pricegroups=data;});
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getphysicalinventoryById(this.selected_id).then((data)=>{
          this.modelPhysicalinventory=data;

          if(this.modelPhysicalinventory.item_id != null){
            this.grupitemsfound = false;
             this.storeService.getItemById(this.modelPhysicalinventory.item_id).then((data)=>{
              this.itemrow = data;
              this.itemrowfound = true;
            })
          }
          if(this.modelPhysicalinventory.price_group_id != null){
            this.itemrowfound = false;
            this.grupitems = [];
             this.storeService.getPriceGroupById(this.modelPhysicalinventory.price_group_id).then((data)=>{
              this.grupitems=data.items;
              if(this.grupitems.length > 0){
                this.grupitemsfound = true;
              }
            });
          }

          
        });
        this.modelTitle = "Edit";
        this.invryId = this.selected_id;
    }else{
      this.modelPhysicalinventory = new Physicalinventory();
      this.modelTitle = "Add";
      this.invryId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addphysicalinventory, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  showIemCodeSection(){
    this.itmcode = true;
    this.pricegrp = false;
    this.grupitemsfound = false;
    this.grupitems = [];
  }
  showPriceGrpSection(){
    this.itemrowfound = false;
    this.itemrow = [];
    this.pricegrp = true;
    this.itmcode = false;
  }
  getGroupItems(ev){
    this.grupitems = [];
    this.storeService.getPriceGroupById(ev.target.value).then((data)=>{
      this.grupitems=data.items;
      if(this.grupitems.length > 0){
        this.grupitemsfound = true;
      }
      
      console.log("grupitems",this.grupitems);
    });
  }
  getItemDetail($event){
    if($event !== undefined){
      this.storeService.getItemById($event).then((data)=>{
        console.log(data);
        if(data.upc_code !=''){
          this.itemrow = data;
          this.itemrowfound = true;
        }else{
          this.itemrowfound = false;
        }
        
      }).catch((error) => {
        this.itemrowfound = false;
      });
    }else{
      this.itemrow = [];
    this.itemrowfound = false;
    }
    
    
  }
  onSearchByUpc($event) {
      if($event.target.value !='')
      this.storeService.getStoreItemListByUPC($event.target.value).then((data)=>{
        console.log(data);
        if(data.upc_code !=''){
          this.modelPhysicalinventory.item_id = data._id;
          this.itemrow = data;
          this.itemrowfound = true;
        }else{
          Swal.fire('Item Search', 'Item not found!.', 'error');
          this.modelPhysicalinventory.item_id = '';
          this.itemrowfound = false;
        }
        
      }).catch((error) => {
        Swal.fire('Item Search', 'Item not found!.', 'error');
        this.modelPhysicalinventory.item_id = '';
        this.itemrowfound = false;
      });
    
    
  }
  onSearch($event) {
    if($event.term !=''){
      this.storeService.getStoreItemListOnSearch($event.term).then((data)=>{
        console.log(data);
        this.searchstoreitems = data;
      });
    }else{
      this.searchstoreitems = [];
    }
    
  }
  onRemove(event: any) {
    this.removedItemIds.push(event.value.id);
  }
  

  savePhysicalinventory(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    let price_group_id;
    let item_id;
   if(form.valid) { 
    if(form.value.buydownfor == 'PriceGroup'){
      price_group_id = form.value.price_group_id;
      item_id = null;
     }else{
       price_group_id = null;
       item_id = this.modelPhysicalinventory.item_id
     }
     let frmvalues =   {
       "store_id":store_id,
       "price_group_id":price_group_id,
       "item_id":item_id,
       "department":form.value.department,
       "description":form.value.description,
       "inventory_date":form.value.inventory_date,
       "buying_cost":form.value.buying_cost,
       "checked_by":form.value.checked_by,
       "selling_price":form.value.selling_price,
       "current_inventory":form.value.current_inventory,
       "physical_inventory":form.value.physical_inventory
     }
    this.storeService.savePhysicalinventory(frmvalues, this.invryId)
          .then((response) => {
             console.log(response);
             Swal.fire('Physical Inventory!', 'Physical Inventory Save Successfully.', 'success');
             this.sendaddphysicalinventory.emit(store_id);
             this.modalService.dismissAll();
          });
    }
  }


}
