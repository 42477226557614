import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import { NavService } from 'src/app/shared/services/nav.service';
import Swal from 'sweetalert2';


export class DepartmentMapping {
  type_obj_id: string
  type: string
  department_name: string
  register_id: string
  display_name: string
}

@Component({
  selector: 'app-add-departmentregistermapping',
  templateUrl: './add-departmentregistermapping.component.html',
  styleUrls: ['./add-departmentregistermapping.component.scss']
})
export class AddDepartmentregistermappingComponent implements OnInit {

  @ViewChild("adddepartmentregistermapping", { static: false }) departmentmapping: TemplateRef<any>;
  @Output() sendaddeddepmapping: EventEmitter<string> = new EventEmitter<string>();

  public modalOpen: boolean = false;
  public closeResult: string;
  selected_id: string;
  department: any;
  modelTitle: string;
  departmentMapping = new DepartmentMapping();
  map_selected_id = null;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public navServices: NavService,
    public storeService: StoreService) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.modelTitle = "Department Register Mapping"
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
      }
    })
  }

  openModal(row_id?: null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));

    this.selected_id = row_id;
    
    if (row_id) {
      this.storeService.getDepartmentById(this.selected_id).then((response) => {
        this.department = response
        this.departmentMapping.type_obj_id = this.department._id
        this.departmentMapping.department_name = this.department.department_name
        this.departmentMapping.type = "Inside Sales";
        if(this.department.regmapping !=null){
          this.map_selected_id = this.department.regmapping._id;
          this.departmentMapping.display_name = this.department.regmapping.display_name;
          this.departmentMapping.register_id = this.department.regmapping.register_id;
        }else{
          this.map_selected_id = null;
          this.departmentMapping.display_name = '';
          this.departmentMapping.register_id = '';
        }
      })
    }


    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.departmentmapping, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveDepartmentData(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    if (!form.invalid) {
      this.storeService.saveregistermapping(this.departmentMapping, this.map_selected_id)
        .then((response) => {
          console.log("Response: " + response)
          if(response){
            Swal.fire('Register Mapping!', 'Reigster Mapping Added Successfully.', 'success');
            this.sendaddeddepmapping.emit(store_id);
            this.modalService.dismissAll();
          }else{
            Swal.fire('Register Mapping!', 'Reigster Mapping Added Failed.', 'error');
          }
        })
    }
    return false
  }
}
