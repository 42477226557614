import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CONSTANTS } from 'src/app/util/constants';

@Component({
  selector: 'app-store-role-management',
  templateUrl: './store-role-management.component.html',
  styleUrls: ['./store-role-management.component.scss']
})
export class StoreRoleManagementComponent implements OnInit {
  modelRoleList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelRoleList = await this.storeService.getStoreRoles(data['_id']);
      }
    })
    
  }
  async reloadRoleTable(event){
    this.modelRoleList = await this.storeService.getStoreRoles(event);
  }
  

}
