import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class Manufacturer {
  public store_id: string;
  public name: string;
  public account_numner: string;
}

@Component({
  selector: 'app-addstoremanufacturer',
  templateUrl: './addstoremanufacturer.component.html',
  styleUrls: ['./addstoremanufacturer.component.scss']
})
export class AddStoreManufacturerComponent implements OnInit {
  @ViewChild("addstoremanufacturer", { static: false }) addstoremanufacturer: TemplateRef<any>;
  @Output() sendaddedmanufaturer:EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelManufacturer = new Manufacturer();
  selected_id : string;
  public modelTitle: string;
  public itemId: string; 
  
  

  events: Event[] = [];
  selectedItems: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getManufacturer(this.selected_id).then((data)=>{this.modelManufacturer=data;});
        this.modelTitle = "Edit Manufacturer";
        this.itemId = this.selected_id;
    }else{
      this.modelManufacturer = new Manufacturer();
      this.modelTitle = "Add Manufacturer";
      this.itemId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addstoremanufacturer, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  savemanufacturer(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    
   if(form.valid) {
    this.storeService.saveManufacturer(form.value, this.itemId)
          .then((response) => {
             console.log(response);
             Swal.fire('Manufacturer!', 'Manufacturer Save Successfully.', 'success');
             this.sendaddedmanufaturer.emit(store_id);
             this.modalService.dismissAll();
          });
    }
  }

}
