import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddUserComponent } from './modal/adduser/adduser.component';
import { CONSTANTS } from 'src/app/util/constants';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  @ViewChild("adduser") AddUser: AddUserComponent;
  modelUserList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  userstores = [];
  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

    ngOnInit(): void {
      this.navServices.storeName.subscribe(async (data) => {
        if (data['_id'] != undefined) {
          localStorage.setItem('store_id', JSON.stringify(data['_id']));
          
          this.modelUserList = await this.storeService.getUsers(data['_id']);
        }
      })

      
      
    }
  async reloadUserTable(event){
    this.modelUserList = await this.storeService.getUsers(event);
  }
  

  searchUser(form){
    console.log("Form: >>"+JSON.stringify(form.value))
    if(!form.invalid){
      let value = form.value.searchvalue
      this.storeService.getUserBySearchValue(value).then((response)=>{
        if(Array.isArray(response)){
          this.modelUserList = [...response]
        }else{
          this.modelUserList = [response]
        }
        form.reset()
      }).catch((err)=>{
        console.log(err)
      })
    }
  }

  removeuser(id:string){
    let store_id = JSON.parse(localStorage.getItem('store_id'));

    if(confirm("Do you want to delete user?")){
      this.storeService.removeUser(id).then(async (response)=>{
        if(response){
          Swal.fire('User!', 'User Deleted Successfully.', 'success');
          this.modelUserList = await this.storeService.getUsers(store_id['_id']);
        }
      })
    }
  
  }
}
