import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as firebase from 'firebase/app';
import { HttpService } from '../http.service';
import { CONSTANTS } from 'src/app/util/constants';
// import { SessionService } from './session.service';
import { StoreService } from 'src/app/services/store.service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public user: firebase.User;
  public showLoader: boolean = false;
  userroleidsArr = [];

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    // private sessionServ: SessionService,
    private httpServ: HttpService,
    private constants: CONSTANTS,
    public storeService: StoreService) {

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     cookieService.set('user', JSON.stringify(this.userData));
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null);
    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // });
  }

  ngOnInit(): void { }

  // sign in function
  SignIn(email, password) {
    let requestObject = {
          REQUEST_DATA:  {
            username: email,
            password: password
          },
          API_URL: this.constants.API_URLS.LOGIN,
          REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
      };
            
          // REQUES
    return this.httpServ.sendRequest(requestObject)
      .then((result) => {
        //console.log(result._id);
        if (result.emailVerified !== true) {
          
          // this.SetUserData(result);
          this.cookieService.set('user', JSON.stringify(result));
          localStorage.setItem('user', JSON.stringify(result));
          // JSON.parse(localStorage.getItem('user'));
          // this.SendVerificationMail();
          
          // this.showLoader = false;
          this.router.navigate(['/dashboard']);
        } else {
           this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/auth/login']);
          });
        }
      }).catch((error) => {
        // this.showLoader = false;
        this.toster.error('You have enter Wrong Email or Password.');
      })
    
  }

  StoreUserSignIn(email, password) {
    let requestObject = {
          REQUEST_DATA:  {
            username: email,
            password: password
          },
          API_URL: this.constants.API_URLS.STOREUSERLOGIN,
          REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
      };
            
          // REQUES
    return this.httpServ.sendRequest(requestObject)
      .then((result) => {
        //console.log(result._id);
        if (result.emailVerified !== true) {
          
          // this.SetUserData(result);
          this.cookieService.set('user', JSON.stringify(result));
          localStorage.setItem('user', JSON.stringify(result));
          // JSON.parse(localStorage.getItem('user'));
          // this.SendVerificationMail();
          
          // this.showLoader = false;
          this.router.navigate(['/dashboard']);
        } else {
           this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/auth/login']);
          });
        }
      }).catch((error) => {
        // this.showLoader = false;
        this.toster.error('You have enter Wrong Email or Password.');
      })
    
  }

  // main verification function
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['/dashboard/default']);
      })
  }

 
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

 
  // Set user
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () {})
          .catch(function (error) {});
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  signOut(user) {
    let requestObject = {
      API_URL: this.constants.API_URLS.LOGOUT,
      REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
    };

    return this.httpServ.sendRequest(requestObject);
  }

  SignUp(firstname, lastname, companyname, email, password) {
    let requestObject = {
      REQUEST_DATA:  {
        firstname: firstname,
        lastname: lastname,
        companyname: companyname,
        email: email,
        username: email,
        password: password
      },
      API_URL: this.constants.API_URLS.REGISTER,
      REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
  };
        
      // REQUES
return this.httpServ.sendRequest(requestObject)
  .then((result) => {
    //console.log(result._id);
    if (result.emailVerified !== true) {
      
      this.cookieService.set('user', JSON.stringify(result));
      localStorage.setItem('user', JSON.stringify(result));
      // JSON.parse(localStorage.getItem('user'));
      // this.SendVerificationMail();
      
      // this.showLoader = false;
      this.router.navigate(['/dashboard']);
    } else {
       this.showLoader = false;
      this.ngZone.run(() => {
        this.router.navigate(['/auth/login']);
      });
    }
  }).catch((error) => {
    // this.showLoader = false;
    this.toster.error('You have enter Wrong Email or Password.');
  })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

  ForgotAdminPassword(data) {

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.FORGOTADMINPASSWORD,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);

        
  }
  resetAdminPassword(data) {

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.RESETADMINPASSWORD,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);

        
  }

  ForgotStoreUserPassword(data) {

    let requestObject = {
        REQUEST_DATA: data,
        API_URL: this.constants.API_URLS.STOREUSERFORGOTPASSWORD,
        REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
    };

    return this.httpServ.sendRequest(requestObject);
 }

 resetStoreUserPassword(data) {

  let requestObject = {
      REQUEST_DATA: data,
      API_URL: this.constants.API_URLS.RESETSTOREUSERPASSWORD,
      REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
  };

  return this.httpServ.sendRequest(requestObject);

  
}


}