<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink='/'>
                            <img class="img-fluid for-light" src="assets/images/logo/login-logo.png" alt="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main" *ngIf="!otpsent">
                        <form class="theme-form" [formGroup]="resetpasswordForm">
                            <h4>Reset Your Password</h4>
                            <div class="form-group">
                                <label class="col-form-label">Enter Your Email Address</label>
                                
                                <input class="form-control" type="email" required="" placeholder="test@gmail.com" formControlName="email">
                                <div *ngIf="resetpasswordForm.controls.email.touched && resetpasswordForm.controls.email.errors?.required" class="text text-danger mt-1">
                                    Email is required
                                </div>
                                <div *ngIf="resetpasswordForm.controls.email.touched && resetpasswordForm.controls.email.errors?.email" class="text text-danger mt-1">
                                    Invalid Email
                                </div>
                            </div>
                            
                            <div class="form-group mb-0">
                                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader" [disabled]="!resetpasswordForm.valid || authService.showLoader"  (click)="resetpassword()"  type="button"><span>{{ authService.showLoader ? '' : 'Send' }}</span></button>

                            </div>
                            
                        </form>
                    </div>
                    <div class="login-main" *ngIf="otpsent">
                        <form class="theme-form" [formGroup]="changepasswordForm">
                            <h4>Set New Password</h4>
                            <p>Enter your OTP</p>
                            <div class="form-group">
                                <label class="col-form-label">OTP</label>
                                
                                <input class="form-control" type="text" required=""  formControlName="otp">
                                <div *ngIf="changepasswordForm.controls.otp.touched && changepasswordForm.controls.otp.errors?.required" class="text text-danger mt-1">
                                    OTP is required
                                </div>
                                <div *ngIf="changepasswordForm.controls.otp.touched && changepasswordForm.controls.otp.errors?.otp" class="text text-danger mt-1">
                                    Invalid OTP
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">New Password</label>
                                
                                <input class="form-control" type="text" required="" placeholder="New Password" formControlName="newPassword">
                                <div *ngIf="changepasswordForm.controls.newPassword.touched && changepasswordForm.controls.newPassword.errors?.required" class="text text-danger mt-1">
                                   Password is required
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Confirm Password</label>
                                <input class="form-control" type="text" required="" placeholder="Confirm Password" formControlName="confirmPassword">
                                <div *ngIf="changepasswordForm.controls.confirmPassword.touched && changepasswordForm.controls.confirmPassword != changepasswordForm.controls.newPassword" class="text text-danger mt-1">
                                    Password Do Not Matched
                                </div>
                            </div>
                            
                            <div class="form-group mb-0">
                                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader" [disabled]="!changepasswordForm.valid || authService.showLoader"   (click)="validateotp()"  type="button"><span>{{ authService.showLoader ? '' : 'Save' }}</span></button>
                                
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>