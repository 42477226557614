<ng-template #addstoremanufacturer let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addstoremanufacturer="ngForm" (ngSubmit)="savemanufacturer(addstoremanufacturer)">
            <div class="form-row">
                        

                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Name </label>
                                <input type="text" class="form-control" name="name" [(ngModel)]="modelManufacturer.name"  #name="ngModel"
                                [ngClass]="{ 'is-invalid': addstoremanufacturer.submitted && name.invalid }" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Account Number</label>
                                <input type="text" class="form-control" name="account_numner" [(ngModel)]="modelManufacturer.account_numner"  #account_numner="ngModel"
                                [ngClass]="{ 'is-invalid': addstoremanufacturer.submitted && account_numner.invalid }" required>
                            </div>
                        </div>
                        
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-secondary mr-1" >Save</button>
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>