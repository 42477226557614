import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class StoreFuelGrade {
  public store_fuel_grade: string;
  public store_fuel_department: string;
  public store_fuel_tax: string;
  public store_fuel_grade_id: string;
  public store_fuel_grade_display_name: string;
}


@Component({
  selector: 'app-addfuelgrade',
  templateUrl: './addfuelgrade.component.html',
  styleUrls: ['./addfuelgrade.component.scss']
})
export class AddfuelgradeComponent implements OnInit {

  @ViewChild("addfuelgrade", { static: false }) addfuelgrade: TemplateRef<any>;
  @Output() sendaddfuelgrade: EventEmitter<string> = new EventEmitter<string>();
 
  public closeResult: string;
  public modalOpen: boolean = false;
  modelStoreFuelGrade = new StoreFuelGrade();
  selected_id : string;
  public modelTitle: string;
  public fuelId: string; 
  public isblend = false;
  fueltaxlist = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreFueltaxList(store_id).then((data)=>{
      this.fueltaxlist = data;
      console.log("fueltaxlist",this.fueltaxlist);
      
    });

    this.isblend = false;
    this.selected_id= row_id;
    if(this.selected_id != null){
        
        this.storeService.getFuelGradeById(this.selected_id).then((data)=>{
          this.modelStoreFuelGrade=data;
          console.log("data",data);
          
          if(data.is_blend == true){
            this.isblend = true;
          }
        });
        this.modelTitle = "Edit";
        this.fuelId = this.selected_id;
        
    }else{
      this.modelStoreFuelGrade = new StoreFuelGrade();
      this.modelTitle = "Add";
      this.fuelId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addfuelgrade, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  saveFuelGrade(form) {
  let store_id = JSON.parse(localStorage.getItem('store_id'));
   if(form.valid) { 
    this.storeService.saveFuelGrade(form.value, this.fuelId)
          .then((response) => {
             console.log(response);
             Swal.fire('Fuel Grade!', 'Fuel Grade Save Successfully.', 'success');
             this.sendaddfuelgrade.emit(store_id);
             this.modalService.dismissAll();
             });
    }
  }
  enableratiofields(evt){
    if(evt.target.checked){
      this.isblend = true;
    }
    if(!evt.target.checked){
      this.isblend = false;
    }
  }


}
