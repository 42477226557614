import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class StorePaymentmethod {
  public store_mop_no: string;
  public store_mop_name: string;
  public store_system_mop: string;
}


@Component({
  selector: 'app-addpaymentmethod',
  templateUrl: './addpaymentmethod.component.html',
  styleUrls: ['./addpaymentmethod.component.scss']
})
export class AddpaymentmethodComponent implements OnInit {

  @ViewChild("addpaymentmethod", { static: false }) addpaymentmethod: TemplateRef<any>;
  @Output() sendaddpaymentmethod: EventEmitter<string> = new EventEmitter<string>();
 
  public closeResult: string;
  public modalOpen: boolean = false;
  modelPaymentmethod = new StorePaymentmethod();
  selected_id : string;
  public modelTitle: string;
  public paymentId: string; 


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getPaymentById(this.selected_id).then((data)=>{this.modelPaymentmethod=data;});
        this.modelTitle = "Edit";
        this.paymentId = this.selected_id;
    }else{
      this.modelPaymentmethod = new StorePaymentmethod();
      this.modelTitle = "Add";
      this.paymentId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addpaymentmethod, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  savePaymentmethod(form) {
  let store_id = JSON.parse(localStorage.getItem('store_id'));
   if(form.valid) { 
    this.storeService.savePaymentmethod(form.value, this.paymentId)
          .then((response) => {
             console.log(response);
             Swal.fire('Payment Method!', 'Payment Method Save Successfully.', 'success');
             this.sendaddpaymentmethod.emit(store_id);
             this.modalService.dismissAll();
             });
    }
  }


}
