<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink='/'>
                            <img class="img-fluid for-light" src="assets/images/logo/login-logo.png" alt="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main">
                        <!-- <form class="theme-form" [formGroup]="loginForm">
                            <h4>Sign in to account</h4>
                            <p>Enter your email & password to login</p>
                            <div class="form-group">
                                <label class="col-form-label">Email Address</label>
                                <input class="form-control" type="email" required="" placeholder="email" formControlName="email">
                                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                                    Email is required
                                </div>
                                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">
                                    Invalid Email
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Password</label>
                                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                                    Password is required
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox">
                                    <label class="text-muted" for="checkbox1">Remember password</label>
                                </div>
                                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>
                                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader" [disabled]="!loginForm.valid || authService.showLoader" (click)="login()" type="button"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
                </button>
                            </div>
                            
                            
                        </form> -->
                        <form class="theme-form" [formGroup]="registerForm">
                            <h4>Create your account</h4>
                            <p>Enter your personal details to create store account</p>
                            <div class="form-group">
                              <label class="col-form-label pt-0">Your Name</label>
                              <div class="row g-2">
                                <div class="col-6">
                                  <input class="form-control" type="text" required="" formControlName="firstname" placeholder="First name">
                                </div>
                                <div class="col-6">
                                  <input class="form-control" type="text" required="" formControlName="lastname" placeholder="Last name">
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Company Name</label>
                                <input class="form-control" type="text" required="" formControlName="companyname" placeholder="Company Name">
                              </div>
                            <div class="form-group">
                              <label class="col-form-label">Email Address</label>
                              <input class="form-control" type="email" required="" formControlName="email" placeholder="Test@gmail.com">
                            </div>
                            <div class="form-group">
                              <label class="col-form-label">Password</label>
                              <div class="form-input position-relative">
                                <input class="form-control" type="password" formControlName="password" required="" placeholder="*********">
                              </div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Confirm Password</label>
                                <div class="form-input position-relative">
                                  <input class="form-control" type="password" formControlName="confirmpassword" required="" placeholder="*********">
                                </div>
                              </div>
                            <div class="form-group mb-0">
                                <button class="btn btn-primary btn-block" [class.loader--text]="authService.showLoader" [disabled]="!registerForm.valid || authService.showLoader" (click)="register()" type="button"><span>{{ authService.showLoader ? '' : 'Register' }}</span>
                                </button>
                                <!-- <button class="btn btn-primary btn-block w-100" type="submit">Create Account</button> -->
                              </div>
                            <p class="mt-4 mb-0">Already have an account?<a class="ms-2" href="/">Sign in</a></p>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
