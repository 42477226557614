import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BuyDownManagersRoutingModule } from './buy-down-managers-routing.module';
import { BuyDownManagersComponent } from './buy-down-managers.component';
import { FormsModule } from '@angular/forms';
import { AddbuydownmanagersComponent } from './addbuydownmanagers/addbuydownmanagers.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    BuyDownManagersComponent,
    AddbuydownmanagersComponent
  ],
  imports: [
    CommonModule,
    BuyDownManagersRoutingModule,
    FormsModule,
    NgxDatatableModule,
    NgSelectModule
  ]
})
export class BuyDownManagersModule { }


