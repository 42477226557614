import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StoreManufacturerRoutingModule } from './store-manufacturer-routing.module';
import { StoreManufacturerComponent } from './store-manufacturer.component';
import { FormsModule } from '@angular/forms';
import { AddStoreManufacturerComponent } from './modal/addstoremanufacturer/addstoremanufacturer.component';

@NgModule({
  declarations: [
    StoreManufacturerComponent,
    AddStoreManufacturerComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    StoreManufacturerRoutingModule,
    NgxDatatableModule
  ]
})
export class StoreManufacturerModule { }
