<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 xl-100 box-col-12">
            <div class="mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Tax setup</h4>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card">
                                <div class="card-header calender-btn">
                                    <h5>
                                        <button class="btn btn-primary pull-right" (click)="addtaxsetup.openModal();$event.preventDefault()">Add new </button>
                                        <div class="clearfix"></div>
                                    </h5>
                                </div>
                                <!-- <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Tax rate </th>
                                                    <th>Tax strategy Id</th>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn">Edit</button>
                                                            <button class="delete-btn">Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                                <div class="card-body custom-datatable noscroll">
                                    <ngx-datatable class="bootstrap"  [rows]="modeltaxsetupList" [loadingIndicator]="loadingIndicator"
                                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                        [reorderable]="reorderable" >
                                        <ngx-datatable-column name="Tax strategy rate" prop="store_tax_rate"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Tax strategy Id" prop="store_tax_strategy_id"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Description" prop="store_tax_description"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Action" widht="600">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addtaxsetup.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                    <button class="delete-btn" (click)="removetaxsetup(row._id)"><i class="fa fa-trash"></i></button>
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItemHeading">Tax Strategy</h4>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Tax strategy id</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Description</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Tax strategy id for retailix</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div> -->
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card">
                                <div class="card-header calender-btn">
                                    <h5>
                                        <button class="btn btn-primary pull-right" (click)="addtaxstrategy.openModal();$event.preventDefault()" >+ Add new record</button>
                                        <div class="clearfix"></div>
                                    </h5>
                                </div>
                                <!-- <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Tax rate </th>
                                                    <th>Commander tax id</th>
                                                    <th>Commands</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn">Edit</button>
                                                            <button class="delete-btn">Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                                <div class="card-body custom-datatable noscroll">
                                    <ngx-datatable class="bootstrap"  [rows]="modeltaxstrategyList" [loadingIndicator]="loadingIndicator"
                                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                        [reorderable]="reorderable" >
                                        <ngx-datatable-column name="Tax strategy rate" prop="store_tax_strategy_rate"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Commander tax id" prop="store_tax_commander_tax_id"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Commands" prop="store_tax_commands"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Action" widht="600">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addtaxstrategy.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                    <button class="delete-btn" (click)="removetaxstrategy(row._id)"><i class="fa fa-trash"></i></button>
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="updatebtn">
                        <button>Save Settings</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<app-addtaxsetup (sendaddtaxsetup) = 'reloadtaxsetupTable($event)' #addtaxsetup></app-addtaxsetup>
<app-addtaxstrategy (sendaddstrategy) = 'reloadtaxstrategyTable($event)' #addtaxstrategy></app-addtaxstrategy>
