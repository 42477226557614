import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CONSTANTS } from 'src/app/util/constants';
import { AddbulkupdateComponent } from './addbulkupdate/addbulkupdate.component';
import { Item } from 'src/app/shared/classes/store';
import * as _ from 'lodash';

export class Bulkupdate {
  public store_name: string;
  public manufacturer_name: string;
  public item_code: string;
  public item_name: string;
  public discount_given_by_manufacturer: string;
  public actual_price_of_item: string;
  public from_date: string;
  public to_date: string;
  public description: string;
}

@Component({
  selector: 'app-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {

  @ViewChild("addbulkupdate") AddBulkUpdate: AddbulkupdateComponent;
  modelAddBulkupdateList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  itemModel = new Item();
  public company = [];
  public selected = [];
  public itemList = [];
  public departmentList = [];
  public storeItemList = []
  storeVendorList = [];
  pricegroupList = [];

  updatedItemList = []


  min_price = 0
  max_price = 0

  constructor(
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreDepartment(store_id).then((data) => { this.departmentList = data; });
    this.storeService.getVendor(store_id)
      .then((response) => {
        console.log(response);
        this.storeVendorList = response;
      });
    this.storeService.getPriceGroup(store_id).then((data) => { this.pricegroupList = data; });
    // this.navServices.storeName.subscribe(async (data) => {
    //   if (data['_id'] != undefined) {
    //     localStorage.setItem('store_id', JSON.stringify(data['_id']));
    //     this.itemList = await this.storeService.getStoreItem(data['_id']);
    //   }
    // })

  }

  onSubmitAdvanceSearch(e, form: any) {
    e.preventDefault();
    if (form.invalid) {
      return false
    }

    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreDepartment(store_id).then((data) => { this.departmentList = data; });
    this.storeService.getVendor(store_id)
      .then((response) => {
        console.log(response);
        this.storeVendorList = response;
      });

    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.storeItemList = await this.storeService.getStoreItem(data['_id']);
      }

      this.itemList = []
      // if upc_code is provided
      if (!_.isEmpty(form.value.upc_code)) {
        this.storeService.advanceSearchItem(this.itemModel).then((response) => {
          this.itemList = response
        })
      }else {       
        // filter itemList
        this.itemList = _.filter(this.storeItemList, (item) => {
          return item.department === form.value.department
        })

        // this.itemList = _.filter(this.itemList, (item)=>{
        //   return item.price_group === form.value.price_group
        // })
        if (this.max_price > 0) {
          this.itemList = _.filter(this.itemList, (item) => {
            return item.case_cost > this.min_price && item.case_cost < this.max_price
          })
        }
        if (parseInt(form.value.modifier)) {
          this.itemList = _.slice(this.itemList, 0, parseInt(form.value.modifier))
        }

        console.log(this.itemList)

      }
    })
  }


  onChangeStoreItemData(row: any, name: string, value: any) {
    if (name === "food_stamp_sales") {
      value = value === "true";
    }
    if (this.updatedItemList.includes(row)) {
      const index = this.updatedItemList.indexOf(row)
      this.updatedItemList[index][name] = value
    }
    else {
      row[name] = value
      this.updatedItemList.push(row)
    }
    console.log(this.updatedItemList)
  }

  onSubmitSaveSettingItemData() {
    if (this.updatedItemList.length !== 0 && confirm("Do you want to update items?")) {
      this.updatedItemList.forEach((item) => {
        console.log("item==> " + JSON.stringify(item))
        console.log("item id==> " + item._id)

        this.storeService.saveItem(item, item._id).then((response)=>{
          console.log(response)
        })
      })
      Swal.fire('Item Update!', 'Item updated Successfully.', 'success');

    }
  }
}
