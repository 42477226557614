<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Buy Down Managers</h4>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <div class="job-filter">
                                    <div class="faq-form">
                                        <input class="form-control" type="text" placeholder="Search.." value="" #search (change)="onClickSearch(search.value)">
                                        <i class="icon-search"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div class="addupdatebtn">
                                <button (click)="addbuydownmanagers.openModal();$event.preventDefault()">+ Add Buy Down Manager</button>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card-body custom-datatable noscroll">
                                <ngx-datatable class="bootstrap"  [rows]="modelAddBuydownmanagerList" [loadingIndicator]="loadingIndicator"
                                    [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                    [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                    [reorderable]="reorderable" >
                                    <ngx-datatable-column name="Store Name" prop="store.store_full_name"></ngx-datatable-column> 
                                    <ngx-datatable-column name="Manufacturer Name" prop="manufacturer.name"></ngx-datatable-column> 
                                    
                                    <ngx-datatable-column name="Item/Group" widht="600">    
                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template> 
                                            <div *ngIf="row.storeitem !=null">
                                                {{row.storeitem.item_description}} ({{row.storeitem.upc_code}})
                                            </div>
                                            <div *ngIf="row.price_group_id !=null">
                                                {{row.pricegroup.name}}
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Discount" prop="discount_given_by_manufacturer"></ngx-datatable-column> 
                                    <ngx-datatable-column name="From Date" prop="from_date"></ngx-datatable-column> 
                                    <ngx-datatable-column name="To Date" prop="to_date"></ngx-datatable-column> 
                                    <ngx-datatable-column name="Action" widht="600">    
                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                            <div class="trlistBtn">
                                                <button class="edit-btn" (click)="addbuydownmanagers.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                <button class="delete-btn" (click)="removeBuydownmanager(row._id)"><i class="fa fa-trash"></i></button>
                                            </div>   
                                        </ng-template>    
                                    </ngx-datatable-column> 
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="updatebtn">
                        <!-- <button class="back">Back</button> -->
                        <!-- <button>Save Settings</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-addbuydownmanagers (sendaddbuydownmanager) = 'reloadBuydownmanagerTable($event)' #addbuydownmanagers ></app-addbuydownmanagers>
<app-addbuydownmanagers (sendaddbuydownmanager) = 'reloadBuydownmanagerTable($event)' #addmanufacturer></app-addbuydownmanagers>