import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CONSTANTS } from 'src/app/util/constants';
import { ActivatedRoute } from '@angular/router';
import { JSONOPTIONS } from 'src/app/util/jsonoptions';
import { Item } from 'src/app/shared/classes/store';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

//import { VendorInvoice } from '../vendor-invoice/vendor-invoice.component';
export class VendorInvoice {
  public default_margin: string;
  public case_cost: string;
  public retail: string;
  public margin: string;
  public department_id: string;
}


@Component({
  selector: 'app-add-vendor-invoice-item',
  templateUrl: './add-vendor-invoice-item.component.html',
  styleUrls: ['./add-vendor-invoice-item.component.scss']
})
export class AddVendorInvoiceItemComponent implements OnInit {
  private subscriptionName: Subscription;
  itemModel = new Item();
  itemId = null;
  vitmdata = [];
  vinvoiceitemList = [];
  public v_invoice_id = '';
  itemData:any;
  isshowdocument: boolean;


  //modelVendorInvoice = [];
  modelVendorInvoice = new VendorInvoice();
  departmentList = [];
  categoryList = [];
  pricegroupList = [];
  isShow1 = true;
  isShow2 = true;
  isShow3 = true;
  isShow4 = true;
  isShowAddBtn = true;
  tab1entry = '';
  tab2entry = '';
  tab4entry = '';
  salestypes = [];
  vinfo = [];
  existingItmUpc: '';
  itemnotformmsg: any;
  searcheditemid = null;
  searcheditemData: any;
  files: any[];
  fileurl:any;
  

  constructor(public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public jsonoptions: JSONOPTIONS) {
    this.subscriptionName = this.storeService.getUpdatedVendorInvoiceItem().subscribe
      (data => {
        this.storeService.getVendorInvoiceItemList(this.v_invoice_id).then((data) => { this.vinvoiceitemList = data; });
      });
  }

  ngOnInit(): void {

    this.salestypes = this.jsonoptions.SALETYPE;
    this.v_invoice_id = this.route.snapshot.paramMap.get('id');

    this.storeService.getVendorInvoiceById(this.v_invoice_id).then((data) => {
      this.modelVendorInvoice = data;
      this.files = this.modelVendorInvoice["files"]
    });
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreDepartment(store_id).then((data) => { this.departmentList = data; });
    this.storeService.getItemCategory(store_id).then((data) => { this.categoryList = data; });
    this.storeService.getPriceGroup(store_id).then((data) => { this.pricegroupList = data; });
    this.storeService.getVendorInvoiceItemList(this.v_invoice_id).then((data) => { this.vinvoiceitemList = data;});
    this.isshowdocument = false
    this.fileurl = ""
  }

  onButtonGroupClick($event) {
    let clickedElement = $event.target || $event.srcElement;
    if (clickedElement.nodeName === "BUTTON") {

      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
      }
      clickedElement.className += " active";
    }

  }

  toggleDisplay1() {
    this.isShow1 = !this.isShow1;
    this.isShow2 = true;
    this.isShow4 = true;
    this.isShow3 = true;
  }
  toggleDisplay2() {
    this.isShow2 = !this.isShow2;
    this.isShow1 = true;
    this.isShow4 = true;
    this.isShow3 = true;
  }
  toggleDisplay3() {
    this.isShow3 = !this.isShow3;
    this.isShow1 = true;
    this.isShow4 = true;
    this.isShow2 = true;
  }
  toggleDisplay4() {
    this.isShow4 = !this.isShow4;
    this.isShow2 = true;
    this.isShow1 = true;
    this.isShow3 = true;
  }

  savemanualentry(form) {
    if (form.valid) {
      this.storeService.saveVendorinvoice(form.value, this.v_invoice_id)
        .then((response) => {
          Swal.fire('Vendor Invoice!', 'Manual Entry Save Successfully.', 'success');
        });
    }
  }

  saveaddexistingitem(e, form: any) {
    if (form.valid) {
      this.storeService.saveVendorInvoiceItem(this.modelVendorInvoice['_id'], this.searcheditemData, this.vitmdata)
        .then((response) => {
          this.storeService.sendUpdatedVendorInvoiceItem();
          Swal.fire('Vendor Invoice!', 'Item added Successfully.', 'success');
          form.reset();
          this.isShowAddBtn = true;
        }).catch((error) => {
          Swal.fire('Vendor Invoice!', 'Error!.', 'error');
        });

    }
  }

  addNewItem(e, form: any) {
    e.preventDefault();
    if (form.invalid) {
      return false;
    }

    if (this.itemId) {
      this.updateVendorInvoiceItem(form)
    } else {
      this.storeService.saveItem(form.value, this.itemId)
        .then((response) => {
          this.storeService.saveVendorInvoiceItem(this.modelVendorInvoice['_id'], response, this.vitmdata)
            .then((response) => {

              this.storeService.sendUpdatedVendorInvoiceItem();
              Swal.fire('Vendor Invoice!', 'Item saved!.', 'success');
              form.reset();
              form.resetForm();
            }).catch((error) => {
              Swal.fire('Vendor Invoice!', 'Error!.', 'error');
            });

        }).catch((error) => {
          Swal.fire('Vendor Invoice!', 'Error!.', 'error');
        });
    }
  }


  updateVendorInvoiceItem(form) {
    console.log(form.value)
    this.storeService.saveVendorInvoiceItem(this.itemData.vendor_invoice_id, this.itemModel)
      .then((response) => {
        console.log("r=>"+JSON.stringify(response))
        Swal.fire('Vendor Invoice!', 'Item saved!.', 'success');
        form.reset();
        this.itemId = null
      this.storeService.getVendorInvoiceItemList(this.v_invoice_id).then((data) => { this.vinvoiceitemList = data; });
      })
    
  }

  onSearchByUpc($event) {
    console.log(this.existingItmUpc);
    if (this.existingItmUpc) {
      this.storeService.getStoreItemListByUPC(this.existingItmUpc).then((data) => {
        console.log(data);
        if (data.upc_code != '') {
          this.isShowAddBtn = !this.isShowAddBtn;
          this.itemnotformmsg = '';
          this.searcheditemid = data._id;
          this.searcheditemData = data;
        } else {
          this.itemnotformmsg = 'Item not exists.';
          this.isShowAddBtn = true;
        }

      }).catch((error) => {
        Swal.fire('Item Search', 'Error!.', 'error');
        this.isShowAddBtn = true;
      });
    } else {
    }

  }

  calculateRetail() {
    if (this.modelVendorInvoice.default_margin) {
      this.modelVendorInvoice.margin = this.modelVendorInvoice.default_margin + "%"
    }

    if (this.modelVendorInvoice.default_margin && this.modelVendorInvoice.case_cost) {
      const margin = parseFloat(this.modelVendorInvoice.default_margin);
      const case_cost = parseFloat(this.modelVendorInvoice.case_cost);
      let retail = (case_cost / (1 - margin / 100)).toFixed(2);
      this.modelVendorInvoice.retail = retail.toString();
    }

  }


  onClickRow(event) {
    if (event.type == 'click') {
      this.itemModel = event.row
      this.itemId = event.row._id
      this.itemData = event.row
    }
  }

  async isshowducuments(){
    let data = {
      key: this.files[0].filename
    }
    await this.storeService.getsignedurl(data).then((response)=>{
      this.fileurl=response['data'];
      this.isshowdocument = !this.isshowdocument
    })
    console.log("url>>>"+this.fileurl)
  }

}
