import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class BankAccount {
    public store_id: string;
    public store_bank_account_name: string;
    public store_bank_account_type: string;
    public store_bank_address: string;
    public store_bank_zip_code: string;
    public store_bank_branch: string;
  }

@Component({
  selector: 'app-addbankaccount',
  templateUrl: './addbankaccount.component.html',
  styleUrls: ['./addbankaccount.component.scss']
})
export class AddbankaccountComponent implements OnInit {

  @ViewChild("addbankaccount", { static: false }) addbankaccount: TemplateRef<any>;
  @Output() sendaddedbankaccount: EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelBankAccount = new BankAccount();
  selected_id : string;
  public modelTitle: string;
  public bankId: string; 

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
  }

  openModal(row_id?:null) {
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getBankAccountById(this.selected_id).then((data)=>{this.modelBankAccount=data;});
        this.modelTitle = "Edit";
        this.bankId = this.selected_id;
    }else{
      this.modelBankAccount = new BankAccount();
      this.modelTitle = "Add";
      this.bankId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addbankaccount, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  savebank(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
  
   if(form.valid) { 
    this.storeService.addBankaccount(form.value, this.bankId)
          .then((response) => {
             console.log(response);
             Swal.fire('Bank Account!', 'Bank Account Save Successfully.', 'success');
             this.sendaddedbankaccount.emit(store_id);
             this.modalService.dismissAll();
          });
    }
  }


}
