import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddtaxsetupComponent } from './addtaxsetup/addtaxsetup.component';
import { AddtaxstrategyComponent } from './addtaxstrategy/addtaxstrategy.component';
import { CONSTANTS } from 'src/app/util/constants';

export class StoreTax {
  public store_tax_rate: string;
  public store_tax_strategy_id: string;
  public store_tax_description: string;
}

export class StoreTaxStrategy {
  public store_tax_strategy_rate: string;
  public store_tax_commander_tax_id: string;
  public store_tax_commands: string;
}

@Component({
  selector: 'app-tax-setup',
  templateUrl: './tax-setup.component.html',
  styleUrls: ['./tax-setup.component.scss']
})
export class TaxSetupComponent implements OnInit {
  @ViewChild("addtaxsetup") Addtaxsetup: AddtaxsetupComponent;
  @ViewChild("addtaxstrategy") Addtaxstrategy: AddtaxstrategyComponent;
  
  public modalOpen: boolean = false;
  modeltaxsetupList = [];
  modeltaxstrategyList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [

  ];

  constructor( public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
     this.storeService.gettax(store_id).then((response) => {
      this.modeltaxsetupList = response;
    });

    this.storeService.gettaxStrategy(store_id).then((response) => {
      this.modeltaxstrategyList = response;
    });
  }

  async reloadtaxsetupTable(event) {
    this.modeltaxsetupList = await this.storeService.gettax(event);
  }

  async reloadtaxstrategyTable(event) {
    this.modeltaxstrategyList = await this.storeService.gettaxStrategy(event);
  }

  removetaxsetup(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removetax(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadtaxsetupTable(store_id);
          }
        });
    }
  }


  removetaxstrategy(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removetaxStrategy(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadtaxstrategyTable(store_id);
          }
        });
    }
  }

}
