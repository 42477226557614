import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddvendorComponent } from './modal/addvendor/addvendor.component';
import { CONSTANTS } from 'src/app/util/constants';

export class Vendor {
    public vendor_name: string;
    public vendor_address: string;
    public vendor_contact_no: string;
    public vendor_item_name: string;
    public vendor_item_scan_code: string;
    public vendor_address2: string;
    public contact_name: string;
    public email: string;
    public fax: string;
    public state: string;
    public city: string;
    public zipcode: string;
    public account_number: string;
    public payment_method: string;
    public terms: string;
    public pos_id: string;
    public department: string;
    public default_margin: string;
    public default_bank_account: string;
    public is_employee: string;
    public is_expence: string;
    public is_customer: string;
    public is_misc: string;
    public is_mpos: string;
    public is_vendor: string;
}

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  @ViewChild("addvendor") Addvendor: AddvendorComponent;
  modelAddVendorList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];

  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelAddVendorList = await this.storeService.getVendor(data['_id']);
      }
    })
    
  }

  async reloadVendorTable(event) {
    // console.log("test");
    this.modelAddVendorList = await this.storeService.getVendor(event);
  }

  removeVendor(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeVendor(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadVendorTable(store_id);
          }
        });
    }
  }

}
