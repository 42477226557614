<div class="container-fluid pdlr">
    <div class="row">
        <div class="col-xl-12">
            <div class="add-heading mt20">
                <h1>Add Item to Vendor Invoice</h1>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-header calender-btn pb-4">
                    <span style="font-size: medium; font-weight: 700;">Invoice number:
                        {{modelVendorInvoice['invoice_number']}}</span>
                    <span style="font-size: medium; font-weight: 700;">Invoice Date:
                        {{modelVendorInvoice['invoice_date']}}</span>
                    <span style="font-size: medium; font-weight: 700;">Status: {{modelVendorInvoice['status']}}</span>
                    <span style="font-size: medium; font-weight: 700;">Amount: {{modelVendorInvoice['amount']}}</span>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card ">
                <div class="card-header calender-btn">

                    <div class="btn-group" (click)="onButtonGroupClick($event)">
                        <button class="btn btn-primary slect-btn" (click)=toggleDisplay1() type="button">Manual
                            Entry</button>
                        <button class="btn btn-primary slect-btn" (click)=toggleDisplay2() type="button">Item Detail
                            Entry</button>
                        <!-- <button class="btn btn-primary slect-btn" (click) = toggleDisplay3() type="button">Using Scanner</button> -->
                        <button class="btn btn-primary slect-btn" (click)=toggleDisplay4() type="button">Purchase
                            Summary - Dept wise</button>
                    </div>



                    <!-- <button (click) = toggleDisplay1()  class="btn btn-primary slect-btn">Manual Entry </button>
                    <button (click) = toggleDisplay2()  class="btn btn-primary slect-btn">Item Detail Entry</button>
                    <button class="btn btn-primary slect-btn">Using Scanner</button>
                    <button (click) = toggleDisplay4()  class="btn btn-primary slect-btn">Purchase Summery</button> -->
                </div>
                <div class="card-body ">
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="card ">
                <!-- <div class="card-header calender-btn">



                </div> -->
                <div class="card-body ">
                    <form [hidden]="isShow1" class="needs-validation" #addmanualentry="ngForm"
                        (ngSubmit)="savemanualentry(addmanualentry)">
                        <div class="justify-content-center">
                            <div class="row">
                                <div class="mb-3 theme-form col-4">
                                    <label>Default Margin </label>
                                    <input type="text" class="form-control" name="default_margin"
                                        [(ngModel)]="modelVendorInvoice.default_margin" #default_margin="ngModel"
                                        [ngClass]="{ 'is-invalid': addmanualentry.submitted && default_margin.invalid }"
                                        required (change)="calculateRetail()">
                                </div>
                                <div class="mb-3 theme-form col-4">
                                    <label>Case Cost </label>
                                    <input type="text" class="form-control" name="case_cost"
                                        [(ngModel)]="modelVendorInvoice.case_cost" #case_cost="ngModel"
                                        [ngClass]="{ 'is-invalid': addmanualentry.submitted && case_cost.invalid }"
                                        required (change)="calculateRetail()">
                                </div>
                                <div class="mb-3 theme-form col-4">
                                    <label>Retail </label>
                                    <input type="text" class="form-control" name="retail"
                                        [(ngModel)]="modelVendorInvoice.retail" #retail="ngModel"
                                        [ngClass]="{ 'is-invalid': addmanualentry.submitted && retail.invalid }"
                                        required>
                                </div>
                            </div>
                            <div class="row">


                                <div class="mb-3 theme-form col-4">
                                    <label>Margin </label>
                                    <input type="text" class="form-control" name="margin"
                                        [(ngModel)]="modelVendorInvoice.margin" readonly>
                                </div>

                                <div class="mb-3 theme-form col-4">
                                    <label>Department </label>
                                    <select class="form-control form-select digits" name="department_id"
                                        [(ngModel)]="modelVendorInvoice.department_id" #department_id="ngModel"
                                        [ngClass]="{ 'is-invalid': addmanualentry.submitted && department_id.invalid }"
                                        required>
                                        <option value="">Select</option>
                                        <option *ngFor="let department of departmentList" [value]="department._id">
                                            {{department.department_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <div class="col-md-12">
                                    <button class="btn btn-primary">Save</button>
                                </div>
                            </div>

                        </div>
                    </form>



                    <div [hidden]="isShow2">
                        <div class="showfilesbtn">
                            <button class="btn" (click)="isshowducuments()">{{isshowdocument?'Hide Invoice':'Show Invoice'}}</button>
                        </div>
                        <form #addeextitem="ngForm" (ngSubmit)="saveaddexistingitem($event,addeextitem)">

                            <input type="hidden" name="item_id" [(value)]="itemId" ngModel>
                            <div class="row m-0 p-tb">
                                <!-- <div class="col-xl-12">
                                    <span class="topSellingItem">Search By UPC /description</span>
                                </div> -->

                                <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="mb-3 theme-form">
                                        <label>Search By UPC /description</label>
                                        <input type="text" class="form-control" name="upc_code"
                                            [(ngModel)]="existingItmUpc" (blur)="onSearchByUpc($event)">
                                    </div>
                                </div>
                                <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="mb-3 theme-form">
                                        <label>Or Item Code </label>
                                        <input type="text" class="form-control">
                                    </div>
                                </div> -->
                                <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="mb-3 theme-form">
                                        <label>Qty </label>
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="mb-3 theme-form">
                                        <label>Curr Qty </label>
                                        <input type="text" class="form-control" readonly>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-12" [hidden]="isShowAddBtn">
                                    <div class="updatebtn">
                                        <button [disabled]="addeextitem.invalid">Add</button>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <div class="updatebtn">
                                        {{itemnotformmsg}}
                                    </div>
                                </div>

                            </div>
                        </form>

                        <!-- if status is verified do not show form -->
                        <ng-template [ngIf]="modelVendorInvoice['status'] != 'Verified' ">
                            <div class="dotted-border" style="border-bottom: 2px dotted #ccc;margin-bottom: 10px;">
                            </div>                    
                            <form #addenewitem="ngForm" (ngSubmit)="addNewItem($event,addenewitem)">
                            <div [ngClass]="{'row' : isshowdocument}">
                                <!-- <form  class="needs-validation" #addenewitem="ngForm" > -->
                                <div [ngClass]="isshowdocument ? 'col-6 p-tb m-0' : 'row m-0 p-tb' " >
                                    <div class="col-xl-12 mb-2">
                                        <span class="topSellingItem ">Use below form to add new item</span>
                                    </div>
                                    <div [ngClass]="{'col-xl-4 col-lg-6 col-md-6 col-12 border' : !isshowdocument}">
                                        <div class="row pt-2">
                                            <!-- <div class="col-3">
                                                <div class="mb-3 theme-form">
                                                    <label>Item Code </label>
                                                    <input type="text" class="form-control" name="nacs_code"
                                                    [(ngModel)]="itemModel.nacs_code" #nacs_code="ngModel"
                                                    [ngClass]="{ 'is-invalid': addenewitem.submitted && nacs_code.invalid }" required>
                                                </div>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>UPC Code </label>
                                                    <input type="text" class="form-control" name="upc_code"
                                                        [(ngModel)]="itemModel.upc_code" #upc_code="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && upc_code.invalid }"
                                                        [(readonly)]="itemId">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Description </label>
                                                    <input type="text" class="form-control" name="item_description"
                                                        [(ngModel)]="itemModel.item_description"
                                                        #item_description="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && item_description.invalid }"
                                                        required>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row pt-2">
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Units/Case </label>
                                                    <input type="text" class="form-control" name="unit_or_case"
                                                        [(ngModel)]="itemModel.unit_or_case" #unit_or_case="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && unit_or_case.invalid }"
                                                        required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Case Cost </label>
                                                    <input type="text" class="form-control" name="case_cost"
                                                        [(ngModel)]="itemModel.case_cost" #case_cost="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && case_cost.invalid }"
                                                        required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-2">
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Unit Retail </label>
                                                    <input type="text" class="form-control" name="unit_retail"
                                                        [(ngModel)]="itemModel.unit_retail" #unit_retail="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && unit_retail.invalid }"
                                                        required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Case Discount </label>
                                                    <input type="text" class="form-control" name="case_discount"
                                                        [(ngModel)]="itemModel.case_discount" #case_discount="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && case_discount.invalid }"
                                                        required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [ngClass]="{'col-xl-4 col-lg-6 col-md-6 col-12 border' : !isshowdocument}">
                                        <div class="row pt-2">
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Department </label>
                                                    <select class="form-control form-select digits" name="department"
                                                        [(ngModel)]="itemModel.department" #department="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && department.invalid }">
                                                        <option>Select</option>
                                                        <option *ngFor="let department of departmentList"
                                                            [value]="department._id">
                                                            {{department.department_name}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Product Category </label>
                                                    <select class="form-control form-select digits" name="item_category"
                                                        [(ngModel)]="itemModel.item_category" #item_category="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && item_category.invalid }">
                                                        <option>Select</option>
                                                        <option *ngFor="let itmcategory of categoryList"
                                                            [value]="itmcategory._id">
                                                            {{itmcategory.name}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Price Group </label>
                                                    <select class="form-control form-select digits" name="price_group"
                                                        [(ngModel)]="itemModel.price_group" #price_group="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && price_group.invalid }">
                                                        <option>Select</option>
                                                        <option *ngFor="let itmpricegroup of pricegroupList"
                                                            [value]="itmpricegroup._id">
                                                            {{itmpricegroup.name}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Size </label>
                                                    <select class="form-control form-select digits" name="size"
                                                        [(ngModel)]="itemModel.size" #size="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && size.invalid }">
                                                        <option>Select</option>
                                                        <option>10</option>
                                                        <option>20</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div [ngClass]="{'col-xl-4 col-lg-6 col-md-6 col-12 border' : !isshowdocument}">
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="checkbox checkbox-primary p-0 leftmargin">
                                                    <input class="form-check-input" id="checkbox-primary-3"
                                                        type="checkbox" name="food_stamp_sales"
                                                        [(ngModel)]="itemModel.food_stamp_sales"
                                                        #food_stamp_sales="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && food_stamp_sales.invalid }">
                                                    <label class="form-check-label" for="checkbox-primary-3">Food
                                                        Stamp</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="mb-3 theme-form">
                                                    <label>Margin </label>
                                                    <input type="text" class="form-control" name="margin"
                                                        [(ngModel)]="itemModel.margin" #margin="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && margin.invalid }">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Cost/Unit after discount </label>
                                                    <input type="text" class="form-control"
                                                        name="cost_or_unit_after_discount"
                                                        [(ngModel)]="itemModel.cost_or_unit_after_discount"
                                                        #cost_or_unit_after_discount="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && cost_or_unit_after_discount.invalid }">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3 theme-form">
                                                    <label>Extd Case </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="mb-3 theme-form">
                                                    <label>Sales Type</label>

                                                    <select class="form-control form-select digits" name="sales_type"
                                                        [(ngModel)]="itemModel.sales_type" #sales_type="ngModel"
                                                        [ngClass]="{ 'is-invalid': addenewitem.submitted && sales_type.invalid }">
                                                        <option *ngFor="let salestype of salestypes"
                                                            [value]="salestype.id">
                                                            {{salestype.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div class="updatebtn">
                                                    <button>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- show document start-->
                                <ng-template [ngIf]="isshowdocument">
                                    <div class="col-6 document pt-2">
                                        <iframe [src]="fileurl | safe" width="100%" height="100%">
                                        </iframe>
                                    </div>
                                </ng-template>
                                <!-- show document end-->
                            </div>
                            </form>
                        </ng-template>

                        <div class="row mt-2 custom-datatable">
                            <div class="col-sm-12">
                                <ngx-datatable class="bootstrap" [rows]="vinvoiceitemList" [columnMode]="'force'"
                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10"
                                    [selectionType]="'checkbox'" [selectAllRowsOnPage]="false"
                                    (activate)="onClickRow($event)">
                                    <!-- <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                        [draggable]="false" [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
                                    </ngx-datatable-column> -->
                                    <ngx-datatable-column name="UPC code" prop="upc_code"></ngx-datatable-column>
                                    <ngx-datatable-column name="Description"
                                        prop="item_description"></ngx-datatable-column>
                                    <ngx-datatable-column name="Unit/Case" prop="unit_or_case"></ngx-datatable-column>
                                    <ngx-datatable-column name="Case Cost " prop="case_cost"></ngx-datatable-column>
                                    <ngx-datatable-column name="Case Discount"
                                        prop="case_discount"></ngx-datatable-column>
                                    <ngx-datatable-column name="Discount"
                                        prop="special_discount"></ngx-datatable-column>
                                    <ngx-datatable-column name="Unit Retail" prop="unit_retail"></ngx-datatable-column>
                                    <ngx-datatable-column name="Margin" prop="vItem.margin"></ngx-datatable-column>
                                    <!-- <ngx-datatable-column name="ItemCode"></ngx-datatable-column> -->
                                    <!-- <ngx-datatable-column name="vendor_invoice_id"></ngx-datatable-column> -->

                                </ngx-datatable>
                            </div>
                        </div>

                    </div>

                    <div [hidden]="isShow3">Commig soon...</div>
                    <div [hidden]="isShow4">
                        <div class="row mt-2 custom-datatable">
                            <div class="col-sm-12">
                                <ngx-datatable class="bootstrap" [columnMode]="'force'" [headerHeight]="50"
                                    [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [summaryRow]="true">
                                    <!-- <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                        [draggable]="false" [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
                                    </ngx-datatable-column> -->
                                    <ngx-datatable-column name="Department"></ngx-datatable-column>
                                    <ngx-datatable-column name="Extd Case Cost"></ngx-datatable-column>
                                    <ngx-datatable-column name="Extd Case Retail"></ngx-datatable-column>
                                    <ngx-datatable-column name="Margin"></ngx-datatable-column>

                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>