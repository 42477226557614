import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JSONOPTIONS {
    
    public YESNO: any = [
        {id: true,name: "Yes"},{id: false,name: "No"}
    ];
    public SALETYPE: any = [
        {id: 'By Piece', name: "By Piece"},{id: 'By Weight',name: "By Weight"},{id: 'By Meat Barcode',name: "By Meat Barcode"}
    ];
    public PlusGradeTankType: any = [
        {id: 'blended',name: "Blended"}, {id: 'separatetank', name: "Separate Tank"}
    ];

    public GASSUPLIERPAYMODES: any = [
        {id: 'EFT',name: "EFT"},{id: 'CHECK',name: "CHECK"}
    ];
    public GASSUPLIERCREDITCARDSETTLE: any = [
        {id: 'Direct',name: "Directly in My Bank Account"},{id: 'Jobber Invoice Credit',name: "Jobber Invoice Credit"}
    ];
}