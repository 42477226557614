<div class="container-fluid">
    <div class="row">

        <div class="col-xl-12 xl-100 box-col-12">
            <div class="mtb">
                <form>
                    <div class=" row m-0 p-tb">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card">
                                <div class="card-header calender-btn">
                                    <h5>vendor list
                                        <button class="btn btn-primary pull-right"  (click)="addvendor.openModal();$event.preventDefault()">Add new </button>
                                        <div class="clearfix"></div>
                                    </h5>
                                </div>
                                <div class="card-body custom-datatable noscroll">
                                    <ngx-datatable class="bootstrap"  [rows]="modelAddVendorList" [loadingIndicator]="loadingIndicator"
                                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                        [reorderable]="reorderable" >
                                        <ngx-datatable-column name="Vendor name" prop="vendor_name"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Address" prop="vendor_address"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Contact No" prop="vendor_contact_no"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Item Name" prop="vendor_item_name"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Item Scan Code" prop="vendor_item_scan_code"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Action" widht="600">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addvendor.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                    <button class="delete-btn" (click)="removeVendor(row._id)"><i class="fa fa-trash"></i></button>
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-addvendor (sendaddedvendor) = 'reloadVendorTable($event)' #addvendor></app-addvendor>
