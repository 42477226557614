<ng-template #addmixmatchmaintenance let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addmixmatchmaintenance="ngForm" (ngSubmit)="savemixmatchmaintenance(addmixmatchmaintenance)">
            <div class="form-row">
                        

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Mix match name </label>
                                <input type="text" class="form-control" name="name" [(ngModel)]="modelMixmatchMaintenance.name" #name="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && name.invalid }"  required>
                            </div>
                        </div>
                        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Unit type</label>
                                <select class="form-control form-select digits" name="unit_type" [(ngModel)]="modelMixmatchMaintenance.unit_type" #unit_type="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && unit_type.invalid }" required>
                                    <option value="unit_1">Select 1</option>
                                    <option value="unit_2">Select 2</option>
                                </select>
                            </div>
                        </div> -->
                        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Is co-founded</label>
                                <select class="form-control form-select digits" name="is_cofounded" [(ngModel)]="modelMixmatchMaintenance.is_cofounded" #is_cofounded="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && is_cofounded.invalid }" required>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form">
                                <label>Select Items</label>
                                <ng-select name="item_list_name" [multiple]="true" [(ngModel)]="mixmatch_itm_ids"  class="searchable_select" (search)="onSearch($event)" (remove)="onRemove($event)">
                                    <ng-option *ngFor="let storeItem1 of searchstoreitems" [value]="storeItem1._id" >
                                        {{ storeItem1.upc_code }} ({{storeItem1.item_description}})
                                    </ng-option>
                                 </ng-select>
                                
                                
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Begin date </label>
                                <input type="date" class="form-control" name="start_date" [(ngModel)]="modelMixmatchMaintenance.start_date" #start_date="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && start_date.invalid }" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>End date </label>
                                <input type="date" class="form-control" name="end_date" [(ngModel)]="modelMixmatchMaintenance.end_date" #end_date="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && end_date.invalid }"  required>
                            </div>
                        </div>
                       

                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="media"><label class="col-form-label m-r-10">Store accept mobile coupons</label>
                                <div class="media-body text-right">
                                    <label class="switch">
                                    <input  type="checkbox" name="accept" [(ngModel)]="modelMixmatchMaintenance.accept"  ><span class="switch-state" ></span>
                                </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Manufacturer discount amount</label>
                                <input type="text" class="form-control" name="manufacturer_discount_amount" [(ngModel)]="modelMixmatchMaintenance.manufacturer_discount_amount"  #manufacturer_discount_amount="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && manufacturer_discount_amount.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Retailer discount amount</label>
                                <input type="text" class="form-control" name="retailer_discount_amount" [(ngModel)]="modelMixmatchMaintenance.retailer_discount_amount" #retailer_discount_amount="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && retailer_discount_amount.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Mix match amount</label>
                                <input type="text" class="form-control" name="amount" [(ngModel)]="modelMixmatchMaintenance.amount" #amount="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && amount.invalid }"  required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Mix match quantity</label>
                                <input type="text" class="form-control" name="quantity" [(ngModel)]="modelMixmatchMaintenance.quantity" #quantity="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && quantity.invalid }" required>
                            </div>
                        </div> 
                        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Status</label>
                                <select class="form-control form-select digits" name="status" [(ngModel)]="modelMixmatchMaintenance.status" #status="ngModel"
                                [ngClass]="{ 'is-invalid': addmixmatchmaintenance.submitted && status.invalid }" required>
                                    <option value="new">New</option>
                                    <option value="ready">Ready</option>
                                </select>
                            </div>
                        </div> -->
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>