<ng-template #addaltria let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addaltria="ngForm" (ngSubmit)="saveftpdeatil(addaltria)">
            <div class="form-row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form">
                        <label>Server/Host </label>
                        <input type="text" class="form-control" name="ftpserver"  [(ngModel)]="modelAltriaftp.ftpserver"  #ftpserver="ngModel"
                        [ngClass]="{ 'is-invalid': addaltria.submitted && ftpserver.invalid }" required>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form">
                        <label>Username </label>
                        <input type="text" class="form-control" name="ftpusername" [(ngModel)]="modelAltriaftp.ftpusername"  #ftpusername="ngModel"
                        [ngClass]="{ 'is-invalid': addaltria.submitted && ftpusername.invalid }" required>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                    <div class="mb-3 theme-form">
                        <label>Password </label>
                        <input type="text" class="form-control" name="ftppassword" [(ngModel)]="modelAltriaftp.ftppassword"  #ftppassword="ngModel"
                        [ngClass]="{ 'is-invalid': addaltria.submitted && ftppassword.invalid }" required>
                    </div>
                </div>
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>