import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class AltriaFtpDetail {
  public store_id: string;
  public ftpserver: string;
  public ftpusername: string;
  public ftppassword: string;
}

@Component({
  selector: 'app-addaltria',
  templateUrl: './addaltria.component.html',
  styleUrls: ['./addaltria.component.scss']
})
export class AddAtriaComponent implements OnInit {
  @ViewChild("addaltria", { static: false }) addaltria: TemplateRef<any>;
  @Output() sendaddedaltria:EventEmitter<string> = new EventEmitter<string>();

  public itemId: string; 
    public closeResult: string;
    public modalOpen: boolean = false;
    modelAltriaftp = new AltriaFtpDetail();
    selected_id : string;
    public modelTitle: string;
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

    ngOnInit(): void {
    }

    openModal(row_id?:null) {
      
  
      this.selected_id= row_id;

      if(this.selected_id != null){
        this.storeService.getsAltriaftpdeatilById(this.selected_id).then((data)=>{this.modelAltriaftp=data;});
        this.modelTitle = "Edit";
        this.itemId = this.selected_id;
    }else{
      this.modelAltriaftp = new AltriaFtpDetail();
        this.modelTitle = "Add";
    }
      
        
      if (isPlatformBrowser(this.platformId)) { // For SSR 
        this.modalService.open(this.addaltria, {
          size: 'lg',
          ariaLabelledBy: 'modal-bookmark',
          centered: true,
          windowClass: 'modal-bookmark'
        }).result.then((result) => {
          this.modalOpen = true;
          `Result ${result}`
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }
  
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
  
    ngOnDestroy() {
      if (this.modalOpen) {
        this.modalService.dismissAll();
      }
    }

    saveftpdeatil(form){
      let store_id = JSON.parse(localStorage.getItem('store_id'));
    
   if(form.valid) {
    this.storeService.saveAltriaftpdeatil(form.value, this.itemId)
          .then((response) => {
             console.log(response);
             Swal.fire('Scan Data!', 'Ftp Details Save Successfully.', 'success');
             this.sendaddedaltria.emit(store_id);
             this.modalService.dismissAll();
          });
    }
    }
  }