// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // apiKey: "Your Api Key",
    // authDomain: "Your Auth Domain",
    // databaseURL: "Your Database Url",
    // projectId: "Your Project Id",
    // storageBucket: "Your StorageBucket url",
    // messagingSenderId: "Your Sender Id",
    apiKey: "AIzaSyAJsYNpuTtMCg73AqNKn3dhUzJfTdEl4A8",
    authDomain: "ecore-a3d0f.firebaseapp.com",
    projectId: "ecore-a3d0f",
    storageBucket: "ecore-a3d0f.appspot.com",
    messagingSenderId: "1055871924269",
    appId: "1:1055871924269:web:7799758525c853b4b8e0a4",
    measurementId: "G-Q3T9JX4HFN"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
