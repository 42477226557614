import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PhysicalInventoryRoutingModule } from './physical-inventory-routing.module';
import { PhysicalInventoryComponent } from './physical-inventory.component';

import { FormsModule } from '@angular/forms';
import { AddphysicalinventoryComponent } from './addphysicalinventory/addphysicalinventory.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    PhysicalInventoryComponent,
    AddphysicalinventoryComponent
  ],
  imports: [
    CommonModule,
    PhysicalInventoryRoutingModule,
    FormsModule,
    NgxDatatableModule,
    NgSelectModule
  ]
})
export class PhysicalInventoryModule { }
