import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { RoleManagementRoutingModule } from './role-management-routing.module';
import { RoleManagementComponent } from './role-management.component';
import { FormsModule } from '@angular/forms';
import { AddRoleComponent } from './modal/addrole/addrole.component';

@NgModule({
  declarations: [
    RoleManagementComponent,
    AddRoleComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RoleManagementRoutingModule,
    NgxDatatableModule
  ]
})
export class RoleManagementModule { }
