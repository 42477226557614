import { Injectable } from "@angular/core";

@Injectable()
export class CONSTANTS {
    SERVER = {
        // ROOT: 'http://lyfe-trek.test/api',
        // BASE: 'http://lyfe-trek.test',
        // http://localhost:8232/swagger/
        // ROOT: '/api',
        // BASE: '',

        // ROOT: 'http://localhost:8232/api',
        // BASE: 'http://localhost:8232/',

        //ROOT: 'http://14.142.23.142:8232/api',
        //BASE: 'http://14.142.23.142:8232/',

        ROOT: 'https://api.cstoregurus.com/api',
        BASE: 'https://cstoregurus.com/',
    };
    API_URLS = {
        ROOT: '/',
        LOGIN: '/auth/login',
        STOREUSERLOGIN: '/auth/storelogin',
        REGISTER: '/users/registration',
        LOGOUT: '/auth/logout',
        SEND_RESET_LINK: '/send-reset-link',
        RESET_PASSWORD: '/reset-password',

        USERS: '/users',
        USER_PROFILES: '/user-profiles',
        MEDICAL_HISTORY: '/medical-history',
        STORE: '/store',
        STOREUSERFORGOTPASSWORD: '/users/store-user/forgot-password',
        RESETSTOREUSERPASSWORD: '/users/store-user/set-password',

        ADDSALESTAX: '/addsalestax',
        GETSALESTAXLIST: '/getsalestaxlist',
        GETSALESTAXBYID: '/getsalestax',
        DELETESALESTAX: '/deletesalestax',

        GETFUELTAXLIST: '/getfueltaxlist',
        ADDFUELTAX: '/addfueltax',
        GETFUELTAXBYID: '/getfueltax',
        DELETEFUELTAX: '/deletefueltax',

        ADDSALESRESTRICTION: '/addsalesrestriction',
        GETSALESRESTRICTIONLIST: '/getsalesrestrictionlist',
        GETSALESRESTRICTIONBYID: '/getsalesrestriction',
        DELETESALESRESTRICTION: '/deletesalesrestriction',

        ADDFUELGRADE: '/addfuelgrade',
        GETFUELGRADELIST: '/getfuelgradelist',
        DELETEFUELGRADE: '/deletefuelgrade',
        GETFUELGRADEBYID: '/getfuelgrade',

        ADDTANKDETAILS: '/addtankdetails',
        GETTANKDETAILSLIST: '/gettankdetailslist',
        DELETETANKDETAILS: '/deletetankdetails',
        GETTANKDETAILSBYID: '/gettankdetails',

        GETSTORELOCATION: '/getstorelocationfeeslist',
        SAVESTORELOCATION: '/addstorelocationfees',
        DELETESTORELOCATION: '/deletestorelocationfees',
        GETSTORELOCATIONBYID: '/getstorelocationfees',

        ADDPAYMENTMETHOD: '/addpaymentmethod',
        GETPAYMENTMETHODLIST: '/getpaymentmethodlist',
        DELETEPAYMENT: '/deletepaymentmethod',
        GETPAYMENTBYID: '/getpaymentmethod',

        ADDVENDOR: '/addvendor',
        GETVENDORLIST: '/getvendorlist',
        DELETEVENDOR: '/deletevendor',
        GETVENDORBYID: '/getvendor',

        ADDTAXSTRATEGY: '/addtaxstrategy',
        GETTAXSTRATEGYLIST: '/gettaxstrategylist',
        DELETETAXSTRATEGY: '/deletetaxstrategy',
        GETTAXSTRATEGYBYID: '/gettaxstrategy',

        ADDVTAX: '/addtaxsetup',
        GETTAXLIST: '/gettaxsetuplist',
        DELETETAX: '/deletetaxsetup',
        GETTAXBYID: '/gettaxsetup',


        GETBANKACCOUNTBYID: '/getbankaccount',
        DELETEBANKACCOUNT: '/deletebankaccount',

        GENERATE_PDF: '/generate-pdf',
        GET_LANGUAGES: '/get-languages',
        CHANGE_STATUS: '/change-status',
        CONTACT_US: '/contact-us',
        GETLOTTERYSETTINGS: '/getlotterysetting',
        SAVELOTTERYSETTING: '/managelotterysetting',
        GETBANKACCOUNTLIST: '/getbankaccountlist',
        ADDBANKACCOUNT: '/addbankaccount',
        GETTIMEOFFSETTING: '/gettimeoffsetting',
        SAVETIMEOFFSETTING: '/managetimeoffsetting',
        GETSTORESERVICES: '/getstoreservices',
        SAVESTORESERVICES: '/managestoreservices',
        GETSCHEDULINGSETTING: '/getschedulesetting',
        SAVESCHEDULINGSETTING: '/manageschedulesetting',
        GETFUELSETTING: '/getfuelsetting',
        SAVEFUELSETTING: '/managefuelsetting',
        GETLOTTERY: '/getlottery',
        SAVELOTTERY: '/managelottery',
        GETPOSSETUP: '/getpossetup',
        SAVEPOSSETUP: '/managepossetup',


        ADDDEPARTMENT: '/adddepartment',
        GETDEPARTMENTLIST: '/getdepartmentlist',
        DELETEDEPARTMENT: '/deletedepartment',
        GETDEPARTMENTBYID: '/getdepartment',

        ADDSTORELOTTERY: '/addstorelottery',
        GETSTORELOTTERYLIST: '/getstorelotterylist',
        DELETESTORELOTTERYDETAILS: '/deletestorelottery',
        GETSTORELOTTERYBYID: '/getstorelottery',

        ADDACTIVESTORELOTTERY: '/addactivestorelottery',
        GETACTIVESTORELOTTERYLIST: '/getactivestorelotterylist',
        DELETEACTIVESTORELOTTERYDETAILS: '/deleteactivestorelottery',
        GETACTIVESTORELOTTERYBYID: '/getactivestorelottery',


        ADDSTOCKTRANSACTION: '/addstocktransaction',
        GETSTOCKTRANSACTIONLIST: '/getstocktransactionlist',
        DELETESTOCKTRANSACTION: '/deletestocktransaction',
        GETSTOCKTRANSACTIONBYID: '/getstocktransaction',

        ADDPHYSICALINVENTORY: '/addphysicalinventory',
        GETPHYSICALINVENTORYLIST: '/getphysicalinventorylist',
        DELETEPHYSICALINVENTORY: '/deletephysicalinventory',
        GETPHYSICALINVENTORYBYID: '/getphysicalinventory',

        ADDBUYDOWNMANAGERS: '/addbuydownmanagers',
        GETBUYDOWNMANAGERSLIST: '/getbuydownmanagerslist',
        DELETEBUYDOWNMANAGERS: '/deletebuydownmanagers',
        GETBUYDOWNMANAGERSBYID: '/getbuydownmanagers',

        ADDITEM: '/additem',
        GETITEMLIST: '/getitemlist',
        DELETEITEM: '/deleteitem',
        GETITEMBYID: '/getitem',

        ADDVENDORITEM: '/addvendoritem',
        GETVENDORITEMLIST: '/getvendoritemlist',
        DELETEVENDORITEM: '/deletevendoritem',
        GETVENDORITEMBYID: '/getvendoritem',

        ADDITEMMAINTENANCE: '/additemmaintenance',
        GETITEMAINTENANCELIST: '/getitemmaintenancelist',
        DELETEITEMMAINTENANCE: '/deleteitemmaintenance',
        GETITEMMAINTENANCEMBYID: '/getitemmaintenance',

        ADDMIXMATCHMAINTENANCE: '/addmixmatchmaintenance',
        GETMIXMATCHMAINTENANCELIST: '/getmixmatchmaintenancelist',
        DELETEMIXMATCHMAINTENANCE: '/deletemixmatchmaintenance',
        GETMIXMATCHMAINTENANCEMBYID: '/getmixmatchmaintenance',

        ADDVENDORINVOICE: '/addvendorinvoice',
        GETVENDORINVOICELIST: '/getvendorinvoicelist',
        DELETEVENDORINVOICE: '/deletevendorinvoice',
        GETVENDORINVOICEBYID: '/getvendorinvoice',
        SEARCHVENDORINVOICE:'/searchvendorinvoice',

        GETFUELGRADEFORINVOICE:'/getfuelgradelistforinvoice',
        ADDFUELINVOICE: '/addfuelinvoice',
        GETFUELINVOICELIST: '/getfuelinvoicelist',
        DELETEFUELINVOICE: '/deletefuelinvoice',
        GETFUELINVOICEBYID: '/getfuelinvoice',

        GETITEMLISTONSEARCH: '/getitemlistonsearch',
        ADVANCEDSEARCHITEMS: '/getitemlistonadvancedsearch',

        ADDITEMCATEGORY: '/additemcategory',
        GETITEMCATEGORYLIST: '/getitemcategorylist',
        DELETEITEMCATEGORY: '/deleteitemcategory',
        GETITEMCATEGORYBYID: '/getitemcategory',

        ADDPRICEGROUP: '/addpricegroup',
        GETPRICEGROUPLIST: '/getpricegrouplist',
        DELETEPRICEGROUP: '/deletepricegroup',
        GETPRICEGROUPBYID: '/getpricegroup',

        ADDVENDORINVOICEITEM: '/addvendorinvoiceitem',
        GETITEMBYUPC:'/getitembyupc',
        VENDORINVOICEITEMLIST: '/vendorinvoiceitemlist',

        ADDTANKSETTING: '/addtanksetting',
        GETTANKSETTING:'/gettanksetting',
        ADDTANKGRADESETTING: '/addtankgradesetting',

        DELETEFUELDELIVERY: '/deletefueldelivery',
        ADDFUELDELIVERY: '/addfueldelivery',
        GETFUELDELIVERYLIST:'/getfueldeliverylist',
        GETFUELDELIVERYBYID:'/getfueldelivery',

        ADDROLE: '/addrole',
        GETROLELIST:'/getrolelist',
        GETROLEBYID:'/getrole',
        GETMENUPAGESLIST:'/getmenuspageslist',
        GETPERMISSIONSLISTBYPAGEID:'/getpermissionlistbypageid',
        ADDROLEPERMISSIONS: '/addrolepermissions',
        GETPERMISSIONSLISTBYROLEID:'/getpermissionlistbyroleid',
        GETDAYRECONREPORT:'/getdayrecornreport',
        GETLIVEDATAREPORT:'/getlivedatareport',
        GETDEPARTMENTTYPELIST:'/getdepartmenttypelist',
        GETUSERLIST:'/getuserlist',
        DELETUSER:'/deleteuser',
        GETUSERBYID:'/getuser',
        ADDUSER:'/adduser',
        ADDSTOREUSERROLE:'/addstoreuserrole',
        GETUSERROLES:'/getuserroles',
        GETUSERLISTBYIDS:'/getusersbyid',
        GETUSERLISTBYSTOREID:'/getuseridsbystore',
        DELETUSERROLES:'/deleteuserroles',

        ADDCOMBODEAL: '/addcombodeal',
        GETCOMBODEALLIST: '/getcombodeallist',
        DELETECOMBODEAL: '/deletecombodeal',
        GETCOMBODEALBYID: '/getcombodeal',

        ADDMIXMATCHITEMS:'/addmixmatchitems',
        GETMIXMATCHITEMS:'/getmixmatchitemlist',
        ADDCOMBODEALITEMS:'/addcombodealitems',
        GETCOMBODEALITEMS:'/getcombodealitemlist',

        DELETROLEPERMISSIONS:'/deleterolepermissions',
        ADDUSERROLE:'/adduserrole',
        ADDSTOREROLE: '/addstorerole',
        GETSTOREROLELIST:'/getstorerolelist',
        GETSTOREROLEBYID:'/getstorerole',
        GETSTOREMENUPAGESLIST:'/getstoremenuspageslist',
        GETSTOREPERMISSIONSLISTBYPAGEID:'/getstorepermissionlistbypageid',
        ADDSTOREROLEPERMISSIONS: '/addstorerolepermissions',
        GETSTOREPERMISSIONSLISTBYROLEID:'/getstorepermissionlistbyroleid',
        DELETESTOREROLEPERMISSIONS:'/deletestorerolepermissions',
        GETSTOREUSERLIST:'/getstoreuserlist',
        DELETESTOREUSER:'/deletestoreuser',
        GETSTOREUSERBYID:'/getstoreuser',
        ADDSTOREUSER:'/addstoreuser',
        GETSTOREUSERROLES:'/getstoreuserroles',
        DELETSTOREUSERROLES:'/deletestoreuserroles',
        GETTIMEZONES:'/getstoretimezones/list',
        FORGOTADMINPASSWORD:'/users/forgot-password',
        RESETADMINPASSWORD:'/users/set-password',
        UPLOADFILE:'/storelogoupload',
        GETOTHERMONEYLABELS:'/getothermoneyreceivedlables/list',
        ADDOTHERMONEYRECEIVED:'/addothermoneyreceived',
        GETOTHERMONEYRECEIVED:'/getothermoneyreceived',
        ADDGENERALCUSTOMERACCOUNT:'/addgencustomeraccount',
        ADDINCOMEEXPENSE:'/addincomeexpense',
        GENERALCUSTOMERACCOUNTLIST:'/gencustomeraccountlist',
        INCOMEEXPENSELIST:'/incomeexpenselist',
        ADDRJRFTP:'/addrjrftp',
        GETRJRFTPLIST:'/getrjrftplist',
        GETRJRFTPBYID:'/getrjrftp',
        ADDALTRIAFTP:'/addaltriaftp',
        GETALTRIAFTPLIST:'/getaltriaftplist',
        GETALTRIAFTPBYID:'/getaltriaftp',
        ADDSTOREDAYRECON:'/addstoredayrecon',
        GETSTOREDAYRECON:'/getstoredayrecon',
        GETMANUFACTURERLIST: '/manufacturerlist',
        DELETEMANUFACTURER: '/deletemanufacturer',
        GETMANUFACTURER: '/getmanufacturer',
        ADDMANUFACTURER: '/addmanufacturer',
        GETGENERALCUSTOMERACCOUNT: '/getgencustomeraccount',
        GETINCOMEEXPENSE: '/getincomeexpense',
        DELETEGENERALCUSTOMERACCOUNT: '/deletegencustomeraccount',
        DELETEINCOMEEXPENSE: '/deleteincomeexpense',
        UPDATEFUELRETAILPRICE:'/updatefuelretailprice',
        UPDATEFUELRETAILPRICES: '/updatefuelretailprices',
        GETMCMCODE: '/getmcmcode',
        UPDATEDAYRECONREPORT:'/updatedayreconreport',
        GETFUELDELIVERYLISTBYDATE:'/findfueldeliverybydate',
        UPLOADFUELINVOICEFILE:'/uploadfuelinvoice',
        UPLOADVENDORINVOICEFILE: '/uploadvendorinvoice',

        SAVEREGISTERMAAPPING: '/addregistemapping',
        GETFUELGRADETANKREADING:'/getfuelgradetankreading',
        GETSIGNEDURL: '/getSignedUrl',

        SAVEFUELPAYMENT: '/addfuelpayment',
        GETFUELPAYMENTLIST: '/getfuelpaymentlist',
        GETFUELPAYMENTBYID: '/getfuelpaymentbyid',
        GETFUELINVOICEPAYMENTLIST: '/getfuelinvoicepaymentlist',

        GETCLOSINGTANKREADINGBYDATE: '/getClosingTankReadingByDate',

        GETSTOREUSERBYEMAIL: '/getstoreuserbyemail',
        GETSTOREUSERBYNAME:  '/getstoreuserbyname',
        GETUSERBYEMAIL: '/getuserbyemail',
        GETUSERBYNAME: '/getuserbyname',
        DEPOSITMONEYTOBANK:'/depositemoneytobank',
        GETBANKDEPOSITEDLIST:'/depositedmoneytobanklist',
        DELETEBANKDEPOSITED:'/removemoneydepositedtobank',
        ADDCREDITCARDBATCH:'/addcreditcardbatch',
        GETCREDITCARDBATCHLIST:'/creditcardbatchlist',
        DELETECREDITCARDBATCH:'/removecreditcardbatch',
        GETCREDITCARDBATCHTYPELIST:'/creditcardbatchtypelist',
        GETLIVESUMMARYDATAREPORT: '/getlivesummarydatareport',
        UPLOADFUELPAYMENTFILE: '/uploadfuelpayment',
        GETCREDITCARDLIST:'/getcreditcardlist',
        ADDDAYRECONCASHPURCHASES:'/adddayreconcashpurchases',
        GETDAYRECONCASHPURCHASELIST:'/getdayreconcashpurchaseslist',
        REMOVEDAYRECONCASHPURCHASES: '/removedayreconcashpurchases',
        
    };

    URL_VARIABLES = {
        TOKEN: '?token=',
        EMAIL: '?email=',
        ID: '&id=',
    };

    RESPONSE_CODES = {
        ERROR: 1,
        NOT_FOUND: 2,
        READ_ERROR: 3,
        CREATE_ERROR: 4,
        UPDATE_ERROR: 5,
        DELETE_ERROR: 6,
        READ_SUCCESS: 7,
        CREATE_SUCCESS: 8,
        UPDATE_SUCCESS: 9,
        DELETE_SUCCESS: 10,
        EMAIL_SENT: 11,
        EMAIL_SENDING_FAILED: 12,
        DUPLICATE_RECORD: 13,

        VALID_LOGIN: 14,
        INVALID_LOGIN: 15,
        FILE_UPLOADED: 16,
        LOGGED_OUT: 17,
        ELIGIBLE: 20,
        NOT_ELIGIBLE: 21,

        HTTP_STATUS_BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        HTTP_STATUS_OK: 200,
        HTTP_STATUS_ERROR: 500,
        HTTP_STATUS_FORBIDDEN: 403,
        HTTP_STATUS_NOT_FOUND: 404,
    };

    REQUEST_METHODS = {
        GET: 'get',
        PUT: 'put',
        POST: 'post',
        DELETE: 'delete'
    };

    MESSAGES = {
        EMAIL_EMPTY: 'Email is empty',
        PASSWORD_EMPTY: 'Password is empty',
        PASSWORD_MISMATCH: 'Password and confirm password do not match',
        PASSWORD_LENGTH: 'Password must contain at least six characters!',
        INVALID_DATE: 'Invalid date',
        INVALID_TOKEN: 'Invalid Token',
        INVALID_BIRTHDATE: 'Invalid Date of Birth',
    };

    GLOBAL_SETTINGS = {
        PROJECT_NAME: 'CstoreGurus'
    };

}
