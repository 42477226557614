import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate  } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { LoginAdminComponent } from './auth/login-admin/login-admin.component';
import { RegisterComponent } from './auth/register/register.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { AuthGuard } from './shared/services/auth.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './pages/authentication/forget-password/forget-password.component';

const routes: Routes = [
  // {
  //   path: 'dashboard',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full'
  // },
  {
    // path: 'auth/login',
    path: '',
    component: LoginComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'auth/register',
    component: RegisterComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'auth/forgot-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'admin-login',
    component: LoginAdminComponent,
    // canActivate: [AuthGuard]
  },
  
  {
    path: '',
    component: ContentComponent,
    children: content,
    canActivate: [AuthGuard]
  },
  { 
    path: 'dashboard', 
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store', 
    loadChildren: () => import('./components/store/store.module').then(m => m.StoreModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store-dashboard', 
    loadChildren: () => import('./components/store-dashboard/store-dashboard.module').then(m => m.StoreDashboardModule) ,
    // canActivate: [AuthGuard]
  },
  { 
    path: 'add-department', 
    loadChildren: () => import('./components/add-department/add-department.module').then(m => m.AddDepartmentModule) ,
    // canActivate: [AuthGuard]
  },
  { 
    path: 'vendor', 
    loadChildren: () => import('./components/vendor/vendor.module').then(m => m.VendorModule), 
    // canActivate: [AuthGuard]
  },
  { 
    path: 'fuel', 
    loadChildren: () => import('./components/fuel/fuel.module').then(m => m.FuelModule),
    // canActivate: [AuthGuard]
   },
  { 
    path: 'lottery', 
    loadChildren: () => import('./components/lottery/lottery.module').then(m => m.LotteryModule),
    // canActivate: [AuthGuard]
   },
  { 
    path: 'pos-setup', 
    loadChildren: () => import('./components/pos-setup/pos-setup.module').then(m => m.PosSetupModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'tax-setup', 
    loadChildren: () => import('./components/tax-setup/tax-setup.module').then(m => m.TaxSetupModule),
    // canActivate: [AuthGuard]
   },
  { 
    path: 'min-age-restriction', 
    loadChildren: () => import('./components/min-age-restriction/min-age-restriction.module').then(m => m.MinAgeRestrictionModule),
    // canActivate: [AuthGuard]
   },
  { 
    path: 'price-group', 
    loadChildren: () => import('./components/price-group/price-group.module').then(m => m.PriceGroupModule),
    // canActivate: [AuthGuard] 
  },
  { path: 'item-category', 
    loadChildren: () => import('./components/item-category/item-category.module').then(m => m.ItemCategoryModule),
  // canActivate: [AuthGuard]
  },
  { 
    path: 'item-maintenance', 
    loadChildren: () => import('./components/item-maintenance/item-maintenance.module').then(m => m.ItemMaintenanceModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'advanced-search', 
    loadChildren: () => import('./components/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'combo-deal-maintenance', 
    loadChildren: () => import('./components/combo-deal-maintenance/combo-deal-maintenance.module').then(m => m.ComboDealMaintenanceModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'mix-match-maintenance', 
    loadChildren: () => import('./components/mix-match-maintenance/mix-match-maintenance.module').then(m => m.MixMatchMaintenanceModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'stock-transaction', 
    loadChildren: () => import('./components/stock-transaction/stock-transaction.module').then(m => m.StockTransactionModule),
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'physical-inventory', 
    loadChildren: () => import('./components/physical-inventory/physical-inventory.module').then(m => m.PhysicalInventoryModule),
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'buy-down-managers', 
    loadChildren: () => import('./components/buy-down-managers/buy-down-managers.module').then(m => m.BuyDownManagersModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'add-edit-item-details', 
    loadChildren: () => import('./components/add-edit-item-details/add-edit-item-details.module').then(m => m.AddEditItemDetailsModule),
    // canActivate: [AuthGuard] 
  },
  { path: 'price-book', 
    loadChildren: () => import('./components/price-book/price-book.module').then(m => m.PriceBookModule),
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'vendor-item', 
    loadChildren: () => import('./components/vendor-item/vendor-item.module').then(m => m.VendorItemModule),
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'multiplier', 
    loadChildren: () => import('./components/multiplier/multiplier.module').then(m => m.MultiplierModule),
    // canActivate: [AuthGuard]  
  },
  { 
    path: 'linked-item', 
    loadChildren: () => import('./components/linked-item/linked-item.module').then(m => m.LinkedItemModule),
    // canActivate: [AuthGuard]  
  },
  { 
    path: 'activate-lottery', 
    loadChildren: () => import('./components/activate-lottery/activate-lottery.module').then(m => m.ActivateLotteryModule), 
    // canActivate: [AuthGuard]
  },
  { path: 'add-lottery', 
    loadChildren: () => import('./components/add-lottery/add-lottery.module').then(m => m.AddLotteryModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'confirm-lottery', 
    loadChildren: () => import('./components/confirm-lottery/confirm-lottery.module').then(m => m.ConfirmLotteryModule), 
  // canActivate: [AuthGuard]
  },
  { 
    path: 'sales-lottery', 
    loadChildren: () => import('./components/sales-lottery/sales-lottery.module').then(m => m.SalesLotteryModule),
  // canActivate: [AuthGuard]
  },
  { 
    path: 'settlement-lottery', 
    loadChildren: () => import('./components/settlement-lottery/settlement-lottery.module').then(m => m.SettlementLotteryModule),
  // canActivate: [AuthGuard] 
  },
  { 
    path: 'vendor-invoice', 
    loadChildren: () => import('./components/vendor-invoice/vendor-invoice.module').then(m => m.VendorInvoiceModule), 
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'vendor-invoice/:id/add-item', 
    loadChildren: () => import('./components/add-vendor-invoice-item/add-vendor-invoice-item.module').then(m => m.AddVendorInvoiceItemModule), 
    // canActivate: [AuthGuard] 
  },
  { 
    path: 'add-new-vendor', 
    loadChildren: () => import('./components/add-new-vendor/add-new-vendor.module').then(m => m.AddNewVendorModule), 
    // canActivate: [AuthGuard] 
  },
  { path: 'fuel-invoice', 
    loadChildren: () => import('./components/fuel-invoice/fuel-invoice.module').then(m => m.FuelInvoiceModule),
    // canActivate: [AuthGuard]  
  },
  { path: 'edi-invoice', 
    loadChildren: () => import('./components/edi-invoice/edi-invoice.module').then(m => m.EdiInvoiceModule),
    // canActivate: [AuthGuard]
  },
  { path: 'day-recon-dashboard', 
    loadChildren: () => import('./components/day-recon-dashboard/day-recon-dashboard.module').then(m => m.DayReconDashboardModule),
    // canActivate: [AuthGuard]
  },
  { path: 'sales-by-store', 
    loadChildren: () => import('./components/sales-by-store/sales-by-store.module').then(m => m.SalesByStoreModule), 
    // canActivate: [AuthGuard]
  },
  { path: 'max-suggests-retail-price-by-manufacture', 
    loadChildren: () => import('./components/max-suggests-retail-price-by-manufacture/max-suggests-retail-price-by-manufacture.module').then(m => m.MaxSuggestsRetailPriceByManufactureModule),
    // canActivate: [AuthGuard] 
  },
  { path: 'store-snapshot-reports', 
    loadChildren: () => import('./components/store-snapshot-reports/store-snapshot-reports.module').then(m => m.StoreSnapshotReportsModule),
    // canActivate: [AuthGuard]  
  },
  { path: 'scan-dashboard', 
    loadChildren: () => import('./components/scan-dashboard/scan-dashboard.module').then(m => m.ScanDashboardModule),
  // canActivate: [AuthGuard]
  },
  { path: 'scan-data', 
    loadChildren: () => import('./components/scan-data/scan-data.module').then(m => m.ScanDataModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'scan-dashboard-pjr', 
    loadChildren: () => import('./components/scan-dashboard-pjr/scan-dashboard-pjr.module').then(m => m.ScanDashboardPjrModule),
  // canActivate: [AuthGuard]
  },
  { path: 'scan-data-rjr', 
    loadChildren: () => import('./components/scan-data-rjr/scan-data-rjr.module').then(m => m.ScanDataRjrModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'tank-management', 
    loadChildren: () => import('./components/tank-management/tank-management.module').then(m => m.TankManagementModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'fuel-pricing', 
    loadChildren: () => import('./components/fuel-pricing/fuel-pricing.module').then(m => m.FuelPricingModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'bill-payment', 
    loadChildren: () => import('./components/bill-payment/bill-payment.module').then(m => m.BillPaymentModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'delivery', 
    loadChildren: () => import('./components/delivery/delivery.module').then(m => m.DeliveryModule),
  // canActivate: [AuthGuard]  
  },
  { path: 'day-recon-reports', 
    loadChildren: () => import('./components/day-recon-reports/day-recon-reports.module').then(m => m.DayReconReportsModule),
  // canActivate: [AuthGuard] 
  },
  { path: 'fuel-reports', 
    loadChildren: () => import('./components/fuel-reports/fuel-reports.module').then(m => m.FuelReportsModule),
  // canActivate: [AuthGuard]   
  },
  { path: 'lottery-reports', 
    loadChildren: () => import('./components/lottery-reports/lottery-reports.module').then(m => m.LotteryReportsModule),
  // canActivate: [AuthGuard]
  },
  { path: 'tender-types', 
    loadChildren: () => import('./components/tender-types/tender-types.module').then(m => m.TenderTypesModule),
  // canActivate: [AuthGuard]
  },
  { 
    path: 'sample', 
    loadChildren: () => import('./components/sample/sample.module').then(m => m.SampleModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'user-management-dashboard', 
    loadChildren: () => import('./components/user-management-dashboard/user-management-dashboard.module').then(m => m.UserManagementDashboardModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'role-management', 
    loadChildren: () => import('./components/role-management/role-management.module').then(m => m.RoleManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'user-management', 
    loadChildren: () => import('./components/user-management/user-management.module').then(m => m.UserManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'live-data', 
    loadChildren: () => import('./components/live-data/live-data.module').then(m => m.LiveDataModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store-role-management', 
    loadChildren: () => import('./components/store-role-management/store-role-management.module').then(m => m.StoreRoleManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store-user-management', 
    loadChildren: () => import('./components/store-user-management/store-user-management.module').then(m => m.StoreUserManagementModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'welcome-page',
    loadChildren: () => import('./components/sample/sample.module').then(m => m.SampleModule)
  },
  { 
    path: 'fuel-retail-price', 
    loadChildren: () => import('./components/fuel-retail-price/fuel-retail-price.module').then(m => m.FuelRetailPriceModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'manufacturer', 
    loadChildren: () => import('./components/store-manufacturer/store-manufacturer.module').then(m => m.StoreManufacturerModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'bulk-update', 
  loadChildren: () => import('./components/bulk-update/bulk-update.module').then(m => m.BulkUpdateComponentModule),
  // canActivate: [AuthGuard]  
  },
  { 
    path: 'register-mapping', 
    loadChildren: () => import('./components/register-mapping/register-mapping.module').then(m => m.RegisterMappingModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'day-recon-calendar', 
    loadChildren: () => import('./components/day-recon-calendar/day-recon-calendar.module').then(m => m.DayReconCalendarModule),
    // canActivate: [AuthGuard]
  },
    {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
