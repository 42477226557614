import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AddphysicalinventoryComponent } from './addphysicalinventory/addphysicalinventory.component';
import { CONSTANTS } from 'src/app/util/constants';



export class Physicalinventory {
  public department: string;
  public ups_code: string;
  public description: string;
  public store_name: string;
  public inventory_date: string;
  public buying_cost: string;
  public checked_by: string;
  public selling_price: string;
  public physical_inventory	: string;
  public current_inventory: string;
}

@Component({
  selector: 'app-physical-inventory',
  templateUrl: './physical-inventory.component.html',
  styleUrls: ['./physical-inventory.component.scss']
})
export class PhysicalInventoryComponent implements OnInit {

  @ViewChild("addphysicalinventory") Addphysicalinventory: AddphysicalinventoryComponent;
  modelAddphysicalinventoryList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  storeList = [];
  departmentList = [];

  constructor( 
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.departmentList = await this.storeService.getStoreDepartment(data['_id']);
        this.storeService.getPhysicalinventory(data['_id']).then((data)=>{this.modelAddphysicalinventoryList=data;});
      }
    })
    
    
          
          
  }

  async reloadPhysicalinventoryTable(event) {
    // console.log("test");
    this.modelAddphysicalinventoryList = await this.storeService.getPhysicalinventory(event);
  }

  removePhysicalinventory(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removePhysicalinventory(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadPhysicalinventoryTable(store_id);
          }
        });
    }
  }

}
