import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FuelRetailPriceRoutingModule } from './fuel-retail-price-routing.module';
import { FuelRetailPriceComponent } from './fuel-retail-price.component';

import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {AddFuelPriceComponent} from './modal/addfuelretailprice/addfuelretailprice.component';

@NgModule({
  declarations: [
    FuelRetailPriceComponent,
    AddFuelPriceComponent
  ],
  imports: [
    CommonModule,
    FuelRetailPriceRoutingModule,
    FormsModule,
    NgxDatatableModule
  ]
})
export class FuelRetailPriceModule { }
