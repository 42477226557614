<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Price change by group</h4>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Select store </label>
                                <select class="form-control form-select digits"  id="exampleFormControlSelect9" >
                                    <option *ngFor="let st of storeList" [value]="st._id">
                                        {{st.store_full_name}}
                                      </option>
                                </select>
                                
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                            <div class="mb-3 theme-form">
                                <div class="job-filter">
                                    <div class="faq-form">
                                        <label>Filter by price group</label>
                                        <input class="form-control" type="text" placeholder="Search..">
                                        <a href="javascript:void(0)"><i class="icon-search"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-5 col-lg-6 col-md-6 col-12">
                            <div class="row">
                                <div class="col-xl-8">
                                    <div class="mb-3 theme-form">
                                        <label>Round of digits-retail Price</label>
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-xl-4">
                                    <button class="btn btn-primary updated-btn">Update</button>
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card">
                                <div class="card-header calender-btn">
                                    <button class="btn btn-primary add-new-btn"  (click)="addpricegroup.openModal();$event.preventDefault()">Add new price group </button>
                                    <!-- <button class="btn slect-btn">Select  all </button> -->
                                    <button class="btn slect-btn selected-btn">Update by price </button>
                                    <button class="btn slect-btn">Updated by purchase entry Margin </button>
                                </div>
                                <div class="card-body custom-datatable noscroll">
                                    <ngx-datatable class="bootstrap"  [rows]="modelPriceGroupList" [loadingIndicator]="loadingIndicator"
                                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                        [reorderable]="reorderable" [limit]="15" [selected]="selected" [selectionType]="'checkbox'" [selectAllRowsOnPage]="false" (select)='onSelect($event)'>
                                        <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                            [draggable]="false" [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true" >
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Group name" prop="name"></ngx-datatable-column>
                                        <ngx-datatable-column name="Buying cost" prop="buying_cost"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Selling Price" prop="selling_price"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Item count"  widht="100">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addpricegroup.openItemListModal(row.items);$event.preventDefault()">{{row.items.length}}</button>
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                        <ngx-datatable-column name="Purchase entry margin" prop="purchase_entry_margin"></ngx-datatable-column>
                                        <ngx-datatable-column name="Action"  widht="600">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addpricegroup.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                    <button class="delete-btn" (click)="removePriceGroup(row._id)"><i class="fa fa-trash"></i></button>
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                                <!-- <div class="card-body">
                                    
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Group name </th>
                                                    <th>buying cost</th>
                                                    <th>selling Price </th>
                                                    <th>item count </th>
                                                    <th>Purchase entry margine</th>
                                                    <th>item</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control">
                                                    </td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn"><i class="fa fa-pencil"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </form>
                <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="updatebtn">
                        <button class="back">Back</button>
                        <button>Save Settings</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-addpricegroup (sendaddedpricegroup) = 'reloadPriceGroupTable($event)' #addpricegroup></app-addpricegroup>