<ng-template #addpricegroup let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body" *ngIf="showItemList">
        <table class="table">
            <thead>
                <th scope="col">#</th>
                <th scope="col">UPC Code</th>
                <th>Item Description</th>
            </thead>

            <tr *ngFor="let itm of itemListData; let i = index; let odd = odd"
            [class.odd]="odd">
                <td>{{index+1}}</td>
                <td>{{itm.upc_code}}</td>
                <td>{{itm.item_description}}</td>
            </tr>
        </table>
    </div>
    <div class="modal-body" *ngIf="!showItemList">
        
        <form class="needs-validation" #addpricegroup="ngForm" (ngSubmit)="savepricegroup(addpricegroup)">
            <div class="form-row">
                        

                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Group name </label>
                                <input type="text" class="form-control" name="name" [(ngModel)]="modelPriceGroup.name"  #name="ngModel"
                                [ngClass]="{ 'is-invalid': addpricegroup.submitted && name.invalid }" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Buying cost </label>
                                <input type="text" class="form-control" name="buying_cost" [(ngModel)]="modelPriceGroup.buying_cost"  #buying_cost="ngModel"
                                [ngClass]="{ 'is-invalid': addpricegroup.submitted && buying_cost.invalid }" required>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Selling Price </label>
                                <input type="text" class="form-control" name="selling_price" [(ngModel)]="modelPriceGroup.selling_price"   #selling_price="ngModel"
                                [ngClass]="{ 'is-invalid': addpricegroup.submitted && selling_price.invalid }" required>
                            </div>
                        </div>
                        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Item count </label>
                                <input type="text" class="form-control" name="item_count" [(ngModel)]="modelPriceGroup.item_count"  #item_count="ngModel"
                                [ngClass]="{ 'is-invalid': addpricegroup.submitted && item_count.invalid }" required>
                            </div>
                        </div> -->
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Purchase entry margin </label>
                                <input type="text" class="form-control" name="purchase_entry_margin" [(ngModel)]="modelPriceGroup.purchase_entry_margin" #purchase_entry_margin="ngModel"
                                [ngClass]="{ 'is-invalid': addpricegroup.submitted && purchase_entry_margin.invalid }" required   >
                            </div>
                        </div>
                        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Item </label>
                                <input type="text" class="form-control" name="item_ids"  [(ngModel)]="modelPriceGroup.item_ids"  >
                            </div>
                        </div> -->
                        
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>