import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'welcome-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'store',
    loadChildren: () => import('../../components/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'store-dashboard',
    loadChildren: () => import('../../components/store-dashboard/store-dashboard.module').then(m => m.StoreDashboardModule)
  },
  {
    path: 'add-department',
    loadChildren: () => import('../../components/add-department/add-department.module').then(m => m.AddDepartmentModule)
  },
  {
    path: 'vendor',
    loadChildren: () => import('../../components/vendor/vendor.module').then(m => m.VendorModule)
  },
  {
    path: 'fuel',
    loadChildren: () => import('../../components/fuel/fuel.module').then(m => m.FuelModule)
  },
  {
    path: 'lottery',
    loadChildren: () => import('../../components/lottery/lottery.module').then(m => m.LotteryModule)
  },
  {
    path: 'pos-setup',
    loadChildren: () => import('../../components/pos-setup/pos-setup.module').then(m => m.PosSetupModule)
  },
  {
    path: 'tax-setup',
    loadChildren: () => import('../../components/tax-setup/tax-setup.module').then(m => m.TaxSetupModule)
  },
  {
    path: 'min-age-restriction',
    loadChildren: () => import('../../components/min-age-restriction/min-age-restriction.module').then(m => m.MinAgeRestrictionModule)
  },
  {
    path: 'price-group',
    loadChildren: () => import('../../components/price-group/price-group.module').then(m => m.PriceGroupModule)
  },
  {
  path: 'item-category', 
  loadChildren: () => import('./../../components/item-category/item-category.module').then(m => m.ItemCategoryModule),
// canActivate: [AuthGuard]
},
  {
    path: 'advanced-search',
    loadChildren: () => import('../../components/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule)
  },
  {
    path: 'item-maintenance',
    loadChildren: () => import('../../components/item-maintenance/item-maintenance.module').then(m => m.ItemMaintenanceModule)
  },
  {
    path: 'combo-deal-maintenance',
    loadChildren: () => import('../../components/combo-deal-maintenance/combo-deal-maintenance.module').then(m => m.ComboDealMaintenanceModule)
  },
  {
    path: 'mix-match-maintenance',
    loadChildren: () => import('../../components/mix-match-maintenance/mix-match-maintenance.module').then(m => m.MixMatchMaintenanceModule)
  },
  {
    path: 'stock-transaction',
    loadChildren: () => import('../../components/stock-transaction/stock-transaction.module').then(m => m.StockTransactionModule)
  },
  {
    path: 'physical-inventory',
    loadChildren: () => import('../../components/physical-inventory/physical-inventory.module').then(m => m.PhysicalInventoryModule)
  },
  {
    path: 'buy-down-managers',
    loadChildren: () => import('../../components/buy-down-managers/buy-down-managers.module').then(m => m.BuyDownManagersModule)
  },
  {
    path: 'bulk-update',
    loadChildren: () => import('../../components/bulk-update/bulk-update.module').then(m => m.BulkUpdateComponentModule)
  },
  {
    path: 'add-edit-item-details',
    loadChildren: () => import('../../components/add-edit-item-details/add-edit-item-details.module').then(m => m.AddEditItemDetailsModule)
  },
  {
    path: 'price-book',
    loadChildren: () => import('../../components/price-book/price-book.module').then(m => m.PriceBookModule)
  },
  {
    path: 'vendor-item',
    loadChildren: () => import('../../components/vendor-item/vendor-item.module').then(m => m.VendorItemModule)
  },
  {
    path: 'multiplier',
    loadChildren: () => import('../../components/multiplier/multiplier.module').then(m => m.MultiplierModule)
  },
  {
    path: 'linked-item',
    loadChildren: () => import('../../components/linked-item/linked-item.module').then(m => m.LinkedItemModule)
  },
  {
    path: 'activate-lottery',
    loadChildren: () => import('../../components/activate-lottery/activate-lottery.module').then(m => m.ActivateLotteryModule)
  },
  {
    path: 'add-lottery',
    loadChildren: () => import('../../components/add-lottery/add-lottery.module').then(m => m.AddLotteryModule)
  },
  {
    path: 'confirm-lottery',
    loadChildren: () => import('../../components/confirm-lottery/confirm-lottery.module').then(m => m.ConfirmLotteryModule)
  },
  {
    path: 'sales-lottery',
    loadChildren: () => import('../../components/sales-lottery/sales-lottery.module').then(m => m.SalesLotteryModule)
  },
  {
    path: 'settlement-lottery',
    loadChildren: () => import('../../components/settlement-lottery/settlement-lottery.module').then(m => m.SettlementLotteryModule)
  },
  {
    path: 'vendor-invoice',
    loadChildren: () => import('../../components/vendor-invoice/vendor-invoice.module').then(m => m.VendorInvoiceModule)
  },
  {
    path: 'vendor-invoice/:id/add-item',
    loadChildren: () => import('../../components/add-vendor-invoice-item/add-vendor-invoice-item.module').then(m => m.AddVendorInvoiceItemModule)
  },
  {
    path: 'add-new-vendor',
    loadChildren: () => import('../../components/add-new-vendor/add-new-vendor.module').then(m => m.AddNewVendorModule)
  },
  {
    path: 'fuel-invoice',
    loadChildren: () => import('../../components/fuel-invoice/fuel-invoice.module').then(m => m.FuelInvoiceModule)
  },
  {
    path: 'edi-invoice',
    loadChildren: () => import('../../components/edi-invoice/edi-invoice.module').then(m => m.EdiInvoiceModule)
  },
  {
    path: 'day-recon-dashboard',
    loadChildren: () => import('../../components/day-recon-dashboard/day-recon-dashboard.module').then(m => m.DayReconDashboardModule)
  },
  {
    path: 'sales-by-store',
    loadChildren: () => import('../../components/sales-by-store/sales-by-store.module').then(m => m.SalesByStoreModule)
  },
  {
    path: 'max-suggests-retail-price-by-manufacture',
    loadChildren: () => import('../../components/max-suggests-retail-price-by-manufacture/max-suggests-retail-price-by-manufacture.module').then(m => m.MaxSuggestsRetailPriceByManufactureModule)
  },
  {
    path: 'store-snapshot-reports',
    loadChildren: () => import('../../components/store-snapshot-reports/store-snapshot-reports.module').then(m => m.StoreSnapshotReportsModule)
  },
  {
    path: 'scan-dashboard-pjr',
    loadChildren: () => import('../../components/scan-dashboard-pjr/scan-dashboard-pjr.module').then(m => m.ScanDashboardPjrModule)
  },
  {
    path: 'scan-data-rjr',
    loadChildren: () => import('../../components/scan-data-rjr/scan-data-rjr.module').then(m => m.ScanDataRjrModule)
  },
  {
    path: 'scan-dashboard-altria',
    loadChildren: () => import('../../components/scan-dashboard/scan-dashboard.module').then(m => m.ScanDashboardModule)
  },
  {
    path: 'scan-data-altria',
    loadChildren: () => import('../../components/scan-data/scan-data.module').then(m => m.ScanDataModule)
  },
  {
    path: 'tank-management',
    loadChildren: () => import('../../components/tank-management/tank-management.module').then(m => m.TankManagementModule)
  },
  {
    path: 'fuel-pricing',
    loadChildren: () => import('../../components/fuel-pricing/fuel-pricing.module').then(m => m.FuelPricingModule)
  },
  {
    path: 'bill-payment',
    loadChildren: () => import('../../components/bill-payment/bill-payment.module').then(m => m.BillPaymentModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('../../components/delivery/delivery.module').then(m => m.DeliveryModule)
  },
  {
    path: 'day-recon-reports',
    loadChildren: () => import('../../components/day-recon-reports/day-recon-reports.module').then(m => m.DayReconReportsModule)
  },
  {
    path: 'fuel-reports',
    loadChildren: () => import('../../components/fuel-reports/fuel-reports.module').then(m => m.FuelReportsModule)
  },
  {
    path: 'lottery-reports', 
    loadChildren: () => import('../../components/lottery-reports/lottery-reports.module').then(m => m.LotteryReportsModule)
  },
  {
    path: 'tender-types', 
    loadChildren: () => import('../../components/tender-types/tender-types.module').then(m => m.TenderTypesModule)
  },
  { 
    path: 'store-sales', 
    loadChildren: () => import('../../components/store-sales/store-sales.module').then(m => m.StoreSalesModule) 
  },
  { 
    path: 'user-management-dashboard', 
    loadChildren: () => import('../../components/user-management-dashboard/user-management-dashboard.module').then(m => m.UserManagementDashboardModule) 
  },
  { 
    path: 'role-management', 
    loadChildren: () => import('../../components/role-management/role-management.module').then(m => m.RoleManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'user-management', 
    loadChildren: () => import('../../components/user-management/user-management.module').then(m => m.UserManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'live-data', 
    loadChildren: () => import('../../components/live-data/live-data.module').then(m => m.LiveDataModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'manage-promotions', 
    loadChildren: () => import('../../components/manage-promotions/manage-promotions.module').then(m => m.ManagePromotionsModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store-role-management', 
    loadChildren: () => import('../../components/store-role-management/store-role-management.module').then(m => m.StoreRoleManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'store-user-management', 
    loadChildren: () => import('../../components/store-user-management/store-user-management.module').then(m => m.StoreUserManagementModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'fuel-retail-price', 
    loadChildren: () => import('../../components/fuel-retail-price/fuel-retail-price.module').then(m => m.FuelRetailPriceModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'manufacturer', 
    loadChildren: () => import('../../components/store-manufacturer/store-manufacturer.module').then(m => m.StoreManufacturerModule),
    // canActivate: [AuthGuard]
  },
  { 
    path: 'register-mapping', 
    loadChildren: () => import('../../components/register-mapping/register-mapping.module').then(m => m.RegisterMappingModule),
    // canActivate: [AuthGuard]
  },
];
