<ng-template #addsalesrestriction let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}} Sales Tax</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class=" needs-validation" #addSalesRestriction="ngForm" (ngSubmit)="saveSalesRestriction(addSalesRestriction)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Pos Sales Restriction Name</label>
                    <input class="form-control" type="text" name="store_pos_sales_restriction_name" [(ngModel)]="modelStoreSalesRestriction.store_pos_sales_restriction_name"  required>
                </div>
                <div class="form-group col-md-6">
                    <label>Min Cust Age</label>
                    <input class="form-control" type="text" name="store_sales_restriction_min_cust_age" [(ngModel)]="modelStoreSalesRestriction.store_sales_restriction_min_cust_age" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Min Clerk Age</label>
                    <input class="form-control" type="text" name="store_sales_restriction_min_clerk_age" [(ngModel)]="modelStoreSalesRestriction.store_sales_restriction_min_clerk_age" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Transaction Limit</label>
                    <input class="form-control" type="text" name="store_sales_restriction_transaction_limit" [(ngModel)]="modelStoreSalesRestriction.store_sales_restriction_transaction_limit" required>
                </div>
                <div class="form-check form-check-inline checkbox checkbox-dark mb-0 col-md-6">
                    <input class="form-check-input" id="inline-1" type="checkbox" name="store_sales_restrict" [(ngModel)]="modelStoreSalesRestriction.store_sales_restrict" required>
                    <label class="form-check-label" for="inline-1">Transaction Sales Restrict</label>
                  </div>
                <div class="form-check form-check-inline checkbox checkbox-dark mb-0 col-md-6">
                    <input class="form-check-input" id="inline-2" type="checkbox"  name="store_pos_sales_prohibit_discount" [(ngModel)]="modelStoreSalesRestriction.store_pos_sales_prohibit_discount" required>
                    <label  class="form-check-label" for="inline-2">Prohibit Discount</label>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>