<ng-template #addstorelocation let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}} Store Fees</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class=" needs-validation" #addstorelocation="ngForm" (ngSubmit)="saveStoreLocation(addstorelocation)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Fees Name</label>
                    <input class="form-control" type="text" name="store_fees_name" [(ngModel)]="modelStoreLocation.store_fees_name" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">POS Fee Id</label>
                    <input class="form-control" type="text" name="store_pos_fees_id" [(ngModel)]="modelStoreLocation.store_pos_fees_id" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">POS Fee</label>
                    <input class="form-control" type="text" name="store_pos_fee" [(ngModel)]="modelStoreLocation.store_pos_fee" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>POS Amount Range</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_pos_amount_range" [(ngModel)]="modelStoreLocation.store_pos_amount_range"  required>
                        <option value="1-2">1-2</option>
                        <option value="2-3">2-3</option>
                        <option value="3-4">3-4</option>
                        <option value="4-5">4-5</option>
                    </select> 
                </div> -->
                <div class="form-group col-md-6">
                    <label>Is Refundable</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fees_is_refundable" [(ngModel)]="modelStoreLocation.store_fees_is_refundable"  required>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
              
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>