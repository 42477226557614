import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

export class Vendor {
    public vendor_name: string;
    public vendor_address: string;
    public vendor_contact_no: string;
    public vendor_item_name: string;
    public vendor_item_scan_code: string;
    public vendor_address2: string;
    public contact_name: string;
    public email: string;
    public fax: string;
    public state: string;
    public city: string;
    public zipcode: string;
    public account_number: string;
    public payment_method: string;
    public terms: string;
    public pos_id: string;
    public department: string;
    public default_margin: string;
    public default_bank_account: string;
    public is_employee: string;
    public is_expence: string;
    public is_customer: string;
    public is_misc: string;
    public is_mpos: string;
    public is_vendor: string;
  }

@Component({
  selector: 'app-addvendor',
  templateUrl: './addvendor.component.html',
  styleUrls: ['./addvendor.component.scss']
})
export class AddvendorComponent implements OnInit {

  @ViewChild("addvendor", { static: false }) addvendor: TemplateRef<any>;
  @Output() sendaddedvendor: EventEmitter<string> = new EventEmitter<string>();

  public closeResult: string;
  public modalOpen: boolean = false;
  modelVendor = new Vendor();
  selected_id : string;
  public modelTitle: string;
  public vendorId: string; 
  modelDepartmentList = [];
  storeBankaccountList = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
    var store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getStoreDepartment(store_id).then((data)=>{this.modelDepartmentList=data;});
    this.storeService.getBankaccountList(store_id).then((data)=>{this.storeBankaccountList=data;});
  }

  openModal(row_id?:null) {
    this.selected_id= row_id;
    if(this.selected_id != null){
        this.storeService.getVendorById(this.selected_id).then((data)=>{this.modelVendor=data;});
        this.modelTitle = "Edit";
        this.vendorId = this.selected_id;
    }else{
      this.modelVendor = new Vendor();
      this.modelTitle = "Add";
      this.vendorId = null;
    }
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addvendor, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  savevendor(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
  
   if(form.valid) { 
    this.storeService.saveVendor(form.value, this.vendorId)
          .then((response) => {
             console.log(response);
             Swal.fire('Vendor!', 'Vendor Save Successfully.', 'success');
             this.sendaddedvendor.emit(store_id);
             this.modalService.dismissAll();
          });
    }
  }


}
