import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, EventEmitter, Output  } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { User } from 'src/app/shared/classes/user';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AddUserComponent implements OnInit {
  @ViewChild("adduser", { static: false }) adduser: TemplateRef<any>;
  @Output() sendaddeduser:EventEmitter<string> = new EventEmitter<string>();
  public closeResult: string;
  public modalOpen: boolean = false;
  selected_id : string;
  public modelTitle: string;
  public itemId: string; 
  modelUser = new User();
  finaldata = [];
  selectedroleidsArr = [];

  events: Event[] = [];
  selectedItems: any;
  menupages = [];
  checkedRoleIDs = [];
  detailuser= [];
  modelRoleList = [];
  seluser_id:any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public storeService: StoreService
    ) { }

  ngOnInit(): void {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
  }

  isChecked(eventId){
    return this.selectedroleidsArr.indexOf(eventId)>-1 ? true : null
  }

  openModal(row_id?:null) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    this.storeService.getRoles(store_id).then((data)=>{this.modelRoleList=data;});
    this.finaldata = [];
    this.selectedroleidsArr = [];
   
    this.selected_id= row_id;

    if(this.selected_id != null){
      this.storeService.getUserRoles(this.selected_id).then((resdata)=>{
        resdata.forEach((value, key) => {
          console.log("value.role_id",value.role_id);
          
          this.selectedroleidsArr.push(value.role_id);
        });
      });
      this.storeService.getUserById(this.selected_id).then((data)=>{
        this.modelUser=data;
        // this.selectedroleidsArr = data['role_id'];
        // let roleids = data['role_id'];
        // this.selectedroleidsArr = roleids.split(",");
      });
      this.modelTitle = "Edit User";
      this.itemId = this.selected_id;
      this.checkedRoleIDs = this.selectedroleidsArr;
    }else{
      this.modelUser = new User();
      //this.modelUser['userdetail']['fullname'] = '';
      //this.modelUser['userdetail']['email'] = '';
      this.modelTitle = "Add User";
      this.itemId = null;
    }
    
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.adduser, {
        size: 'lg',
        ariaLabelledBy: 'modal-bookmark',
        centered: true,
        windowClass: 'modal-bookmark'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  saveuser(form) {
    //user_id = '';
    let store_id = JSON.parse(localStorage.getItem('store_id'));
    if(form.valid) {
      let frmvalues = {};
    frmvalues =   {
      "firstName":form.value.firstName,
      "lastName":form.value.lastName,
      "fullname":form.value.firstName+" "+form.value.lastName,
      "email":form.value.email,
      "displayName":form.value.firstName+" "+form.value.lastName,
      "password":form.value.newpassword
    }

    this.storeService.saveUser(frmvalues, this.itemId)
          .then((response) => {
            if(this.itemId !=null){
              this.seluser_id = this.itemId;
            }else{
              this.seluser_id = response._id;
            }
            this.storeService.deleteuserroles(store_id, this.seluser_id).then((response) => {
              
            });
            this.checkedRoleIDs.map((value1, key1) => {
              this.detailuser[key1] = {"role_id":value1,"user_id":this.seluser_id};
            });
            // console.log(this.detailuser);
            // return false;
            
            
            this.storeService.saveUserRole(this.detailuser, null).then((perresponse) => {
            });

            Swal.fire('User!', 'User Save Successfully.', 'success');
            this.sendaddeduser.emit(store_id);
            this.modalService.dismissAll();
          });
    }
  }
  fetchPerCheckedIDs($event) {
  }
  changeSelection(evt) {

    if(evt.target.checked){
      this.checkedRoleIDs.push(evt.target.value);
    }
    if(!evt.target.checked){
      var xchkid = this.checkedRoleIDs.indexOf(evt.target.value);
       this.checkedRoleIDs.splice(xchkid,1);
    }
    console.log(this.checkedRoleIDs);
    
  }
}
