import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StockTransactionRoutingModule } from './stock-transaction-routing.module';
import { StockTransactionComponent } from './stock-transaction.component';
import { AddstocktransactionComponent } from './modal/addstocktransaction/addstocktransaction.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    StockTransactionComponent,
    AddstocktransactionComponent
  ],
  imports: [
    CommonModule,
    StockTransactionRoutingModule,
    FormsModule,
    NgxDatatableModule
  ]
})
export class StockTransactionModule { }
