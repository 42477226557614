<ng-template #addfuelgrade let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}} Fuel Grade</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class=" needs-validation" #addfuelgrade="ngForm" (ngSubmit)="saveFuelGrade(addfuelgrade)">
            <div class="form-row">
                <div class="form-check form-check-inline checkbox checkbox-dark mb-0 col-md-6">
                    <input class="form-check-input" id="inline-1" type="checkbox" (click)="enableratiofields($event)" name="is_blend" [(ngModel)]="modelStoreFuelGrade.is_blend">
                    <label class="form-check-label" for="inline-1">Is Blend</label>
                </div>
                <div class=" row m-0 p-tb" *ngIf="isblend" >
                    <div class="col-xl-12">
                        <strong>Add blend details</strong>
                    </div>
                    
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="mb-3 theme-form">
                            <label>Set Ratio</label>
                            <input class="form-control" type="text" name="blend_ratio1" [(ngModel)]="modelStoreFuelGrade.blend_ratio1" #blend_ratio1="ngModel"
                            [ngClass]="{ 'is-invalid': addfuelgrade.submitted && blend_ratio1.invalid }" required>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="mb-3 theme-form">
                            <label>Set Ratio</label>
                            <input class="form-control" type="text" name="blend_ratio2" [(ngModel)]="modelStoreFuelGrade.blend_ratio2"  #blend_ratio2="ngModel"
                            [ngClass]="{ 'is-invalid': addfuelgrade.submitted && blend_ratio2.invalid }" required>
                        </div>
                    </div>
                </div>
                
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Select fuel grade</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fuel_grade" [(ngModel)]="modelStoreFuelGrade.store_fuel_grade" #store_fuel_grade="ngModel"
                    [ngClass]="{ 'is-invalid': addfuelgrade.submitted && store_fuel_grade.invalid }" required>
                        <option value="">Select</option>
                        <option value="Regular">Regular</option>
                        <option value="Super">Super</option>
                        <option value="Premium">Premium </option>
                        <option value="Diesel">Diesel</option>
                        <option value="Plus">Plus</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Store fuel department</label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fuel_department" [(ngModel)]="modelStoreFuelGrade.store_fuel_department" #store_fuel_department="ngModel"
                    [ngClass]="{ 'is-invalid': addfuelgrade.submitted && store_fuel_department.invalid }" required>
                        <option value="">Select</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="">Other</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Select fuel tax</label>
                     <select class="form-control form-select digits" id="exampleFormControlSelect9" name="store_fuel_tax" [(ngModel)]="modelStoreFuelGrade.store_fuel_tax"  #store_fuel_tax="ngModel"
                    [ngClass]="{ 'is-invalid': addfuelgrade.submitted && store_fuel_tax.invalid }"  required>
                    <option value="">Select</option>
                    <option *ngFor="let taxrow of fueltaxlist" [value]="taxrow._id">
                        {{taxrow.store_fuel_tax_description}}
                      </option>
                    
                    </select>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Fuel grade ID</label>
                    <input class="form-control" type="text" name="store_fuel_grade_id" [(ngModel)]="modelStoreFuelGrade.store_fuel_grade_id"  #store_fuel_grade_id="ngModel"
                    [ngClass]="{ 'is-invalid': addfuelgrade.submitted && store_fuel_grade_id.invalid }" required>
                 </div> -->
                <div class="form-group col-md-6">
                    <label>Fuel grade display name</label>
                    <input class="form-control" type="text" name="store_fuel_grade_display_name" [(ngModel)]="modelStoreFuelGrade.store_fuel_grade_display_name"  #store_fuel_grade_display_name="ngModel"
                    [ngClass]="{ 'is-invalid': addfuelgrade.submitted && store_fuel_grade_display_name.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect9">Tank Size</label>
                    <input type="text" class="form-control" placeholder="0.00" name="tank_size"
                        [(ngModel)]="modelStoreFuelGrade.tank_size" #tank_size="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelgrade.submitted && tank_size.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Pre-Paid Sales Tax</label>
                    <input type="text" class="form-control" placeholder="0.00" name="prepaid_sales_tax"
                        [(ngModel)]="modelStoreFuelGrade.prepaid_sales_tax" #prepaid_sales_tax="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelgrade.submitted && prepaid_sales_tax.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Tax Rate</label>
                    <input type="text" class="form-control" placeholder="0.00" name="tax_rate"
                        [(ngModel)]="modelStoreFuelGrade.tax_rate" #tax_rate="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelgrade.submitted && tax_rate.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>UST Fees</label>
                    <input type="text" class="form-control" placeholder="0.00" name="ust_fees"
                        [(ngModel)]="modelStoreFuelGrade.ust_fees" #ust_fees="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelgrade.submitted && ust_fees.invalid }" required>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label>Register Mapping</label>
                    <input type="text" class="form-control" placeholder="0.00" name="register_mapping"
                        [(ngModel)]="modelStoreFuelGrade.register_mapping" #register_mapping="ngModel"
                        [ngClass]="{ 'is-invalid': addfuelgrade.submitted && register_mapping.invalid }" required>
                </div> -->
                
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" >Save</button>
                    <button class="btn btn-secondary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    
                </div>
            </div>
        </form>
    </div>
</ng-template>