import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ReactiveFormsModule  } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public show: boolean = false;
  public resetpasswordForm: FormGroup;
  public errorMessage: any;
  public otpsent: boolean = false;
  public changepasswordForm: FormGroup;

  constructor(public authService: AuthService, private fb: FormBuilder,public router: Router,) {
    this.resetpasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.changepasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  ngOnInit() {
    // console.log("login compoent");
  }
  
  async resetpassword(){  
        var user = await this.authService.ForgotAdminPassword(this.resetpasswordForm.value).then((response) => {
          if(response.statusCode){
            Swal.fire('Error', response.message, 'error');
          }else{
            Swal.fire('Thank You!', 'Please check your email for otp', 'success');
            this.otpsent = true;
          }
          
        });;

    
  }

  async validateotp(){
    if(this.changepasswordForm.value["newPassword"] == this.changepasswordForm.value["confirmPassword"]){
      var user = await this.authService.resetAdminPassword(this.changepasswordForm.value).then((response) => {
        
        if(response.statusCode){
          Swal.fire('Error', response.message, 'error');
        }else{
          Swal.fire('Thank You!', response.message, 'success');
          this.router.navigate(['/admin-login']);
        }
        
      }).catch((error) => {
        console.log(error);
        
      });
    }
  }
}
