<ng-template #addsalestax let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}} Sales Tax</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class=" needs-validation" #addSalesTax="ngForm" (ngSubmit)="saveSalesTax(addSalesTax)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Tax name</label>
                    <input class="form-control" type="text" name="store_sales_tax_name" [(ngModel)]="modelStoreSalesTax.store_sales_tax_name"  required>
                </div>
                <div class="form-group col-md-6">
                    <label>POS tax</label>
                    <input class="form-control" type="text" name="store_sales_pos_tax" [(ngModel)]="modelStoreSalesTax.store_sales_pos_tax" required>
                </div>
                <div class="form-group col-md-6">
                    <label>City tax</label>
                    <input class="form-control" type="text" name="store_sales_city_tax" [(ngModel)]="modelStoreSalesTax.store_sales_city_tax" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Country tax</label>
                    <input class="form-control" type="text" name="store_sales_country_tax" [(ngModel)]="modelStoreSalesTax.store_sales_country_tax" required>
                </div>
                <div class="form-group col-md-6">
                    <label>State tax</label>
                    <input class="form-control" type="text" name="store_sales_state_tax" [(ngModel)]="modelStoreSalesTax.store_sales_state_tax" required>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>