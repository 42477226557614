import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CONSTANTS } from 'src/app/util/constants';
import * as _ from 'lodash';

import {AddFuelPriceComponent} from './modal/addfuelretailprice/addfuelretailprice.component';

class fuelRetailPrice{
  public current_cash_price:string;
  public new_cash_price:string
  public current_credit_price:string
  public new_credit_price:string
  public price_changes_date:string
}

@Component({
  selector: 'app-fuel-retail-price',
  templateUrl: './fuel-retail-price.component.html',
  styleUrls: ['./fuel-retail-price.component.scss']
})
export class FuelRetailPriceComponent implements OnInit {
  @ViewChild("addfuelretailprice") AddFuelPrice: AddFuelPriceComponent;
  storeList = [];
  modelFuelGradeList = [];
  modelFuelPrice = new fuelRetailPrice();
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];
  public selected = [];
  new_credit_price_ctrl = {};
  new_cash_price_ctrl  = {};
  constructor(
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.storeService.getStoreFuelGrade(data['_id']).then((resdata)=>{
          this.modelFuelGradeList = resdata;
         })
      }
    })
  }

  async reloadTable(event){
    this.modelFuelGradeList = await this.storeService.getStoreFuelGrade(event);
  }
  savefuelretailprice(form) {
    let store_id = JSON.parse(localStorage.getItem('store_id'));

    if(form.valid) {
      this.storeService.updatefuelretailprices(form.value,null)
            .then((response) => {
              console.log(response);
              Swal.fire('Retail Price!', 'Retail Price Updated Successfully.', 'success');
              form.reset();
              this.reloadTable(store_id);
            });
      }
  }


}
