<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <form #itemForm="ngForm" (ngSubmit)="onSubmitAdvanceSearch($event,itemForm)">
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Bulk Updates</h4>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Scan Code </label>
                                <input class="form-control" name="upc_code" type="text"
                                    [(ngModel)]="itemModel.upc_code" #upc_code="ngModel">
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Modifier</label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9"
                                    name="modifier" ngModel>
                                    <option [value]="1">Single Item</option>
                                    <option [value]="2">2</option>
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                    <option [value]="5">5</option>
                                    <option [value]="6">6</option>
                                    <option [value]="7">7</option>
                                    <option [value]="8">8</option>
                                    <option [value]="9">9</option>
                                    <option [value]="10">10</option>
                                    <option [value]="11">11</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Item Name</label>
                                <input class="form-control" name="item_description" type="text"
                                    [(ngModel)]="itemModel.item_description">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Department</label>
                                <select class="form-control form-select digits" name="department"
                                    [(ngModel)]="itemModel.department" #department="ngModel">
                                    <option [value]="null"></option>
                                    <option *ngFor="let department of departmentList" [value]="department._id">
                                        {{department.department_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Tax Type</label>
                                <select class="form-control form-select digits" name="tax_rate"
                                    id="exampleFormControlSelect9" [(ngModel)]="itemModel.tax_rate">
                                    <option [value]="'0'">Non Tax</option>
                                    <option [value]="'10'">Tax</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Min Price</label>
                                <input class="form-control" type="number" name="min_price" [(ngModel)]="min_price">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Max Price</label>
                                <input class="form-control" type="number" name="max_price" [(ngModel)]="max_price">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Price Group</label>
                                <select class="form-control form-select digits" name="price_group"
                                    [(ngModel)]="itemModel.price_group" #price_group="ngModel"
                                    [ngClass]="{ 'is-invalid': itemForm.submitted && price_group.invalid }">
                                    <option *ngFor="let itmpricegroup of pricegroupList" [value]="itmpricegroup._id">
                                        {{itmpricegroup.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Age restriction</label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9" name="">
                                    <option [value]="">Alcohol ID 21</option>
                                    <option [value]="">Tobacco ID 21</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Food Stamp</label>
                                <select class="form-control form-select digits" name="food_stamp_sales"
                                    id="exampleFormControlSelect9" [(ngModel)]="itemModel.food_stamp_sales">
                                    <option [value]="true">Yes</option>
                                    <option [value]="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Vendor</label>
                                <select class="form-control form-select digits" name="payee"
                                    [(ngModel)]="itemModel.payee" #payee="ngModel">
                                    <option value="">Select</option>
                                    <option *ngFor="let storeVendor of storeVendorList" [value]="storeVendor._id">
                                        {{storeVendor.vendor_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Linked Item</label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9" name="">
                                    <option>Select 1</option>
                                    <option>Select 2</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Min Margin </label>
                                <input class="form-control" name="min_margin" type="text">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Max Margin</label>
                                <input class="form-control" name="max_margin" type="text">
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="updatebtn">
                                <!-- <button class="back">Back</button> -->
                                <button>Search</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="card-body custom-datatable noscroll">
                        <ngx-datatable class="bootstrap" [rows]="itemList" [loadingIndicator]="loadingIndicator"
                            [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                            [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                            [reorderable]="reorderable">
                            <ngx-datatable-column name="Scan code" prop="upc_code" [width]="120"></ngx-datatable-column>

                            <ngx-datatable-column name="Item name" prop="item_description" [width]="180">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <input type="text" #item_description name="item_description" [value]="value"
                                        (change)="onChangeStoreItemData(row, item_description.name, item_description.value)"
                                        style="width: 100%;">
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Department" prop="department" [width]="170">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" #department name="department"
                                        [value]="value"
                                        (change)="onChangeStoreItemData(row, department.name, department.value)">
                                        <option *ngFor="let department of departmentList" [value]="department._id"
                                            [selected]="department._id === value">
                                            {{department.department_name}}
                                        </option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Tax type" prop="tax_rate">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" #tax_rate name="tax_rate" [value]="value"
                                        (change)="onChangeStoreItemData(row, tax_rate.name, tax_rate.value)">
                                        <option [value]="'0'" [selected]="tax_rate === 0">Non Tax
                                        </option>
                                        <option [value]="'10'" [selected]="tax_rate !== 0">Tax
                                        </option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column name="Price Group" prop="price_group">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" #price_group name="price_group"
                                        [value]="value"
                                        (change)="onChangeStoreItemData(row, price_group.name, price_group.value)">
                                        <option [value]="null"></option>
                                        <option *ngFor="let itmpricegroup of pricegroupList" [value]="itmpricegroup._id"
                                            [selected]="itmpricegroup._id === value">
                                            {{itmpricegroup.name}}
                                        </option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Foodstamp" prop="food_stamp_sales">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" #food_stamp_sales name="food_stamp_sales"
                                        [value]="value"
                                        (change)="onChangeStoreItemData(row, food_stamp_sales.name, food_stamp_sales.value)">
                                        <option [value]="true" [selected]="food_stamp_sales">Yes</option>
                                        <option [value]="false" [selected]="food_stamp_sales">No</option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Age restiction" prop="to_date">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" name="" [value]="value">
                                        <option [value]="'Alcohol ID 21'">Alcohol ID 21</option>
                                        <option [value]="'Tobacco ID 21'">Tobacco ID 21</option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Linked Item" prop="linked_item">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <select class="form-control form-select" #linked_item name="linked_item"
                                        [value]="value">
                                        <option [value]="">Select 1</option>
                                        <option [value]="">Select 2</option>
                                    </select>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Cost" prop="case_cost" [width]="100">
                                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <input class="form-control" style="width: 100%" #case_cost type="text"
                                        name="case_cost" [value]="value"
                                        (change)="onChangeStoreItemData(row, case_cost.name, case_cost.value)">
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Price" prop="case_cost" [width]="100">
                                <!-- <ng-template let-value="value" let-rowIndex="rowIndex"
                                    ngx-datatable-cell-template>
                                    <input class="form-control" style="width: 100%" type="text" name=""
                                        [value]="value">
                                </ng-template> -->
                            </ngx-datatable-column>

                        </ngx-datatable>
                        <div class="updatebtn">
                            <button class="mr-1" (click)="onSubmitSaveSettingItemData()">Save Settings</button>
                            <button class="back">Back</button>
                        </div>
                    </div>
                    <!-- </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-12"> -->

                </div>
            </div>
        </div>
    </div>
</div>