import { Injectable } from '@angular/core';
import { CONSTANTS } from '../util/constants';
import { HttpService } from '../shared/services/http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs'; 
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})

export class FileuploadService {
    constructor(
        private httpClient: HttpClient,
        private constants: CONSTANTS,
        private httpServ: HttpService
    ) {
        this.httpServ = httpServ,
        this.constants = constants
    }

    uploadfile(data){
        
       

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFILE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);

        // let apiurlphoto = this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFILE;
        // return this.httpClient.post(apiurlphoto, formParams)
    }
}