import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { NavService, Menu } from 'src/app/shared/services/nav.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CONSTANTS } from 'src/app/util/constants';
import { AddbuydownmanagersComponent } from './addbuydownmanagers/addbuydownmanagers.component';
import * as _ from 'lodash';

export class Buydownmanager {
  public store_name: string;
  public manufacturer_name: string;
  public item_code: string;
  public item_name: string;
  public discount_given_by_manufacturer: string;
  public actual_price_of_item: string;
  public from_date: string;
  public to_date: string;
  public description: string;
}

@Component({
  selector: 'app-buy-down-managers',
  templateUrl: './buy-down-managers.component.html',
  styleUrls: ['./buy-down-managers.component.scss']
})
export class BuyDownManagersComponent implements OnInit {

  @ViewChild("addbuydownmanagers") AddBuydownmanagers: AddbuydownmanagersComponent;
  modelAddBuydownmanagerList = [];

  buydownmanagerlist = []

  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [];

  constructor(
    public storeService: StoreService,
    public navServices: NavService,
    public constants: CONSTANTS,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.navServices.storeName.subscribe(async (data) => {
      if (data['_id'] != undefined) {
        localStorage.setItem('store_id', JSON.stringify(data['_id']));
        this.modelAddBuydownmanagerList = await this.storeService.getBuydownmanager(data['_id']);
        this.buydownmanagerlist = this.modelAddBuydownmanagerList;
        console.log(this.buydownmanagerlist[0])
      }
    })
  }



  async reloadBuydownmanagerTable(event) {
    // console.log("test");
    this.modelAddBuydownmanagerList = await this.storeService.getBuydownmanager(event);
  }

  removeBuydownmanager(id: string) {
    console.log(id);
    if (confirm("Do you want to delete this record?")) {
      this.storeService.removeBuydownmanager(id)
        .then((res) => {
          if (res.deletedCount == 1) {
            let store_id = JSON.parse(localStorage.getItem('store_id'));
            this.reloadBuydownmanagerTable(store_id);
          }
        });
    }
  }

  onClickSearch(inputData) {
    if (!_.isEmpty(inputData)) {
      this.modelAddBuydownmanagerList = _.filter(this.buydownmanagerlist, (item) => {
        return item.store.store_name.includes(inputData) ||
          item.manufacturer.name.includes(inputData) ||
          item.storeitem.upc_code.includes(inputData) ||
          item.storeitem.item_description.includes(inputData)
      })

    } else {
      this.modelAddBuydownmanagerList = this.buydownmanagerlist
    }
  }

}
