<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Item Category </h4>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <button class="btn btn-primary updated-btn"  (click)="additemcategory.openModal();$event.preventDefault()">Add Item Category</button>
                            </div>
                        </div>
                        <div class="card-body custom-datatable noscroll">
                            <ngx-datatable class="bootstrap"  [rows]="modelItemCategoryList" [loadingIndicator]="loadingIndicator"
                                [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                [reorderable]="reorderable" [limit]="15">
                                <ngx-datatable-column name="Category Name" prop="name"></ngx-datatable-column> 
                                
                                <ngx-datatable-column name="Action" widht="600">    
                                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                        <div class="trlistBtn">
                                            <button class="edit-btn" (click)="additemcategory.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                            <button class="delete-btn" (click)="removeItemCategory(row._id)"><i class="fa fa-trash"></i></button>
                                        </div>   
                                    </ng-template>    
                                </ngx-datatable-column> 
                            </ngx-datatable>
                        </div>

                    </div>
                </form>
                
            </div>
        </div>
    </div>
</div>
<app-additemcategory (sendaddeditemcategory) = 'reloadItemCategoryTable($event)' #additemcategory></app-additemcategory>