<div class="container-fluid pdlr">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="live-data_main">

                <div class="row m-0 p-tb">
                    <div class="col-xl-6 col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h5>Transaction Summary</h5>
                            </div>
                            <div class="card-body custom-datatable noscroll">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12" style="padding: 0;">
                                        <div class="mb-3 theme-form">
                                            <select _ngcontent-jql-c190="" name="department"
                                                class="form-control form-select digits ng-pristine ng-valid ng-touched"
                                                (change)="reloadTransactionTable('store',$event)">
                                                <option *ngFor="let st of storeList" [value]="st._id"
                                                    [selected]="selectedstoreid == st._id">
                                                    {{st.store_full_name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12"
                                        style="padding: 0; padding-left: 10px;">
                                        <div class="mb-3 theme-form">
                                            <select _ngcontent-jql-c190="" name="department"
                                                class="form-control form-select digits ng-pristine ng-valid ng-touched"
                                                (change)="reloadTransactionTable('station',$event)">
                                                <option value="all">All</option>
                                                <option *ngFor="let station of station_list" value="{{station}}">
                                                    {{station}}</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12"
                                        style="padding: 0; padding-left: 10px;">
                                        <div class="mb-3 theme-form">
                                            <select _ngcontent-jql-c190="" name="department"
                                                class="form-control form-select digits ng-pristine ng-valid ng-touched"
                                                (change)="reloadTransactionTable('transaction_type',$event)">
                                                <option value="all">All Type</option>
                                                <option value="VoidEvent">Void</option>
                                                <option value="NoSaleEvent">No Sale</option>
                                                <option value="LineVoidEvent">Line void</option>
                                                <option value="RefundEvent">Refund</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12"
                                        style="padding: 0; padding-left: 10px;">
                                        <div class="mb-3 theme-form">
                                            <select _ngcontent-jql-c190="" name="department"
                                                class="form-control form-select digits ng-pristine ng-valid ng-touched"
                                                (change)="reloadTransactionTable('transaction_time',$event)">

                                                <option value="all">All</option>
                                                <option value="50"> Last 50</option>
                                                <option value="5min"> Last 5 Minutes</option>
                                                <option value="1hour"> Last Hour</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive table_detail">
                                    <table class="table">
                                        <tbody>
                                            <tr [class.trclsactive]="selectedIndex === index" class="td_main"
                                                *ngFor="let trow of displayStoreTransactionList;let index = index;"
                                                (click)="show_tansaction_detail(trow['TransactionID'],index)">
                                                <td *ngIf="sales_events.indexOf(trow['EventType'])">
                                                    <p class="td_title"
                                                        [ngClass]="{'linevoid':trow['EventType']=='LineVoidEvent' || trow['EventType'] == 'VoidEvent'}">
                                                        {{selstorename}}<br>
                                                        <span>{{trow['EventEndDate'] | date: 'MM/dd/yyyy'}}
                                                            {{trow['EventEndTime']}}</span>
                                                    </p>
                                                </td>
                                                <!-- <td *ngIf="sales_events.indexOf(trow['EventType'])"  [ngClass]="{'linevoid':trow['EventType']=='LineVoidEvent'}"> -->
                                                <td [ngClass]="{'linevoid':trow['EventType']=='LineVoidEvent' || trow['EventType'] == 'VoidEvent'}">
                                                    ${{trow['TransactionTotalNetAmount']}}</td>
                                                <td><i class="fa fa-angle-right pull-right"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h5>Transaction Detail</h5>
                            </div>
                            <div class="card-body custom-datatable noscroll">

                                <div class="table-responsive Critical-table invoice_detail">
                                    <table class="table table-bordered table-border" *ngIf="show_transaction">
                                        <thead class="bgt" *ngIf="sales_events.indexOf(transactiondetail['EventType'])">
                                            <tr>
                                                <th colspan="3" class="text-center sizetwenty time_span">
                                                    {{selstorename}}
                                                    <span>{{transactiondetail['EventEndDate'] | date: 'MM/dd/yyyy'}}
                                                        {{transactiondetail['EventEndTime']}}</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <thead class="bgt" *ngIf="sales_events.indexOf(transactiondetail['EventType'])">
                                            <ng-template [ngIf]="transactiondetail['EventType'] == 'VoidEvent'">
                                                <span class="voideventwatermark">VOID</span>
                                            </ng-template>
                                            <tr class="border-bottom-none">
                                                <th class="border-bottom-none" colspan="2" class="border-right-none">
                                                    Employee: {{transactiondetail['CashierID']}}</th>
                                                <th class="border-bottom-none" class="border-left-none">
                                                    Station: {{transactiondetail['RegisterID']}}</th>
                                            </tr>
                                        </thead>

                                        <tbody *ngIf="sales_events.indexOf(transactiondetail['EventType'])">
                                                <ng-container
                                                *ngFor="let indvtrow of filterTransactionArray(transactiondetail['TransactionLine']);">

                                                    <ng-template [ngIf]="indvtrow['FuelLine']">
                                                        <tr>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-right-none border-top-none linevoid line-through' : 'border-right-none border-top-none'">
                                                                {{indvtrow['FuelLine']['Description']}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none border-right-none linevoid line-through' : 'border-left-none border-top-none border-right-none'">
                                                                {{indvtrow['FuelLine']['SalesQuantity']}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none linevoid line-through' : 'border-left-none border-top-none '">
                                                                $ {{indvtrow['FuelLine']['SalesAmount']}}</td>
                                                        </tr>
                                                    </ng-template>


                                                    <ng-template [ngIf]="indvtrow['FuelPrepayLine']">
                                                        <tr>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-right-none border-top-none linevoid line-through' : 'border-right-none border-top-none'">
                                                                Prepaid Fuel
                                                            </td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none border-right-none linevoid line-through' : 'border-left-none border-top-none border-right-none'">
                                                            </td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none linevoid line-through' : 'border-left-none border-top-none '">
                                                                $ {{indvtrow['FuelPrepayLine']['SalesAmount']}}</td>
                                                        </tr>

                                                    </ng-template>


                                                    <ng-template [ngIf]="indvtrow['ItemLine']">
                                                        <tr>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-right-none border-top-none linevoid line-through' : 'border-right-none border-top-none'">
                                                                {{indvtrow['ItemLine']['Description']}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none border-right-none linevoid line-through' : 'border-left-none border-top-none border-right-none'">
                                                                    x {{indvtrow['ItemLine']['SalesQuantity']| number : '1.0-0'}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none linevoid line-through' : 'border-left-none border-top-none '">
                                                                $ {{indvtrow['ItemLine']['SalesAmount']}}</td>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template [ngIf]="indvtrow['MerchandiseCodeLine']">
                                                        <tr>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-right-none border-top-none linevoid line-through' : 'border-right-none border-top-none'">
                                                                {{indvtrow['MerchandiseCodeLine']['Description']}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none border-right-none linevoid line-through' : 'border-left-none border-top-none border-right-none'">
                                                                    x {{indvtrow['MerchandiseCodeLine']['SalesQuantity']| number : '1.0-0'}}</td>
                                                            <td [ngClass]="indvtrow['@status'] == 'cancel' ? 'border-left-none border-top-none linevoid line-through' : 'border-left-none border-top-none '">
                                                                $ {{indvtrow['MerchandiseCodeLine']['SalesAmount']}}</td>
                                                        </tr>
                                                    </ng-template>
                                                </ng-container>
                                            
                                            <tr>
                                                <td class="bold-list" style="padding-top:15px" colspan="3">Total</td>
                                            </tr>
                                            <tr>
                                                <td class="bold-list" colspan="3">Gross:
                                                    $ {{transactiondetail['TransactionTotalGrossAmount']}}</td>
                                            </tr>
                                            <tr>
                                                <td class="bold-list" colspan="3">Tax:
                                                    $ {{transactiondetail['TransactionTotalTaxNetAmount'] * 1}}</td>
                                            </tr>
                                            <tr>
                                                <td class="bold-list" style="padding-bottom:15px" colspan="3">Net:
                                                    $ {{transactiondetail['TransactionTotalNetAmount']}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="card">
                        <div class="card-header calender-btn">
                            <h5>Sales and Inventory Summary</h5>
                        </div>
                        <div class="card-body custom-datatable noscroll">
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Store Name</th>
                                            <th colspan="7" class="text-center">Total sales</th>
                                            <th colspan="4" class="text-center">Ending inventory&nbsp;(Vol)&nbsp;&nbsp;
                                            </th>
                                            <!-- <th>&nbsp;</th> -->
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>Total Sales</th>
                                            <th>Grocery Sales</th>
                                            <th>Gas(Vol)</th>
                                            <th>No Sale</th>
                                            <th>Line Void</th>
                                            <th>Void</th>
                                            <th>Refunds</th>
                                            <th>Regular</th>
                                            <th>Plus</th>
                                            <th>Super</th>
                                            <th>Diesel</th>
                                            <!-- <th>&nbsp;</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let summary of livesummarydatareport">
                                            <ng-template [ngIf]="summary[summarystoreid]">
                                                <td>
                                                    <p class="m-0">{{selstorename}}</p>
                                                    <small>
                                                        <span> Last transaction at
                                                            {{summary[summarystoreid].lasttransactiontime}}
                                                            <!-- <button class="btn btn-default btn-xs fix_btn">Fix</button> -->
                                                        </span>
                                                    </small>
                                                </td>
                                                <td>$ {{summary[summarystoreid].totalsales}}</td> <!-- total sales -->
                                                <td>$ {{summary[summarystoreid].grocerySales}}</td>
                                                <!-- Grocery sales -->
                                                <td>{{summary[summarystoreid].gasvolume}}</td> <!-- Gas vol -->
                                                <td>0</td> <!--No sale-->
                                                <td>{{summary[summarystoreid].linevoid}}</td> <!--line void-->
                                                <td>{{summary[summarystoreid].voidevents}}</td> <!--void event-->
                                                <td>0</td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                                <td>0.00</td>
                                            </ng-template>

                                            <!-- <td>
                                            <div class="dropdown pull-right">
                                                <button class="btn btn-primary dropdown-toggle act_btn" type="button" data-toggle="dropdown" (click)="settingMenuToggle()"><i class="fa fa-cog">&nbsp;</i>
                                                <span class="caret"></span></button>
                                                <ul class="dropdown-menu" [class]="settinglevelMenu ? 'set-d-block' : 'set-d-none'">
                                                  <li><a href="#">Set Department Type</a></li>
                                                  <li><a href="#">Set Gas Grade</a></li>
                                                </ul>
                                            </div>
                                         </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>