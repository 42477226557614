import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserManagementComponent } from './user-management.component';
import { FormsModule } from '@angular/forms';
import { AddUserComponent } from './modal/adduser/adduser.component';

@NgModule({
  declarations: [
    UserManagementComponent,
    AddUserComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    UserManagementRoutingModule,
    NgxDatatableModule
  ]
})
export class UserManagementModule { }
