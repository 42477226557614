<ng-template #addphysicalinventory let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}} Physical Inventory</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addphysicalinventory="ngForm" (ngSubmit)="savePhysicalinventory(addphysicalinventory)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Department</label>
                    <select class="form-control form-select digits" name="department" [(ngModel)]="modelPhysicalinventory.department"  #department="ngModel"
                    [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && department.invalid }" required>
                        <option value="">Select</option>
                        <option *ngFor="let depl of departmentList" [value]="depl._id">
                          {{depl.department_name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label>Description</label>
                    <input class="form-control" type="text" name="description" [(ngModel)]="modelPhysicalinventory.description" >
                </div>
                <div *ngIf="invryId == null" class="row form-group col-md-12">
                    <div class="col-md-12 ">
                        <label>Select Item code / Price Group</label>
                    </div>
                    
                    <div class="mt-2 ml-2 col-md-4 form-group">
                        <div class="radio radio-primary radio_item">
                            <input  id="itmcode" type="radio" name="buydownfor" (click)="showIemCodeSection()" [(ngModel)]="modelPhysicalinventory.selectedBy" value="ItemCode" required
                            #selectedBy="ngModel" required>
                            <label  for="itmcode">Item Code</label>
                        </div>
                    </div>
                    <div class="mt-2 ml-2 col-md-4 form-group">
                        <div class="radio radio-primary radio_item">
                            <input  id="pricegrp" type="radio" name="buydownfor" (click)="showPriceGrpSection()" [(ngModel)]="modelPhysicalinventory.selectedBy" value="PriceGroup" required
                            #selectedBy="ngModel" required>
                            <label  for="pricegrp">Price Group</label>
                        </div>
                    </div>
                    <div *ngIf="selectedBy.errors?.required" class="ml-3 text-danger">Please Select Item code / Price Group</div>
                </div>
                <div *ngIf="itmcode" class="form-group col-md-6">
                    <label>UPC Code / Description </label>
                    
                            <ng-select name="item_list_name" [multiple]="false" [(ngModel)]="modelPhysicalinventory.item_id"  (search)="onSearch($event)" (remove)="onRemove($event)" (change)="getItemDetail($event)" #item_id="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && item_id.invalid }" required>
                                <ng-option *ngFor="let storeItem1 of searchstoreitems" [value]="storeItem1._id" >
                                    {{ storeItem1.upc_code }} ({{storeItem1.item_description}})
                                </ng-option>
                             </ng-select>
                    
                             <div *ngIf="!modelPhysicalinventory.item_id" class="invalid-feedback">
                                UPC Code / Description is required
                            </div>
                    
                </div>
                <div *ngIf="pricegrp" class="form-group col-md-6">
                    <label>Select Price Group </label>
                    <select class="form-control form-select digits" name="price_group_id" [(ngModel)]="modelPhysicalinventory.price_group_id" #price_group_id="ngModel"
                    [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && price_group_id.invalid }" (change)="getGroupItems($event)" required>
                        <option value="">Select</option>
                        <option *ngFor="let prg of pricegroups" [value]="prg._id">
                          {{prg.name}}
                        </option>
                    </select>
                </div>
                
                <div class="form-group col-md-6">
                    <label>Inventory Date</label>
                    <input class="form-control" type="date" name="inventory_date" [(ngModel)]="modelPhysicalinventory.inventory_date" #inventory_date="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && inventory_date.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Buying Cost </label>
                    <input class="form-control" type="number" name="buying_cost" [(ngModel)]="modelPhysicalinventory.buying_cost" #buying_cost="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && buying_cost.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Checked By </label>
                    <select class="form-control form-select digits" id="exampleFormControlSelect9" name="checked_by" [(ngModel)]="modelPhysicalinventory.checked_by" #checked_by="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && checked_by.invalid }" required>
                        <option value="User 1">User 1</option>
                        <option value="User 2">User 2</option>
                    </select>
                </div>
                <!-- <div class="form-check form-check-inline checkbox checkbox-dark mb-0 col-md-6">
                    <input class="form-check-input" id="inline-1" type="checkbox" name="checked_by" [(ngModel)]="modelPhysicalinventory.checked_by" required>
                    <label class="form-check-label" for="inline-1">Checked By</label>
                  </div> -->
                <div class="form-group col-md-6">
                    <label>Selling Price </label>
                    <input class="form-control" type="number" name="selling_price" [(ngModel)]="modelPhysicalinventory.selling_price" #selling_price="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && selling_price.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Current Inventory </label>
                    <input class="form-control" type="text" name="current_inventory" [(ngModel)]="modelPhysicalinventory.current_inventory" #current_inventory="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && current_inventory.invalid }" required>
                </div>
                <div class="form-group col-md-6">
                    <label>Physical Inventory </label>
                    <input class="form-control" type="text" name="physical_inventory" [(ngModel)]="modelPhysicalinventory.physical_inventory" #physical_inventory="ngModel" [ngClass]="{ 'is-invalid': addphysicalinventory.submitted && physical_inventory.invalid }" required>
                </div>
                <div *ngIf="itemrowfound" class="col-md-12 mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">UPC Code</th>
                                <th scope="col">Description</th>
                                <th scope="col">Unit/Cass</th>
                                <th scope="col">Case Cost </th>
                                <th scope="col">Unit Retail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>{{itemrow.upc_code}}</td>
                                <td>{{itemrow.item_description}}</td>
                                <td>{{itemrow.unit_or_case}}</td>
                                <td>{{itemrow.case_cost}}</td>
                                <td>{{itemrow.unit_retail}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="grupitemsfound" class="col-md-12 mb-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">UPC Code</th>
                                <th scope="col">Description</th>
                                <th scope="col">Unit/Cass</th>
                                <th scope="col">Case Cost </th>
                                <th scope="col">Unit Retail</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let irow of grupitems;; let prgi=index">
                                <td>{{prgi + 1}}</td>
                                <td>{{irow.upc_code}}</td>
                                <td>{{irow.item_description}}</td>
                                <td>{{irow.unit_or_case}}</td>
                                <td>{{irow.case_cost}}</td>
                                <td>{{irow.unit_retail}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-secondary mr-1" >Save</button>
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    
                </div>
            </div>
        </form>
    </div>
</ng-template>