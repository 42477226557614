<div class="container-fluid pdlr">
    <div class="row">

        <div class="col-xl-12">
            <div class="card mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Physical Inventory</h4>
                        </div>
                        
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Department </label>
                                <select class="form-control form-select digits"  id="exampleFormControlSelect9" >
                                    <option value="">Select</option>
                                    <option *ngFor="let dep of departmentList" [value]="dep._id">
                                        {{dep.department_name}}
                                      </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label>Select date range</label>
                                <input type="date" class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div class="mb-3 theme-form">
                                <label for="exampleFormControlSelect9">Checked by </label>
                                <select class="form-control form-select digits" id="exampleFormControlSelect9">
                                    <option value="">Select</option>
                                    <option value="User 1">User 1</option>
                                    <option value="User 2">User 2</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="updatebtn">
                                <button>Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card" style="margin-top: 30px;">
                <div class="card-header calender-btn">
                    <h5>
                        <button class="btn btn-primary pull-right"  (click)="addphysicalinventory.openModal();$event.preventDefault()">Add new </button>
                        <div class="clearfix"></div>
                    </h5>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <ngx-datatable class="bootstrap"  [rows]="modelAddphysicalinventoryList" [loadingIndicator]="loadingIndicator"
                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                        [reorderable]="reorderable" >
                        <ngx-datatable-column name="Store name" prop="store.store_full_name"></ngx-datatable-column> 
                        <ngx-datatable-column name="Department" prop="stdepartment.department_name"></ngx-datatable-column> 
                        <ngx-datatable-column name="Item/Group" widht="600">    
                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template> 
                                <div *ngIf="row.itemdetail !=null">
                                    {{row.itemdetail.item_description}} ({{row.itemdetail.upc_code}})
                                </div>
                                <div *ngIf="row.price_group_id !=null">
                                    {{row.pricegroup.name}}
                                </div>
                            </ng-template>
                        </ngx-datatable-column> 
                        <ngx-datatable-column name="Date" prop="inventory_date"></ngx-datatable-column> 
                        <ngx-datatable-column name="Checked by" prop="checked_by"></ngx-datatable-column> 
                        <ngx-datatable-column name="Buying cost" prop="buying_cost"></ngx-datatable-column> 
                        <ngx-datatable-column name="Selling price" prop="selling_price"></ngx-datatable-column> 
                        <ngx-datatable-column name="Physical inventory" prop="physical_inventory"></ngx-datatable-column> 
                        <ngx-datatable-column name="Current inventory" prop="current_inventory"></ngx-datatable-column> 
                        <ngx-datatable-column name="Action" widht="600">    
                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                <div class="trlistBtn">
                                    <button class="edit-btn" (click)="addphysicalinventory.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                    <button class="delete-btn" (click)="removePhysicalinventory(row._id)"><i class="fa fa-trash"></i></button>
                                </div>   
                            </ng-template>    
                        </ngx-datatable-column> 
                    </ngx-datatable>
                </div>
                <!-- <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Department</th>
                                    <th>UPC code</th>
                                    <th>Description</th>
                                    <th>Store name</th>
                                    <th>Inventory date</th>
                                    <th>Checked by</th>
                                    <th>Buying cost</th>
                                    <th>Selling price</th>
                                    <th>Physical inventory</th>
                                    <th>Current inventory</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                    <td>text</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</div>
<app-addphysicalinventory (sendaddphysicalinventory) = 'reloadPhysicalinventoryTable($event)' #addphysicalinventory></app-addphysicalinventory>
