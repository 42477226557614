<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <form>
                    <div class="row m-0 p-tb">
                        <div class="col-xl-12">
                            <h4 class="topSellingItem">Scan Data For Altria/Phillip Morris</h4>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="card">
                                <div class="card-header calender-btn">
                                    <h5>
                                        <button class="btn btn-primary pull-right" (click)="addaltria.openModal();$event.preventDefault()">+ Add Ftp Detail </button>
                                        <div class="clearfix"></div>
                                    </h5>
                                </div>
                                <div class="card-body custom-datatable noscroll">
                                    <ngx-datatable class="bootstrap"  [rows]="modelFtpList" [loadingIndicator]="loadingIndicator"
                                        [columns]="columns" [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                        [summaryPosition]="'bottom'" [footerHeight]="40" [rowHeight]="'auto'"
                                        [reorderable]="reorderable" [limit]="15" [selected]="selected"  >
                                        
                                        <ngx-datatable-column name="Server/Host" prop="ftpserver"></ngx-datatable-column>
                                        <ngx-datatable-column name="Username" prop="ftpusername"></ngx-datatable-column> 
                                        <ngx-datatable-column name="Action"  widht="600">    
                                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>    
                                                <div class="trlistBtn">
                                                    <button class="edit-btn" (click)="addaltria.openModal(row._id);$event.preventDefault()"><i class="fa fa-pencil"></i></button>
                                                    
                                                </div>   
                                            </ng-template>    
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                                <!-- <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Manufacturer Name</th>
                                                    <th>Store Name</th>
                                                    <th>Account No</th>
                                                    <th>File format Name</th>
                                                    <th>Submission Day</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn"><i class="fa fa-pencil"></i></button>
                                                            <button class="eye-btn"><i class="fa fa-eye"></i></button>
                                                            <button class="delete-btn"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn"><i class="fa fa-pencil"></i></button>
                                                            <button class="eye-btn"><i class="fa fa-eye"></i></button>
                                                            <button class="delete-btn"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn"><i class="fa fa-pencil"></i></button>
                                                            <button class="eye-btn"><i class="fa fa-eye"></i></button>
                                                            <button class="delete-btn"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>test</td>
                                                    <td>
                                                        <div class="listBtn">
                                                            <button class="edit-btn"><i class="fa fa-pencil"></i></button>
                                                            <button class="eye-btn"><i class="fa fa-eye"></i></button>
                                                            <button class="delete-btn"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
<app-addaltria  #addaltria  (sendaddedaltria) = 'reloadFtpTable($event)'></app-addaltria>