<ng-template #addrole let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{modelTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title="" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form class="needs-validation" #addrole="ngForm" (ngSubmit)="saverole(addrole)">
           
            <div class="row">
                <div class="col-xl-12">
                    <!-- <div class="heading-title">
                        <h2>Department details</h2>
                    </div> -->
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                            <div class="mb-3 theme-form ">
                                <label>Role name </label>
                                <input type="text" class="form-control" name="role_name"  [(ngModel)]="modelRole.role_name"  #role_name="ngModel"
                                [ngClass]="{ 'is-invalid': addrole.submitted && role_name.invalid }"  required>
                            </div>
                            <div class="heading-title">
                                <h6>Add Permissions</h6>
                            </div>
                        </div>
                        

                        <div *ngFor="let mprow of finaldata;" class="col-xl-4 col-lg-4 col-md-4 col-4">
                            
                            <div class="mb-3 theme-form col-md-12">
                                <label><strong>{{mprow['name']}}</strong></label>
                                <ul class="fuel_list">
                                    
                                    <li>
                                        <div *ngFor="let permrow of mprow.permissions; let i = index" class="checkbox checkbox-primary">
                                            <input class="form-check-input"  id="chkper_{{permrow._id}}"  type="checkbox"  value="{{permrow._id}}" (change)="changeSelection($event)" [attr.checked]="isChecked(permrow._id)" ng-checked="isChecked(permrow._id)">
                                            <label class="form-check-label" for="chkper_{{permrow._id}}">{{permrow.name}}</label>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        
                    </div>
               </div>
            </div>

            <div class="form-row">
                <div class="col-md-12" style="text-align: right;">
                    <button class="btn btn-primary mr-1" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-secondary mr-1" >Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>