import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	public storeName = new BehaviorSubject('Eric');

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			// path: '/dashboard', title: 'Dashboard', type: 'link',
			title: 'Dashboard', headTitle1: 'Dashboard',
		},

		// {
		// 	title: 'Store Snapshot', icon: 'airplay', type: 'sub', badgeType: 'success', children: [
		// 		{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Store sales ', type: 'sub' },
		// 		{ path: '/sales-by-store', title: 'Sales by store', type: 'link' }

		// 	]
		// },

		{
			title: 'Daily Paperwork', icon: 'check-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/day-recon-dashboard', title: 'Dashboard', type: 'link' },
				{ path: '/day-recon-reports', title: 'Reports', type: 'link' },

			]
		},

		{
			title: 'Lottery', icon: 'gift', type: 'sub', badgeType: 'success', children: [
				{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
				{ path: '/add-lottery', title: 'Add', type: 'link' },
				{ path: '/activate-lottery', title: 'Activate', type: 'link' },
				// { path: '/confirm-lottery', title: 'Confirm', type: 'link' },
				{ path: '/sales-lottery', title: 'Sales', type: 'link' },
				// { path: '/settlement-lottery', title: 'Settlement', type: 'link' }
			]
		},

		{
			title: 'Fuel ', icon: 'filter', type: 'sub', badgeType: 'success', children: [
				{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
				{ path: '/fuel-invoice', title: 'Fuel Invoice', type: 'link' },
				// { path: '/tank-management', title: 'Tank Management', type: 'link' },
				{ path: '/fuel-retail-price', title: 'Retail Price', type: 'link' },
				// { path: '/fuel-pricing', title: 'Fuel Pricing', type: 'link' },
				{ path: '/bill-payment', title: 'Bill/Payment', type: 'link' },
				{ path: '/delivery', title: 'Delivery', type: 'link' }
			]
		},

		{
			title: 'Invoice ', icon: 'archive', type: 'sub', badgeType: 'success', children: [
				{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
				{
					title: 'Vendor :', icon: 'box', type: 'sub', children: [
						{ path: '/vendor-invoice', title: 'Vendor invoice', type: 'link' },
						{ path: '/vendor', title: 'Add New vendor', type: 'link' },
					]
				},
				// { path: 'javascript:void(0);', title: 'Pending/Approve invoice', type: 'sub' },
				// { path: 'javascript:void(0);', title: 'Review invoice', type: 'sub' },
				{ path: '/edi-invoice', title: 'EDI invoice', type: 'link' },
				{ path: 'javascript:void(0);', title: 'Reports', type: 'sub' },


			]
		},

		{
			title: 'Price Book', icon: 'command', type: 'sub', badgeType: 'success', children: [
				{ path: 'javascript:void(0);', title: 'Dashboard ', type: 'sub' },
				{
					title: 'Add item:', icon: 'box', type: 'sub', children: [
						{ path: '/add-edit-item-details', title: 'Add/edit item details', type: 'link' },
						{ path: '/price-book', title: 'Price book', type: 'link' },
						{ path: '/vendor-item', title: 'Vendor items', type: 'link' },
						// { path: '/multiplier', title: 'multiplier', type: 'link' },
						// { path: '/linked-item', title: 'linked items', type: 'link' },
					]
				},
				// { path: '/advanced-search', title: 'Advanced Search', type: 'link' },
				{ path: '/price-group', title: 'Price group', type: 'link' },
				{ path: '/item-category', title: 'Item category', type: 'link' },
				{ path: '/manufacturer', title: 'Manufacturer', type: 'link' },
				{
					title: 'Promotions', type: 'sub', children: [
						// { path: '/item-maintenance', title: 'Item maintenance', type: 'link' },
						// { path: '/combo-deal-maintenance', title: 'Combo deal maintenance', type: 'link' },
						// { path: '/mix-match-maintenance', title: 'Mix match maintenance ', type: 'link' },
						{ path: '/manage-promotions', title: 'Manage Promotions', type: 'link' },
					]
				},
				{ path: '/stock-transaction', title: 'Stock transaction', type: 'link' },
				{ path: '/physical-inventory', title: 'Physical inventory ', type: 'link' },
				{ path: '/buy-down-managers', title: 'Buy down managers ', type: 'link' },
				{ path: '/bulk-update', title: 'Bulk Update ', type: 'link' },

				// scan data
				{
					title: 'Altria/ Phillip Morris', icon: 'box', type: 'sub', children: [
						{ path: '/scan-dashboard-altria', title: 'Reports', type: 'link' },
						{ path: '/scan-data-altria', title: 'Setup', type: 'link' }
					]
				},
				{
					title: 'RJR / ITG', icon: 'box', type: 'sub', children: [
						{ path: '/scan-dashboard-pjr', title: 'Reports', type: 'link' },
						{ path: '/scan-data-rjr', title: 'Setup', type: 'link' }
					]
				}
			]
		},

		{
			title: 'Reports', icon: 'book', type: 'sub', badgeType: 'success', children: [
				{ path: 'javascript:void(0);', title: 'Purchase', type: 'sub' },
				{ path: 'javascript:void(0);', title: 'Sales', type: 'sub' },
				// { path: 'javascript:void(0);', title: 'Inventory', type: 'sub' },
				{ path: '/fuel-reports', title: 'Fuel', type: 'link' },
				{ path: 'javascript:void(0);', title: 'Accounting', type: 'sub' },
				{ path: '/lottery-reports', title: 'Lottery', type: 'link' },
				{ path: 'javascript:void(0);', title: 'Services ', type: 'sub' },
				{ path: 'javascript:void(0);', title: 'Grocery ', type: 'sub' },
				{ path: '/tender-types', title: 'Tender Types  ', type: 'link' },
				{ path: 'javascript:void(0);', title: 'Expenses CPA', type: 'sub' },
				{ path: 'javascript:void(0);', title: 'Other Income CPA  ', type: 'sub' },
				{ path: 'javascript:void(0);', title: 'Store wise methods of pay analysis', type: 'sub' },
				{ path: '/max-suggests-retail-price-by-manufacture', title: 'Max suggests retail price by manufacturer', type: 'link' },
				{ path: '/store-snapshot-reports', title: 'Reports', type: 'link' }
			]
		},

		{
			title: 'Store Setting', icon: 'file-text', type: 'sub', badgeType: 'success', children: [
				{ path: '/store-dashboard', title: 'Dashboard', type: 'link' },
				{ path: '/store', title: 'Store details', type: 'link' },
				// { path: '/fuel', title: 'Fuel', type: 'link' },
				// { path: '/lottery', title: 'Lottery', type: 'link' },
				{ path: '/pos-setup', title: 'POS setup', type: 'link' },
				{ path: '/register-mapping', title: 'Register mappings', type: 'link' },
				{ path: '/add-department', title: 'Add Department', type: 'link' },
				// { path: '/vendor', title: 'Vendor List', type: 'link' },
				// { title: 'Tax:', icon: 'box', type: 'sub', children: [
				// 	{ path: '/tax-setup', title: 'Tax setup', type: 'link' },
				// 	{ path: '/min-age-restriction', title: 'Min age restriction', type: 'link' },
				// ]
				// },
				{
					title: 'Store User Management', icon: 'file-minus', type: 'sub', badgeType: 'success', children: [

						{ path: '/store-role-management', title: 'Role Management', type: 'link' },
						{ path: '/store-user-management', title: 'Users', type: 'link' }
					]
				},

				// User Management
				{ path: '/user-management-dashboard', title: 'Dashboard', type: 'link' },
				{ path: '/user-management', title: 'Users', type: 'link' },
				{ path: 'javascript:void(0);', title: 'Reports', type: 'sub' }
			]
		},

		// {
		// 	title: 'Sync Services', icon: 'layout', type: 'sub', badgeType: 'success', children: [
		// 		{ path: 'javascript:void(0);', title: 'XML file transfer from passport/ruby2', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Heartbeat notifications', type: 'sub' }
		// 	]
		// },


		// {
		// 	title: 'Scan data', icon: 'printer', type: 'sub', badgeType: 'success', children: [
		// 		// { path: '/scan-dashboard', title: 'Dashboard', type: 'link' },
		// 		// { path: '/scan-data', title: 'Scan Data', type: 'link' },
		// 		{
		// 			title: 'Altria/ Phillip Morris', icon: 'box', type: 'sub', children: [
		// 				{ path: '/scan-dashboard-altria', title: 'Reports', type: 'link' },
		// 				{ path: '/scan-data-altria', title: 'Setup', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'RJR / ITG', icon: 'box', type: 'sub', children: [
		// 				{ path: '/scan-dashboard-pjr', title: 'Reports', type: 'link' },
		// 				{ path: '/scan-data-rjr', title: 'Setup', type: 'link' }
		// 			]
		// 		}
		// 	]
		// },

		// {
		// 	title: 'User Management', icon: 'file-minus', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/user-management-dashboard', title: 'Dashboard', type: 'link' },
		// 		// { path: '/role-management', title: 'Role Management', type: 'link' },
		// 		{ path: '/user-management', title: 'Users', type: 'link' },
		// 		{ path: 'javascript:void(0);', title: 'Reports', type: 'sub' }
		// 	]
		// },


		// {
		// 	title: 'Payroll', icon: 'dollar-sign', type: 'sub', badgeType: 'success', children: [
		// 		{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Timesheet', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Pay checks', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Employees', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Reports', type: 'sub' }
		// 	]
		// },
		// {
		// 	title: 'Grocery', icon: 'disc', type: 'sub', badgeType: 'success', children: [
		// 		{ path: 'javascript:void(0);', title: 'Dashboard', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Purchase', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Track non scan items', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Inventory Adjustment ', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Rebate Management', type: 'sub' },
		// 		{ path: 'javascript:void(0);', title: 'Reports', type: 'sub' }
		// 	]
		// },

	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{ path: 'javascript:void(0);', title: 'Lorem Store (0020)', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Lorem Store (0020)', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Lorem Store (0020)', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);
	createdstore = new BehaviorSubject(null);

}
