import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ScanDataRoutingModule } from './scan-data-routing.module';
import { ScanDataComponent } from './scan-data.component';
import { FormsModule } from '@angular/forms';
import { AddAtriaComponent } from './modal/addaltria/addaltria.component';


@NgModule({
  declarations: [
    ScanDataComponent,
    AddAtriaComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ScanDataRoutingModule,
    NgxDatatableModule
  ]
})
export class ScanDataModule { }
