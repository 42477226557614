import { Injectable } from '@angular/core';
import { CONSTANTS } from '../util/constants';
import { HttpService } from '../shared/services/http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class StoreService {
    private subjectNameSaveVendorInvoiceItm = new Subject<any>();
    public saveFamilyMemberBS = new BehaviorSubject(null);
    constructor(
        private constants: CONSTANTS,
        private httpServ: HttpService
    ) {
        this.constants = constants,
            this.httpServ = httpServ
    }

    saveStore(data, store_id: string, otherdata = null) {
        data['_id'] = JSON.parse(localStorage.getItem('store_id'));
        if(otherdata){
            if(otherdata['store_logo'] !=''){
                data['store_logo'] = otherdata['store_logo'];
            }
        }
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getAllStores() {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStore(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreSalesTax(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSALESTAXLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreSalesTaxById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSALESTAXBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }




    saveSalesTax(data, salesTaxId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = salesTaxId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSALESTAX,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveFuelTax(data, salesTaxId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = salesTaxId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDFUELTAX,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreFueltaxList(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELTAXLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreFuelTaxById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELTAXBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveSalesRestriction(data, salesRestrictionId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = salesRestrictionId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSALESRESTRICTION,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getSalesRestrictionList(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSALESRESTRICTIONLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getSalesRestrictionById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSALESRESTRICTIONBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeSalesRestriction(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESALESRESTRICTION + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveFuelGrade(data, fuleId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = fuleId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDFUELGRADE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreFuelGrade(storeid: string,dayreconid=null) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELGRADELIST + `/${storeid}`+`/${dayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getFuelGradeById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELGRADEBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeFuelGrade(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEFUELGRADE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveTank(data, tankId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = tankId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDTANKDETAILS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreTank(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTANKDETAILSLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getTankById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTANKDETAILSBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeTank(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETETANKDETAILS + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }


    removeAddSalesTax(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESALESTAX + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeAddFuelTax(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEFUELTAX + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getLotterySettings(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETLOTTERYSETTINGS + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveLotterySettings(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVELOTTERYSETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getBankaccountList(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETBANKACCOUNTLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    addBankaccount(data, bankId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = bankId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDBANKACCOUNT,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getTimeoffsetting(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTIMEOFFSETTING + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveTimeoffsetting(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVETIMEOFFSETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreLocation(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTORELOCATION + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreLocationById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTORELOCATIONBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeStoreLocation(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESTORELOCATION + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }


    getstoreServices(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTORESERVICES + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }

    saveStoreLocation(data, storeLocationId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = storeLocationId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVESTORELOCATION,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getPaymentById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPAYMENTBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    savePaymentmethod(data, paymentId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = paymentId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDPAYMENTMETHOD,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removePaymentMethod(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEPAYMENT + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    savestoreServices(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVESTORESERVICES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getPaymentmethod(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPAYMENTMETHODLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveSchedulesetting(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVESCHEDULINGSETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getSchedulesetting(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSCHEDULINGSETTING + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getBankAccountById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETBANKACCOUNTBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }

    saveFuelsetting(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVEFUELSETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeBankaccount(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEBANKACCOUNT + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };
        return this.httpServ.sendRequest(requestObject);
    }

    getFuelsetting(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELSETTING + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveLottery(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVELOTTERY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getLottery(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETLOTTERY + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    savePosSetup(data) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVEPOSSETUP,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getPosSetup(storeid) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPOSSETUP + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getVendorById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveVendor(data, vendorId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = vendorId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDVENDOR,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeVendor(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEVENDOR + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getVendor(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    gettaxById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTAXBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveTax(data, vendorId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = vendorId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDVTAX,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveDepartment(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDDEPARTMENT,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removetax(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETETAX + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    gettax(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTAXLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    // tax Strategy
    gettaxStrategyById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTAXSTRATEGYBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreDepartment(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETDEPARTMENTLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    savetaxStrategy(data, vendorId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = vendorId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDTAXSTRATEGY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removetaxStrategy(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETETAXSTRATEGY + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    gettaxStrategy(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTAXSTRATEGYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getDepartmentById(row_id: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETDEPARTMENTBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    removeDepartment(row_id) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEDEPARTMENT + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStocktransactionById(row_id: string) {
        let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOCKTRANSACTIONBYID + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
         };

         return this.httpServ.sendRequest(requestObject);
     }


     removeStocktransaction(row_id) {
         let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESTOCKTRANSACTION + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
         };

         return this.httpServ.sendRequest(requestObject);
     }

     getStocktransaction(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOCKTRANSACTIONLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

   saveStocktransaction(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOCKTRANSACTION,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
   }

   saveItem(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;
	let requestObject = {
            REQUEST_DATA: data,
	    API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDITEM,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

  getphysicalinventoryById(row_id: string) {
        let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPHYSICALINVENTORYBYID + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
         };

         return this.httpServ.sendRequest(requestObject);
     }


     removePhysicalinventory(row_id) {
         let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEPHYSICALINVENTORY + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
         };

         return this.httpServ.sendRequest(requestObject);
     }

     getPhysicalinventory(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPHYSICALINVENTORYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreItem(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

   savePhysicalinventory(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDPHYSICALINVENTORY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

     //Buy down managers
     getBuydownmanagerById(row_id: string) {
        let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETBUYDOWNMANAGERSBYID + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
         };

         return this.httpServ.sendRequest(requestObject);
     }


     removeBuydownmanager(row_id) {
         let requestObject = {
             API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEBUYDOWNMANAGERS + `/${row_id}`,
             REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
         };

         return this.httpServ.sendRequest(requestObject);
     }

     getBuydownmanager(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETBUYDOWNMANAGERSLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getItemById(row_id: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveBuydownmanager(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDBUYDOWNMANAGERS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
	};

        return this.httpServ.sendRequest(requestObject);
    }

    removeItem(row_id) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEITEM + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getVendoritemById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORITEMBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveVendoritem(data, vendorId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = vendorId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDVENDORITEM,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeVendoritem(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEVENDORITEM + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getVendoritem(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORITEMLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveItemmaintenance(data, itemId: string) {

        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = itemId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDITEMMAINTENANCE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getItemmaintenance(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMAINTENANCELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeItemmaintenance(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEITEMMAINTENANCE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getItemMaintenanceById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMMAINTENANCEMBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveMixmatchmaintenance(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDMIXMATCHMAINTENANCE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getMixMatchMatchMaintenance(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMIXMATCHMAINTENANCELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeMixmatchmaintenance(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEMIXMATCHMAINTENANCE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getMixmatchMaintenanceById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMIXMATCHMAINTENANCEMBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveVendorinvoice(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDVENDORINVOICE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getVendorinvoice(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORINVOICELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeVendorinvoice(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEVENDORINVOICE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getVendorInvoiceById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETVENDORINVOICEBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
g
    getStoreFuelGradeForInvoice(storeid: string,fuelinvoiceid:string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELGRADEFORINVOICE + `/${storeid}`+ `/${fuelinvoiceid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveFuelinvoice(data, itemId: string,otherinfo=null) {
        console.log("otherinfo",otherinfo)
        if(otherinfo){
            if(otherinfo['invoicefile'] !=''){
                console.log("otherinfo9",otherinfo)
                data['invoicefile'] = otherinfo['invoicefile'];
            }
        }

//return false;

        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDFUELINVOICE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getFuelinvoice(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELINVOICELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeFuelinvoice(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEFUELINVOICE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getFuelInvoiceById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELINVOICEBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreItemListOnSearch(itemtxt: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMLISTONSEARCH + `/${itemtxt}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getVendorinvoiceBySearch(values){

        let requestObject = {
            REQUEST_DATA: values,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SEARCHVENDORINVOICE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveItemCategory(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDITEMCATEGORY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getItemCategory(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMCATEGORYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeItemCategory(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEITEMCATEGORY + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getItemCategoryById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMCATEGORYBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    savePriceGroup(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDPRICEGROUP,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getPriceGroup(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPRICEGROUPLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removePriceGroup(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEPRICEGROUP + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getPriceGroupById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPRICEGROUPBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    saveVendorInvoiceItem(vendorInvoiceId: string, itemRow: any,data?:any) {

        let cur_store_id = JSON.parse(localStorage.getItem('store_id'));

        let viteminvdata = {"vendor_invoice_id": vendorInvoiceId, "item_data": itemRow,"store_id":cur_store_id};


        let requestObject = {
            REQUEST_DATA: viteminvdata,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDVENDORINVOICEITEM,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    sendUpdatedVendorInvoiceItem(){
        this.subjectNameSaveVendorInvoiceItm.next();
    }
    getUpdatedVendorInvoiceItem(): Observable<any> {
        return this.subjectNameSaveVendorInvoiceItm.asObservable();
    }

    getStoreItemListByUPC(itemtxt: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETITEMBYUPC + `/${itemtxt}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    getVendorInvoiceItemList(vendorinvoiceid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.VENDORINVOICEITEMLIST + `/${vendorinvoiceid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveTankSetting(data, itemId: string) {

        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDTANKSETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getTankSetting(storeid: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTANKSETTING + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveTankGradeSetting(data, itemId: string) {

        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDTANKGRADESETTING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeFuelDelivery(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEFUELDELIVERY + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveFuelDelivery(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDFUELDELIVERY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getFuelDelivery(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELDELIVERYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getFuelDeliveryById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELDELIVERYBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveRole(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDROLE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getRoles(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETROLELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getRoleById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETROLEBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getMenuPages(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMENUPAGESLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getpermissionlistbypageid(pageid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPERMISSIONSLISTBYPAGEID + `/${pageid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveRolePermissions(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['role_id'] = data.role_id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDROLEPERMISSIONS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getpermissionlistbyroleid(roleid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETPERMISSIONSLISTBYROLEID + `/${roleid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getdayrecornreport(storeid:string,conntype: string,date1,date2) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETDAYRECONREPORT + `/${storeid}`+ `/${conntype}`+ `/${date1}`+ `/${date2}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getlivedatareport(storeid:string,conntype: string,datrange:string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETLIVEDATAREPORT + `/${storeid}`+ `/${conntype}`+ `/${datrange}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }


    advanceSearchItem(data:any) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADVANCEDSEARCHITEMS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getDepartmentType(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETDEPARTMENTTYPELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getUsers(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETUSERLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveUser(data, itemId: string) {
        data['_id'] = itemId;
        //data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDUSER,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getUserById(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETUSERBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeUser(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETUSER + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getUserRoles(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETUSERROLES + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getUsersByIds(row_ids: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETUSERLISTBYIDS + `/${row_ids}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getUsersBystoreId(row_id: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETUSERLISTBYSTOREID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    deleteuserroles(storeid,row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETUSERROLES + `/${storeid}`+ `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveCombodeal(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDCOMBODEAL,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getComboDeal(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCOMBODEALLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeCombodeal(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETECOMBODEAL + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getComboDealById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCOMBODEALBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveMixMatchItems(mixmatchId: string, data:any) {

        let datasrr = [];

        datasrr.push({objdata:data,mixmatchid:mixmatchId,_id:null});

        let requestObject = {
            REQUEST_DATA: datasrr,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDMIXMATCHITEMS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getmixmatchitemlist(mixmatchId: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMIXMATCHITEMS + `/${mixmatchId}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveComboDealItems(combodealid: string,data:any) {
        let datasrr = [];
        datasrr.push({objdata:data,combodealid:combodealid,_id:null});
        let requestObject = {
            REQUEST_DATA: datasrr,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDCOMBODEALITEMS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getComboDealitemlist(combodealId: string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCOMBODEALITEMS + `/${combodealId}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    deleterolepermissions(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETROLEPERMISSIONS + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveUserRole(data, itemId: string) {
        data['_id'] = itemId;
        data['role_id'] = data.role_id;
        data['user_id'] = data.user_id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDUSERROLE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveStoreRole(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOREROLE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreRoles(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREROLELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreRoleById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREROLEBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreMenuPages(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREMENUPAGESLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStorepermissionlistbypageid(pageid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREPERMISSIONSLISTBYPAGEID + `/${pageid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveStoreRolePermissions(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['role_id'] = data.role_id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOREROLEPERMISSIONS,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getstorepermissionlistbyroleid(roleid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREPERMISSIONSLISTBYROLEID + `/${roleid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    deletestorerolepermissions(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESTOREROLEPERMISSIONS + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreUsers(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREUSERLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveStoreUser(data, itemId: string) {
        data['_id'] = itemId;
        //data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOREUSER,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
getStoreUserById(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREUSERBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeStoreUser(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESTOREUSER + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreUserRoles(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREUSERROLES + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
deleteStoreuserroles(storeid,row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETSTOREUSERROLES + `/${storeid}`+ `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
saveStoreUSerRole(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['role_id'] = data.role_id;
        data['user_id'] = data.user_id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOREUSERROLE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getStoreTimezones() {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETTIMEZONES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    uploadfile(data){

        console.log("pfate1=",data);

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFILE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);
    }
    saveRjrftpdeatil(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDRJRFTP,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);

        // let apiurlphoto = this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFILE;
        // return this.httpClient.post(apiurlphoto, formParams)
    }
    getOtherMoneyLabels(store_id,added_date,storedayreconid) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETOTHERMONEYLABELS + `/${store_id}`+ `/${added_date}`+`/${storedayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }
    getRjrftpdeatil(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETRJRFTPLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    addOtherMoney(data, itemId: string){
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDOTHERMONEYRECEIVED,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getOtherMoney(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETOTHERMONEYRECEIVED + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getRjrftpdeatilById(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETRJRFTPBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    add_gen_customer_account(data, itemId: string){
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDGENERALCUSTOMERACCOUNT,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);
    }
    saveAltriaftpdeatil(data, itemId: string) {
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDALTRIAFTP,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    gen_customer_account_list(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GENERALCUSTOMERACCOUNTLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }
    getAltriaftpdeatil(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETALTRIAFTPLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    add_income_expense(data, itemId: string){
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDINCOMEEXPENSE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    income_expense_list(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.INCOMEEXPENSELIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }
    getsAltriaftpdeatilById(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETALTRIAFTPBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    add_storedayrecon(data, itemId: string){
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTOREDAYRECON,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    get_storedayrecon(row_id: string,sdate: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTOREDAYRECON + `/${row_id}`+ `/${sdate}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getManufacturers(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMANUFACTURERLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeManufacturer(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEMANUFACTURER + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveManufacturer(data, itemId: string){
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDMANUFACTURER,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getManufacturer(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMANUFACTURER + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getIncomeExpense(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETINCOMEEXPENSE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getGencustomeraccount(row_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETGENERALCUSTOMERACCOUNT + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeIncomeExpense(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEINCOMEEXPENSE + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    removeGencustomeraccount(row_id) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEGENERALCUSTOMERACCOUNT + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveFuelRetailPrice(data, itemId: string){
        data['_id'] = itemId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPDATEFUELRETAILPRICES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    updatefuelretailprices(data, itemId: string) {
        let newdata = [];
        //console.log("fprice1",data.fuelpriceitems);
        data['_id'] = itemId;
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        for(var item in data.fuelpriceitems) {
          if(data.fuelpriceitems[item].new_credit_price !== null && data.fuelpriceitems[item].new_cash_price !== null){
            newdata.push(data.fuelpriceitems[item])
          }else{
          }
        }
        let requestObject = {
            REQUEST_DATA: newdata,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPDATEFUELRETAILPRICES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };
        return this.httpServ.sendRequest(requestObject);
    }
    getMCMcode(code_id: string) {

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETMCMCODE + `/${code_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    saveDayreconreport(data, itemId: string){
        data['_id'] = itemId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPDATEDAYRECONREPORT,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };
        return this.httpServ.sendRequest(requestObject);
    }
    

    searchFuelDeliveryByDate(values){

        let requestObject = {
            REQUEST_DATA: values,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELDELIVERYLISTBYDATE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    //lottery api
    saveStoreLottery(data, tankId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = tankId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDSTORELOTTERY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreLottery(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTORELOTTERYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getStoreLotteryById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSTORELOTTERYBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeStoreLottery(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETESTORELOTTERYDETAILS + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }

    saveactivatelottery(data, tankId: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = tankId;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDACTIVESTORELOTTERY,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getactiveStoreLottery(storeid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETACTIVESTORELOTTERYLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getActivatelotteryById(row_id: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETACTIVESTORELOTTERYBYID + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    removeactiveStoreLottery(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEACTIVESTORELOTTERYDETAILS + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }


    uploadfuelinvoice(data) {

        console.log("pfate1=", data);

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFUELINVOICEFILE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);
    }

    uploadvendorinvoice(data) {
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADVENDORINVOICEFILE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);
    }

    saveregistermapping(data, id: string) {
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'));
        data['_id'] = id;

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVEREGISTERMAAPPING,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getfuelgradetankreading(storeid: string,dayreconid: string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELGRADETANKREADING + `/${storeid}`+`/${dayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }

    async getsignedurl(data){
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETSIGNEDURL,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };
        return await this.httpServ.sendRequest(requestObject)
    }

    savefuelpayment(data, fuelinvoicepaymentdetails, id:string){
        data['store_id'] = JSON.parse(localStorage.getItem('store_id'))
        data['_id'] = id;
        data['fuelinvoicepaymentdetails'] = fuelinvoicepaymentdetails
        console.log(JSON.stringify(data))

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.SAVEFUELPAYMENT,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);

    }

    uploadfuelpayment(data) {
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.UPLOADFUELPAYMENTFILE,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        }
        return this.httpServ.sendRequest(requestObject);
    }
    getfuelpaymentlist(){
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELPAYMENTLIST+ `/${store_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getfuelpaymentbyid(id:string){
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELPAYMENTBYID+ `/${id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getClosingTankReadingByDate(date: string){
       
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCLOSINGTANKREADINGBYDATE+ `/${store_id}`+`/${date}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getStoreUserByEmail(searchvalue: string){
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        if(searchvalue.match(validRegex)){
            let requestObject = {
                API_URL : this.constants.API_URLS.USERS + this.constants.API_URLS.GETSTOREUSERBYEMAIL+`/${store_id}`+`/${searchvalue}`,
                REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
            }
            return this.httpServ.sendRequest(requestObject);
        }else{
            let requestObject = {
                API_URL : this.constants.API_URLS.USERS + this.constants.API_URLS.GETSTOREUSERBYNAME+`/${store_id}`+`/${searchvalue}`,
                REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
            }
            return this.httpServ.sendRequest(requestObject);
        }
        
    }

    getUserBySearchValue(searchvalue){
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(searchvalue.match(validRegex)){
            let requestObject = {
                API_URL : this.constants.API_URLS.USERS + this.constants.API_URLS.GETUSERBYEMAIL+`/${searchvalue}`,
                REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
            }
            return this.httpServ.sendRequest(requestObject);
        }else{
            let requestObject = {
                API_URL : this.constants.API_URLS.USERS + this.constants.API_URLS.GETUSERBYNAME+`/${searchvalue}`,
                REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
            }
            return this.httpServ.sendRequest(requestObject);
        }
        
    }
    
    addMoneyToBank(data, itemId: string){
        data['_id'] = itemId;
        

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DEPOSITMONEYTOBANK,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getbankdepositedlist(storeid,dayreconid){
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETBANKDEPOSITEDLIST+ `/${storeid}`+`/${dayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getFuelinvoicePayment(storeid: string, payment_id:string) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETFUELINVOICEPAYMENTLIST + `/${storeid}`+`/${payment_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }
    removeMoneyDepositedToBank(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETEBANKDEPOSITED + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    addCreditCardBatch(data, itemId: string){
        data['_id'] = itemId;
        

        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDCREDITCARDBATCH,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getCreditCardBatchList(storeid,dayreconid){
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCREDITCARDBATCHLIST+ `/${storeid}`+`/${dayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }
    removeCreditCardBatch(row_id) {
        let user = JSON.parse(localStorage.getItem('user'));

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.DELETECREDITCARDBATCH + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getCreditCardBatchTypeList(storeid){
        let store_id= JSON.parse(localStorage.getItem('store_id'))     
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCREDITCARDBATCHTYPELIST+ `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        }
        return this.httpServ.sendRequest(requestObject);
    }

    getlivesummarydatareport(storeid:string,conntype: string,datrange:string) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETLIVESUMMARYDATAREPORT + `/${storeid}`+ `/${conntype}`+ `/${datrange}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };

        return this.httpServ.sendRequest(requestObject);
    }
    getcreditcardlist(storeid:string) {
        console.log(">>>storeid: "+storeid)
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETCREDITCARDLIST + `/${storeid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject);
    }

    adddayreconcashpurchases(data, itemId: string){
        data['_id'] = itemId;
        let requestObject = {
            REQUEST_DATA: data,
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.ADDDAYRECONCASHPURCHASES,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.POST
        };

        return this.httpServ.sendRequest(requestObject);
    }

    getdayreconcashpurchaseslist(storedayreconid){
        // GETDAYRECONCASHPURCHASELIST
        let store_id= JSON.parse(localStorage.getItem('store_id'))     

        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.GETDAYRECONCASHPURCHASELIST + `/${store_id}/${storedayreconid}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.GET
        };
        return this.httpServ.sendRequest(requestObject)
    }

    removedayreconcashpurchases(row_id) {
        let requestObject = {
            API_URL: this.constants.API_URLS.STORE + this.constants.API_URLS.REMOVEDAYRECONCASHPURCHASES + `/${row_id}`,
            REQUEST_METHOD: this.constants.REQUEST_METHODS.DELETE
        };

        return this.httpServ.sendRequest(requestObject);
    }
}
