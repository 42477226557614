import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { StoreComponent } from './store.component';
import { FormsModule } from '@angular/forms';
import { AddsalestaxComponent } from './modal/addsalestax/addsalestax.component';
import { AddfueltaxComponent } from './modal/addfueltax/addfueltax.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddsalesrestrictionComponent } from './modal/addsalesrestriction/addsalesrestriction.component';
import { AddfuelgradeComponent } from './modal/addfuelgrade/addfuelgrade.component';
import { AddtankComponent } from './modal/addtank/addtank.component';
import { AddstorelocationComponent } from './modal/addstorelocation/addstorelocation.component';
import { AddpaymentmethodComponent } from './modal/addpaymentmethod/addpaymentmethod.component';
import { AddbankaccountComponent } from './modal/addbankaccount/addbankaccount.component';


@NgModule({
  declarations: [
    StoreComponent,
    AddsalestaxComponent,
    AddfueltaxComponent,
    AddsalesrestrictionComponent,
    AddfuelgradeComponent,
    AddtankComponent,
    AddstorelocationComponent,
    AddpaymentmethodComponent,
    AddbankaccountComponent
  ],
  imports: [
    CommonModule,
    StoreRoutingModule,
    FormsModule,
    NgxDatatableModule
  ]
})
export class StoreModule { }
