<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 box-col-12">
            <div class="mtb">
                <div class="cardhead mt-3">

                    <ul id="info-tab" role="tablist" class="nav nav-tabs border-tab nav-primary">
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-1" class="nav-link "
                                [ngClass]="{'active show': openTab == 'mixmatch'}" (click)="tabbed('mixmatch')">Mix
                                Match</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" id="info-tab-2" class="nav-link"
                                [ngClass]="{'active show': openTab == 'combodeal'}" (click)="tabbed('combodeal')">Combo
                                Deals</a></li>
                    </ul>
                    <div id="info-tabContent" class="tab-content">

                        <div id="info-1" role="tabpanel" aria-labelledby="info-tab-1" class="tab-pane fade"
                            [ngClass]="{'show active': openTab == 'mixmatch'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"
                                                        (click)="addmixmatchmaintenance.openModal();$event.preventDefault()">Add
                                                        new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="modelMixmatchMaintenanceList"
                                                    [loadingIndicator]="loadingIndicator" [columns]="columns"
                                                    [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40"
                                                    [rowHeight]="'auto'" [reorderable]="reorderable" [limit]="15">
                                                    <ngx-datatable-column name="Name" prop="name">
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Item Count" prop="mixmatchItemscnt.length">
                                                    </ngx-datatable-column>
                                                    
                                                    <ngx-datatable-column name="Begin date" prop="start_date">
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="End date" prop="end_date">
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column name="Action" widht="600">
                                                        <ng-template let-row="row" let-expanded="expanded"
                                                            ngx-datatable-cell-template>
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn"
                                                                    (click)="addmixmatchmaintenance.openModal(row._id);$event.preventDefault()"><i
                                                                        class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn"
                                                                    (click)="removeMixmatchmaintenance(row._id)"><i
                                                                        class="fa fa-trash"></i></button>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="info-2" role="tabpanel" aria-labelledby="info-tab-2" class="tab-pane fade"
                            [ngClass]="{'show active': openTab == 'combodeal'}">
                            <form>
                                <div class=" row m-0 p-tb">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="card">
                                            <div class="card-header calender-btn">
                                                <h5>
                                                    <button class="btn btn-primary pull-right"
                                                        (click)="addcombodeal.openModal();$event.preventDefault()">Add
                                                        new </button>
                                                    <div class="clearfix"></div>
                                                </h5>
                                            </div>
                                            <div class="card-body custom-datatable noscroll">
                                                <ngx-datatable class="bootstrap" [rows]="modelCombodealList"
                                                    [loadingIndicator]="loadingIndicator" [columns]="columns"
                                                    [columnMode]="'force'" [headerHeight]="40" [summaryRow]="true"
                                                    [summaryPosition]="'bottom'" [footerHeight]="40"
                                                    [rowHeight]="'auto'" [reorderable]="reorderable" [limit]="15">
                                                    <ngx-datatable-column name="Name" prop="name">
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Item Count" prop="combodealItemscnt.length">
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Begin date" prop="start_date">
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="End date" prop="end_date">
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column name="Action" widht="600">
                                                        <ng-template let-row="row" let-expanded="expanded"
                                                            ngx-datatable-cell-template>
                                                            <div class="trlistBtn">
                                                                <button class="edit-btn"
                                                                    (click)="addcombodeal.openModal(row._id);$event.preventDefault()"><i
                                                                        class="fa fa-pencil"></i></button>
                                                                <button class="delete-btn"
                                                                    (click)="removeCombodeal(row._id)"><i
                                                                        class="fa fa-trash"></i></button>
                                                            </div>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-addmixmatchmaintenance (sendaddedmixmatchmaintenance)='reloadMixMatchMaintenanceTable($event)'
    #addmixmatchmaintenance></app-addmixmatchmaintenance>
<app-addcombodeal (sendaddedcombodeal)='reloadCombodealTable($event)' #addcombodeal></app-addcombodeal>